export class BaseNomenclature {
    key: String;
    // _id: String;
    value: String;
    description: String;
    createdBy: String;
    modifiedBy: String;
    collection_name: String;


    constructor(){
        this.key="";
        // this._id="";
        this.value=""; 
        this.description="";
        this.createdBy="";
        this.modifiedBy="";
        this.collection_name="";
    }
}