<div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label for="inputReprezentant"  class="label">{{ls.strings.reprezentant}}<span style="color:orange">*</span></label>
        <input type="text" (change)="onChangeEvent($event)" required nbInput fullWidth id="inputReprezentant"  [(ngModel)]="representative.nume" [placeholder]="ls.strings.reprezentant">
      </div>
    </div>

<div class="col-sm-6">
    <div class="form-group">
      <p class="label">{{ls.strings.rol_reprezentant}}<span style="color:orange">*</span></p>
      <input type="text" nbInput fullWidth id="inputRol" [(ngModel)]="representative.rol_reprezentant" [placeholder]="ls.strings.rol_reprezentant">
       
  </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-6">
    <div class="form-group">
      <label for="inputEmail" class="label">{{ls.strings.email}}</label>
      <input type="email" (change)="onChangeEvent($event)" nbInput fullWidth id="inputEmail"  [(ngModel)]="representative.email" [placeholder]="ls.strings.email">
    </div>
  </div>
  <div class="col-sm-6">
      <div class="form-group">
        <label for="inputFax" class="label">{{ls.strings.telefon}}</label>
        <input type="tel" (change)="onChangeEvent($event)" nbInput fullWidth id="inputTel"  [(ngModel)]="representative.telefon" [placeholder]="ls.strings.telefon">
      </div>
    </div>
</div>

<div class="row">
<div class="col-sm-6">
  <div class="form-group">
    <label for="inputFax" class="label">{{ls.strings.fax}}</label>
    <input type="tel" (change)="onChangeEvent($event)" nbInput fullWidth id="inputFax"  [(ngModel)]="representative.fax" [placeholder]="ls.strings.fax">
  </div>
</div>
</div>
