import { BaseModel } from "./base.model";
import { Service } from "./service.model";

export class AllocationModel extends BaseModel {
    [x: string]: any;
    public id: String;
    public client_id: String;
    public client_name:String;
    public start_date: Date;
    public end_date: Date;
    public frequency: String;
    public create_date: Date;
    public modified_date: Date;
    public creator: String;
    public modifier: String;
    public validated: Boolean;
    public locked: Boolean;
    public users: any[];
    public collection_name:String;
 
    
    //public group:string;
    constructor() {
        super();

    } 

    protected generateInit() {
        this.id='';
        this.client_id='';
        this.client_name = '';
        this.start_date = new Date();
        this.end_date=new Date();
        this.frequency='';
        this.create_date=new Date();
        this.modified_date=new Date();
        this.creator='';
        this.modifier='';
        this.validated=false;
        this.locked=false;
        this.users= [];
        this.collection_name="allocation_lists";
 
    }
}