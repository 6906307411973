<nb-card>
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body >
        <!-- <div *ngIf="objectToModify!=undefined"> -->
          <div *ngIf="services && editMode || measurementFields" class="row">
            <nb-toggle status="primary" (change)="onChangeEventActiveService($event)" [(ngModel)]="data.active" >{{ls.strings.active_service}}</nb-toggle>
          </div>
        <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="key" class="label">{{ls.strings.key}}</label>
                <input type="text"  (change)="onKeyChangeEvent()" [(ngModel)]="data.key" nbInput fullWidth [placeholder]="ls.strings.key">
                <p *ngIf="keyExists" style="color: red;">{{ls.strings.key_already_exists}}</p>
              </div>
            </div>
          </div>
          <div *ngIf="!services" class="row">
            <div class="col-sm-12">
                <div class="form-group">
                  <label for="value" class="label">{{ls.strings.value}}</label>
                  <input type="text"  (change)="onChangeEvent(null)" [(ngModel)]="data.value" nbInput fullWidth [placeholder]="ls.strings.value">
                </div>
              </div>
          </div>
          <div *ngIf="services" class="row">
            <div class="col-sm-12">
                <div class="form-group">
                  <label for="value" class="label">{{ls.strings.service_price}}</label>
                  <input type="number"  (change)="onChangeEvent(null)" [(ngModel)]="data.price" nbInput fullWidth [placeholder]="ls.strings.service_price">
                </div>
              </div>
          </div>
          <!-- <div *ngIf="services" class="row">
            <div class="col-sm-12">
                <div class="form-group">
                  <label for="value" class="label">{{ls.strings.discount}}</label>
                  <input type="number"  (change)="onChangeEvent(null)" [(ngModel)]="data.discount" nbInput fullWidth [placeholder]="ls.strings.discount">
                </div>
              </div>
          </div> -->
          <div *ngIf="typeManagement" class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <p class="label">{{ls.strings.measurements}}</p>
                <nb-select #measurementsSelect multiple [(ngModel)]="data.measurements" (selectedChange)="onChangeEvent($event)">
                    <nb-option *ngFor="let measure of dosimeterMeasurements" [value]="measure.value">{{measure.value}}</nb-option>
                  </nb-select>
            </div>
            </div>
        </div>
        <div *ngIf="services" class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <p class="label">{{ls.strings.measurements}}</p>
              <nb-select #measurementsSelect [(ngModel)]="data.service" (selectedChange)="onChangeEvent($event)">
                  <nb-option *ngFor="let measure of dosimeterMeasurements" [value]="measure">{{measure.value}}</nb-option>
                </nb-select>
          </div>
          </div>
      </div>
        <div *ngIf="measurementFields" class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <p class="label">{{ls.strings.menu_dosimetric_measurements}}</p>
              <nb-select #fieldsSelect multiple [(ngModel)]="data.import_fields" (selectedChange)="onChangeEvent($event)">
                  <nb-option *ngFor="let field of importFields" [value]="field.value">{{field.value}}</nb-option>
                </nb-select>
                
                  <div class="form-group">
                    <label for="description" class="label">{{ls.strings.minValue}}</label>
                    <input type="number" (change)="onChangeEvent(null)" [(ngModel)]="data.min_value" nbInput fullWidth [placeholder]="ls.strings.minValue">
                  </div>
              
                
                  <div class="form-group">
                    <label for="description" class="label">{{ls.strings.maxValue}}</label>
                    <input type="number" (change)="onChangeEvent(null)" [(ngModel)]="data.max_value" nbInput fullWidth [placeholder]="ls.strings.maxValue">
                  </div>

                  <div class="form-group">
                    <label for="description" class="label">{{ls.strings.min_cutoff}}</label>
                    <input type="number" (change)="onChangeEvent(null)" [(ngModel)]="data.min_cutoff" nbInput fullWidth [placeholder]="ls.strings.min_cutoff">
                  </div>

                  <div class="form-group">
                    <label for="description" class="label">{{ls.strings.daily_backgound}}</label>
                    <input type="number" (change)="onChangeEvent(null)" [(ngModel)]="data.daily_background" nbInput fullWidth [placeholder]="ls.strings.daily_background">
                  </div>

                  <div class="form-group">
                    <label for="description" class="label">{{ls.strings.title}}</label>
                    <input type="string" (change)="onChangeEvent(null)" [(ngModel)]="data.title" nbInput fullWidth [placeholder]="ls.strings.report_column_title">
                  </div>

                  <div class="form-group">
                    <label for="description" class="label">{{ls.strings.cumulated_title}}</label>
                    <input type="string" (change)="onChangeEvent(null)" [(ngModel)]="data.cumulated_title" nbInput fullWidth [placeholder]="ls.strings.report_column_cumulated_title">
                  </div>
             
          </div>
          </div> 
      </div>
      <!-- <div *ngIf="notification" class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <p class="label">{{ls.strings.menu_client_notification}}</p>
            <nb-select #notificationFieldSelector [(ngModel)]="data.notification"  (selectedChange)="onChangeEvent($event)">
                <nb-option *ngFor="let notification of notificationField" [value]="notification">{{notification}}</nb-option>
              </nb-select>
        </div>
        </div> 
    </div> -->

      <div *ngIf="code_comment" class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <p class="label">{{ls.strings.menu_comment_field}}</p>
            <nb-select #commentFieldSelector [(ngModel)]="data.comment_field"  (selectedChange)="onChangeEvent($event)">
                <nb-option *ngFor="let field of commentField" [value]="field">{{field.value}}</nb-option>
              </nb-select>
        </div>
        </div> 
    </div>



      <div *ngIf="measurementFields"  class="row">
        <div class="col-sm-12">
            <div class="form-group">
              <p class="label">{{ls.strings.coefficient}}</p>
              <nb-select #coefficientSelect [(ngModel)]="data.coefficient" fullWidth  (selectedChange)="onChangeEvent($event)">
                <nb-option *ngFor="let coef of coefficients" [value]="coef.value">{{coef.value}}</nb-option>
              </nb-select>
            </div>
          </div>
      </div>

      <div *ngIf="measurementFields" class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <p class="label">{{ls.strings.menu_exposure_types}}</p>
            <nb-select #radiationsSelect multiple [(ngModel)]="data.radiation_types" (selectedChange)="onChangeEvent($event)">
                <nb-option *ngFor="let rad of radiationTypes" [value]="rad.value">{{rad.value}}</nb-option>
              </nb-select>
        </div>
        </div>
      
    </div>
    <div *ngIf="measurementFields" class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <p class="label">{{ls.strings.menu_measurement_units}}</p>
          <nb-select #unitSelect [(ngModel)]="data.measurement_unit" (selectedChange)="onChangeEvent($event)">
              <nb-option *ngFor="let unit of measurementUnits" [value]="unit.value">{{unit.value}}</nb-option>
            </nb-select>
      </div>
      </div>
  </div>
  <div *ngIf="measurement_import_field"  class="row">
     <div class="col-sm-6">
        <div class="form-group">
          <label for="description" class="label">{{ls.strings.report_column_title}}</label>
          <textarea  (change)="onChangeEvent(null)" [(ngModel)]="data.title" nbInput fullWidth [placeholder]="ls.strings.report_column_title"></textarea>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label for="description" class="label">{{ls.strings.report_column_cumulated_title}}</label>
          <textarea  (change)="onChangeEvent(null)" [(ngModel)]="data.cumulated_title" nbInput fullWidth [placeholder]="ls.strings.report_column_cumulated_title"></textarea>
        </div>
      </div> 
  </div>
          <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                  <label for="description" class="label">{{ls.strings.description}}</label>
                  <textarea  (change)="onChangeEvent(null)" [(ngModel)]="data.description" nbInput fullWidth [placeholder]="ls.strings.description"></textarea>
                </div>
              </div>
          </div>
          <div *ngIf="measurementFields" class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="report_method_descriptor" class="label">{{ls.strings.report_method_descriptor}}</label>
                <textarea  (change)="onChangeEvent(null)" [(ngModel)]="data.report_method_descriptor" nbInput fullWidth [placeholder]="ls.strings.report_method_descriptor"></textarea>
              </div>
            </div>
        </div>
          <div *ngIf="stateFields" class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <p class="label">{{ls.strings.prerequisite}}</p>
                <nb-select #dosimeterPrerequisiteSelector [(ngModel)]="data.prerequisite" (selectedChange)="onPrerequisiteChangeEvent($event)">
                  <!-- <nb-option [value]="">*</nb-option> -->
                    <nb-option *ngFor="let pre of dosiPrerequisite" [value]="pre.value">{{pre.value}}</nb-option>
                  </nb-select>
            </div>
            </div>
        </div>
        <!-- </div> -->
      </nb-card-body>
    <nb-card-footer style="text-align: center;">
      <button [disabled]="buttonDisabled || !data.key  || keyExists"  *ngIf="editMode==false" (click)="addData()" nbButton hero status="primary">{{this.ls.strings.add}}</button>
      <button *ngIf="editMode==true" (click)="editData()" nbButton hero status="primary">{{this.ls.strings.save_changes}}</button>
      <button nbButton hero status="primary" (click)="dismiss()">{{this.ls.strings.close}}</button>
    </nb-card-footer>
  </nb-card>