<p>{{page+1}}/{{totalPages}}</p>
                       <div style="display: inline-flex;">
                <button nbButton status="primary" [disabled]="prevDisabled" (click)="prev()"><nb-icon icon="arrowhead-left-outline"></nb-icon></button>
                <div>
                  <nav aria-label="pagination">
                    <ul>
                    
                      <li *ngFor="let p of pages;">
                        <a nbButton
                          class="ng2-smart-page-item page-item"
                          [class.current]="p === page" 
                          (click)="onGoTo(p)"
                          > {{ p+1}}
                          </a>
                      </li>
    
                     
                    </ul>
                  </nav>
                </div>
    
                <button nbButton  status="primary"[disabled]="nextDisabled" (click)="next()"><nb-icon icon="arrowhead-right-outline"></nb-icon></button>
              </div>
          <button class="firstButton" [disabled]="prevDisabled" nbButton hero status="primary" (click)="first()">{{ls.strings.first}}</button>
          <button class="lastButton" [disabled]="nextDisabled" nbButton hero status="primary" (click)="last()">{{ls.strings.last}}</button>
         