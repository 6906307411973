import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../pages/shared/shared/shared.module';
import { NgxAuthRoutingModule } from './auth-routing.module';
import { NbAuthModule } from '@nebular/auth';
import { 
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbInputModule,
  NbStepperModule,
} from '@nebular/theme';
import { NgxLoginComponent } from '../components/login/login.component';
import { RegisterComponent } from '../components/register/register.component';
// import { AuthorizeComponent } from '../components/authorize/authorize.component';
 
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';


@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NgxAuthRoutingModule,
    NbStepperModule,
    NbAuthModule,
    Ng2SmartTableModule,
    NbCardModule,
    FormsModule, ReactiveFormsModule,
    NbEvaIconsModule
    
  ],
  declarations: [
    NgxLoginComponent,
    RegisterComponent,
    ForgotPasswordComponent
    // AuthorizeComponent
  ],


})
export class NgxAuthModule {
 
}
 