import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbCalendarComponent, NbCalendarRange, NbCalendarRangeComponent, NbColumnsService, NbComponentStatus, NbDateService, NbDialogService, NbGlobalPhysicalPosition, NbGlobalPosition, NbRangepickerComponent, NbStepperComponent, NbToastrService, NbViewportRulerAdapter } from '@nebular/theme';
import { AllocationModel } from '../../models/allocation.model';
import { BaseComponent } from '../../../pages/client/base/base-component';
import { DefaultEditor, LocalDataSource, Ng2SmartTableComponent } from "ng2-smart-table";

import { AuthService } from '../../services/auth/auth.service';
import { DataService } from '../../services/data/data.service';
import { LanguageService } from '../../services/language/language.service';
import { ConfirmationComponent } from '../../../pages/client/modals/confirmation/confirmation.component';
import { __makeTemplateObject } from 'tslib';
import { DatePipe } from '@angular/common';
import { ManageProfileComponent } from '../../../pages/client/modals/manage-profile/manage-profile.component';
import { ListPreviewComponent } from '../../../pages/admin/listpreview/listpreview.component';
import { Timestamp } from 'rxjs-compat';
import { FormsModule } from '@angular/forms';
import { timeStamp } from 'console';
import { MonitoringFrequency } from '../../models/monitoringFrequency.model';



@Component({
  selector: 'ngx-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.scss'],
  providers: [DatePipe]
})
export class AllocationComponent extends BaseComponent implements OnInit {



  [x: string]: any;

  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;

  min: Date;
  max: Date;
  s_date:any;
  e_date:any;
  ngModelDate = new Date();
  suborganizations = [];
  currentOrganization: any;
  loadedAlocModelId:any="";
  allocation_start_date: Date;
  allocation_end_date: Date;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
 
 
  @ViewChild('stepper') stepperComponent: NbStepperComponent;
  @ViewChild('allocationTable') allocationTable: Ng2SmartTableComponent;
  @ViewChild('nrDisplay') nrDisplay;
  @ViewChild('rangepicker') rangepicker;//: NbCalendarRange<Date>;

  
  monitoringFrequency= [];
  nrDisplayPerPage = [ 10, 20, 50, 100 ];
  perpage=10
  collection_name="person";
  serviceMethod="getGeneric";
  query={}; //{ size: 10, skip:0, sort: { id: "desc"}};
  date_range:NbCalendarRange<Date>={ start: new Date(), end: new Date()}
  source: LocalDataSource = new LocalDataSource();
  lists_source: LocalDataSource = new LocalDataSource();
  strings: any;
  list_settings = {
    actions:false,
    columns: {
      start_date: { title:this.ls.strings.start_date, type: Date},
      end_date: { title: this.ls.strings.end_date, type: Date}
    }
  }
  settings = {
    selectMode: 'multi',
    actions:{
      columnTitle:this.ls.strings.actions,
      add: false,
      delete: false,
      edit: false
    },
  };


  settings2 = {
    mode: 'external',
    actions:{
      columnTitle:"",
      add: true,
      delete: true,
      edit: false
    },
    add: {
      addButtonContent:'<i class="nb-plus"></i>',
      // createButtonContent: '<i class="nb-checkmark"></i>',
      // cancelButtonContent: '<i class="nb-close"></i>',
      
      confirmCreate: false,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
  };
  
  selectedRows: any;
  selectedPersons:any[];
  finalPersons:any[];
  monitoringPersons: [];
  public missingMonitoringType:boolean;
  public confimationShow: boolean;
  
    public allocationList:AllocationModel; 
    personsSource: LocalDataSource = new LocalDataSource();
    constructor(private fb: FormBuilder,
    private datePipe:DatePipe,
    protected dateService: NbDateService<Date>,
    public auth: AuthService,
    public data: DataService,
    
    public ls: LanguageService,
    public toastrService: NbToastrService,
    private dialogService: NbDialogService) {
      super(data);
      this.title_key="component_title_allocation";
    let alloc:AllocationModel=new AllocationModel();
    this.selectedRows=[];
    this.selectedPersons=[];
    this.finalPersons=[];
    this.allocationList=new AllocationModel();
    this.allocationList.creator=this.auth.user.username;
      this.allocation_start_date=new Date(Date.now());
      this.allocation_end_date=new Date(Date.now());
  }

  ngOnInit(): void {
    // this.frequency = [];

    this.getFrequency();
  }

  private cloneObj(o):any {
    let n={};
    for (var k in o) {
      n[k]=o[k]
    }
    return n;
  }
  private normalizeData(arr):any {
    this.missingMonitoringType=false;
    console.log("normalizing ", arr.length,arr)
    let result=[];
    for (var i=0; i<arr.length; i++) {
      
      let ob=arr[i];
      //console.log(i,ob)
      if (ob.monitoring_type) {
      if (ob.monitoring_type.length == 1) {
        result.push(ob);
      } else if (ob.monitoring_type.length >1) {
       
        console.log(arr[i], ob.monitoring_type)
        for (var j=0; j<ob.monitoring_type.length; j++) {
          let _ob=this.cloneObj(arr[i]);
        _ob.monitoring_type=ob.monitoring_type[j];
        //console.log(j, _ob);
        result.push(_ob);

      }
      }
    } else {
      this.missingMonitoringType=true;
          //return false;
    }
    }
    return result;
  }


  public showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 5000,
      hasIcon: true,
      position: this.position,
      preventDuplicates: true,
    };
    const titleContent = title ? ` ${title}` : "";

    this.toastrService.show(body, `${titleContent}`, config);
    console.log(title);
  }


  stepChangeHandler (ev) {
    console.log("doing something????",ev)
    this.updateSelection()
  }  

  viewMonitoredPersons(ev){
    console.log(ev.data.users)
    this.dialogService.open(ListPreviewComponent, {
      context: {
        body: this.ls.strings.monitored_persons,
        // lists_source: ev.data.users,
        confirm: this.ls.strings.confirm_list,
        create: this.ls.strings.create_from_this,
        cancel: this.ls.strings.cancel
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
  
    .onClose.subscribe(result => {
      if(result){
        console.log('llllll',result)
        this.allocationListSelect(ev, result);
      }

    });

  }
  
  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }


  ngAfterViewInit() {
    this.reinit();
  }


  protected reinit(ob?:any){
 // this.getOlderAllocationLists();
 console.log("--------------------------------------------");

  this.getCurrentOrganizationDetails();
  this.getSuborganizations();
  this.loadedAlocModelId="";
  

  // this.rangepicker.rangeChange.subscribe(e =>{
  //   console.log("ONCHANGE....",e);
  //   if (e.start) { this.s_date=e.start;}
  //   if (e.end) { this.e_date=e.end; }
  //   });

 }

 exposedOrganizationChange(id){
  this.selectedOrganizationExposed = id;
  this.getOlderAllocationLists(id);
}


  async onFirstSubmit() {
    this.missingMonitoringType=false;
   // this.firstForm.markAsDirty();
   console.log("this.allocationList.users_length", this.allocationList.users, this.allocationList)
    this.finalPersons=[];

    if (this.allocationList.users && this.allocationList.users.length>0) {
      console.log("|it s in|")
      this.finalPersons=this.allocationList.users;
      this.selectedPersons=[];
      await this.addMonitoringList(this.allocationList.users);// this.finalPersons;
      //this.personsSource.load(this.selectedPersons);
      //this.stepperComponent.next();  this.stepperComponent.next();
      this.stepperComponent.selectedIndex=2;
      this.onSecondSubmit()
    }
    else {
    this.personsSource.load(this.selectedPersons);

    this.stepperComponent.next();
    
    }
    if (this.allocationList.start_date) {
    this.date_range.start=new Date(this.allocationList.start_date);
    }
    if (this.allocationList.end_date) { 
      this.date_range.end=new Date(this.allocationList.end_date);
    }
 
  }

  private async getOlderAllocationLists(id) {
   // let _obj:any=new AllocationModel(); 
   //_obj.client_id= this.auth.user.preffered_organization;
  console.log("xxxxxxxxx", id)
  let _obj= { "query": { "query": { "client_id": id } , limit:0, skip:0, "collection_name": "allocation_lists"}} 
  //let _obj= { "query": { "query": { "client_id": id, "collection_name": "allocation_lists" } , "collection_name": "allocation_lists"}}
    let r=await this.data.getGeneric(this.auth.user.token, _obj);
    console.log("r is ", r);
    this.list_settings  = Object.assign({},  {
      actions: false,
      columns: {
        start_date: { title:this.ls.strings.start_date, type: Date, 
        valuePrepareFunction: (d) => {
          return this.datePipe.transform(new Date(d), 'longDate');
        }
        },
        end_date: { title: this.ls.strings.end_date, type: Date,
        valuePrepareFunction: (d) => {
         var nd: any 
         try{nd = this.datePipe.transform(new Date(d), 'longDate')} catch (e){ console.log ("SMTH", d); nd=new Date()}
         return nd;
        }},
        users_length: { title: this.ls.strings.persons_nr, type: Number},
        modified_date: { title: this.ls.strings.modified_date, type: Date,
        valuePrepareFunction: (d) => {
          return this.datePipe.transform(new Date(d), 'longDate');
        }}
      }
    });
    
    r.docs.forEach(el => {
      el['users_length']=el.users.length
    });

    this.lists_source.load(r.docs)
  }

  onSecondSubmit() {
  console.log("CEVA",this.rangepicker)
   console.log("this.missingMonitoringType", this.missingMonitoringType)
   if (this.missingMonitoringType) {
    this.showToast("danger", this.ls.strings.no_monitoring_type,'');
    return;
  }
    console.log("???", this.finalPersons)
    
    this.assignSettings(this.finalPersons.length);
   
    this.personsSource.load(this.finalPersons);
    console.log("----------", this.allocationList)

    this.allocation_start_date=new Date(this.allocationList.start_date);
    this.allocation_end_date=new Date(this.allocationList.end_date);

    console.log("xxxxxxxxx",this.date_range)
  }

  onThirdSubmit() {
    this.thirdForm.markAsDirty();
  }

  organizationChange(id){
    this.getSuborganizationExposed(id);
  }

  async getCurrentOrganizationDetails(){
    let current = await this.data.getOrganizationDetails(this.auth.user.preffered_organization, this.auth.user.token);
    this.currentOrganization = current.docs[0];
    this.getOlderAllocationLists(this.currentOrganization._id);
    this.getSuborganizationExposed(this.currentOrganization._id)
  }

  async getSuborganizations(){
    let resp = await this.data.getUserSuborganizations(this.auth.user.preffered_organization, this.auth.user.token, true);
    console.log('SUBORG', resp)
    if(resp.status!=404){
      this.suborganizations = resp;
    }
    this.assignSettings();
    
  }
 
  async getSuborganizationExposed(suborganizationId){
    console.log(suborganizationId)
    this.query={organizations: suborganizationId}
    let resp = await this.data.getOrganizatonPersons(suborganizationId, this.auth.user.token, true)
    if(resp.persons.length > 0){
      this.source.load(resp.persons);
      //this.updateSelection();
    }
    else  this.source.load([]);
    this.updateSelection();
  }

  addToSelection(_data:any){
    //console.log("adding", _data, this.selectedPersons.indexOf(_data))
    if (this.selectedPersons.indexOf(_data)==-1)
    this.selectedPersons.push(_data);
  }

  removeFromSelected(_data) {
    //console.log("removing ",_data)
    if (_data.length>1) {
      for (var i=0;i<_data.length;i++) {
        this.removeElement(_data[i]);
      }
    } else {
      this.removeElement(_data)
    }
  }

  removeElement(_data) {
   // console.log("rem ",_data)
    for (var k=0;k<this.selectedPersons.length;k++) {
      if (this.selectedPersons[k]==_data) {
       this.selectedPersons.splice(k,1);
       return;
      }
    }
  }
 async onRowSelect(event) {
   // console.log(event, event.data);
    this.selectedRows = event.selected;
    console.log(event)
    console.log(this.selectedRows)
    //this.secondForm.setValue({secondCtrl:this.selectedRows})
    if (event.data) {
    if (event.isSelected) { this.addToSelection(event.data)}
    
    else { this.removeFromSelected(event.data)}
    } else
    {
      if (event.selected.length>0) {
          console.log("multiple add")
          for (var k in event.selected) {
            this.addToSelection(event.selected[k])
          }
      } else {
          console.log("multiple remove")
          let arr=this.personsSource.getElements()
          for (var k in arr) {
            this.removeFromSelected(arr[k])
          }
      }

    }
    //this.personsSource.load(this.selectedPersons);
    this.finalPersons=this.normalizeData(this.selectedPersons);
   
    console.log(await this.personsSource.getAll())
 

    this.allocationList.users=this.finalPersons;

    console.log("allocation list ", this.allocationList)
  
  }

allocationListSelect (event, create_type) {
  // this.selectedPersons = event.data.users, client_id
  this.allocationList=new AllocationModel();
  this.allocationList=this.allocationList.fromJSON(JSON.parse(JSON.stringify(event.data)));
  this.loadedAlocModelId=event.data._id;
  if (create_type!="confirm") {
  this.allocationList.end_date=null;
  this.allocationList.start_date=new Date(Date.now());
  event.data._id=null;
  this.loadedAlocModelId==""
  }
  console.log('aldsfsfdf', this.loadedAlocModelId, event);
  console.log("alloc model = ", this.allocationList);
  this.finalPersons=this.allocationList.users;
  // mergi la pasul 3 DUPA ce pui data din picker, modifier date = now, modifier = eu
  this.onFirstSubmit();
}


 updateSelection() {
   //console.log(this.allocationTable)
   setTimeout(()=>{
     console.log("updateSELECTED")
   this.allocationTable.grid.getRows().forEach(row => {
     let _dat:any=row['data'];
     let ind=this.selectedPersons.indexOf(_dat);
     //console.log(ind, "_dat",_dat)
     if (ind>-1) {
       //console.log("should be selected")
       if (!row.getIsSelected())
       this.allocationTable.grid.multipleSelectRow(row);
     }
     //console.log(row);
   });
  }, 0)
 }

 getConfimationTextBody():string {
  let s='';
  let aloc=this.allocationList;
s=s + this.ls.strings.confirmation_text;
s=s+"<br/>";
s=s+"<br/>";
s=s+this.ls.strings.allocation_user_len+": "+ aloc.users.length;
s=s+"<br/>";

s=s+this.ls.strings.allocation_period+": "+ 
this.datePipe.transform(this.allocation_start_date, 'mediumDate')+ " - " +
this.datePipe.transform(this.allocation_end_date, 'mediumDate') +  "=" + this.frequency;
return s;
}

 async openConfirmationDialog() {
  
   if(!this.allocation_start_date || !this.allocation_end_date ){
     this.showToast('warning', this.ls.strings.period_not_selected, '');
     return;
   }
console.log("this.allocationList.users_length",this.allocationList.users.length)
   if(this.allocationList.users.length < 1) {
    this.showToast('warning', "you must select an exposed", '');
    return;
  }

  this.dialogService.open(ConfirmationComponent, {
    context: {
      body: this.getConfimationTextBody(),
      confirm: this.ls.strings.confirm,
      cancel: this.ls.strings.cancel
    },
    hasBackdrop: true,
    closeOnBackdropClick: false
  })
  .onClose.subscribe(async result => {
    if(result){
      console.log('r', result)
      this.saveList();
    }
  })
 }

 async addMonitoringList(arr){
    let _exposed = await this.data.getOrganizatonPersons(this.auth.user.preffered_organization, this.auth.user.token)
    //console.log("add to monitoring ", _exposed.persons)
  for (var i=0; i<arr.length; i++) {
    let _id:any=arr[i]._id;
    let element=_exposed.persons.filter(elem => elem._id==_id)[0];
    if (element) { this.addToSelection(element)}
    //console.log("_id ", _id, element)
  }
};


 changeDate(e) {
       console.log("changeDate....",e);
  //if (e.start) { this.s_date=e.start;}
  //if (e.end) { this.e_date=e.end; }
  //console.log(this.rangepicker)
 }


 async getFrequency() {
  let resp = await this.data.getGeneric(this.auth.user.token, new MonitoringFrequency())
  this.monitoringFrequency = resp.docs;
  console.log('monitoringFrequency', this.monitoringFrequency)
 }


 async saveList () {
   console.log("it works");
   
   this.allocationList.client_id=this.currentOrganization;
   this.allocationList.start_date=this.allocation_start_date;
   this.allocationList.end_date=this.allocation_end_date;

   let resp;
   if (this.loadedAlocModelId=="") {
   resp=await this.data.postGeneric(this.auth.user.token, this.allocationList, null);
   } else {
     
     this.allocationList._id=this.loadedAlocModelId;
     this.allocationList.modified_date=new Date();
    //  this.allocationList.start_date=;
    //  this.allocationList.end_date=;
     console.log("into update", this.allocationList);
     resp=await this.data.updateGeneric(this.auth.user.token,this.allocationList);
   }
   console.log("responses", resp);
  
  if (resp.status == 200)  {
    console.log("ok");
    this.showToast("success", this.ls.strings.success,'');
    this.stepperComponent.next();

  }
  else {
    this.showToast("warning", this.ls.strings.warning,''); 
  }

 }

  private assignSettings(_perPage?:number) {
    if (!_perPage) { _perPage=10; }
    console.log("_perPage", _perPage)
    
    this.settings  = Object.assign({},  {
      //actions: false, 
      //mode:'multi',
      
      actions:{
        columnTitle:this.ls.strings.actions,
        add: false,
        delete: false,
        edit: false
      },
      selectMode: 'multi',
      pager : {
        display: true,
        perPage: _perPage
      },
    
      add: {
        addButtonContent: '<i class="nb-plus"></i>',
        confirmCreate: true,
      },
      edit: {
        editButtonContent: '<i class="nb-edit"></i>',
        saveButtonContent: '<i class="nb-checkmark"></i>',
        cancelButtonContent: '<i class="nb-close"></i>',
        confirmSave: true,
      },
      delete: {
        deleteButtonContent: '<i class="nb-trash"></i>',
        confirmDelete: true,
      },

      attr : {
        class: 'allocationTable'
      },
      columns: {
       
        firstName: {
          title: this.ls.strings.first_name,
          type: "string",
        },
        lastName: {
          title: this.ls.strings.last_name,
          type: "string",
        },
        middleName: {
          title: this.ls.strings.middleName,
          type: "string",
          editable: false,
        },
        maidenName: {
          title: this.ls.strings.maidenName,
          type: "string",
        },
        initial: {
          title: this.ls.strings.initial,
          type: "string",
        },

        cnp: {
          title: this.ls.strings.cnp,
          type: "string",
        },
        profession : {
          title: this.ls.strings.menu_professions,
          type: "string",
        },
        monitoring_type : {
          title: this.ls.strings.menu_monitoring_types,
          type: "string",
        },
        email: {
          title: this.ls.strings.email,
          type: "string",
        },
        phone: {
          title: this.ls.strings.telefon,
          type: "string",
        },
        organizations: {
          title: this.ls.strings.organizations,
          type: "string",
        }
      
      },
    });


    this.settings2  = Object.assign({},  {
      mode:'external',
      pager : {
        display: true,
        perPage: 10
      },
      actions:{
        columnTitle:this.ls.strings.actions,
        add: true,
        edit: false,  
        delete: true
      },
      add: {
        addButtonContent:'<i class="nb-plus"></i>',
        
        confirmCreate: false,
      },
      delete: {
        deleteButtonContent: '<i class="nb-trash"></i>',
        confirmDelete: true,
      },


      columns: {
       
        firstName: {
          title: this.ls.strings.first_name,
          type: "string",
        },
        lastName: {
          title: this.ls.strings.last_name,
          type: "string",
        },
        middleName: {
          title: this.ls.strings.middleName,
          type: "string",
          editable: false,
        },
        maidenName: {
          title: this.ls.strings.maidenName,
          type: "string",
        },
        initial: {
          title: this.ls.strings.initial,
          type: "string",
        },

        cnp: {
          title: this.ls.strings.cnp,
          type: "string",
        },
        profession : {
          title: this.ls.strings.menu_professions,
          type: "string",
        },
        monitoring_type : {
          title: this.ls.strings.menu_monitoring_types,
          type: "string",
        },
        email: {
          title: this.ls.strings.email,
          type: "string",
        },
        phone: {
          title: this.ls.strings.telefon,
          type: "string",
        },
        organizations: {
          title: this.ls.strings.organizations,
          type: "string",
        }
      
      },
    });
  }

  selectionFrequencyChange(event){
    let n = this.frequency;
    let d= this.allocation_start_date;
   let new_date=new Date(d.toDateString());
   new_date.setMonth(d.getMonth() + Number(n));
   this.allocation_end_date=new_date;
    console.log("FREQUENCY ", new_date)
  }


  async getUsers() {
    let resp = await this.data.getOrganizatonUsers(
      this.auth.user.preffered_organization,
      this.auth.user.token
    );
   ;
  }
 
  sources() {
    console.log(this.source["data"]);
  }



  onCreate(event): void {
   this.stepperComponent.selectedIndex=1;
  }


  onDelete(event): void {
    console.log(event,"PPPPPPPPPPPPPPPPP")
    this.dialogService.open(ConfirmationComponent, {
      context: {
        body: this.ls.strings.delete_confirmation,
        confirm: this.ls.strings.yes,
        cancel: this.ls.strings.cancel
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(result => {
      if(result == 'confirm'){
        console.log("---------------------", event)
        console.log(this.finalPersons, event.index);
        this.finalPersons.splice(event.index,1);
        this.personsSource.load(this.finalPersons);
      }
    })
  }




}



