import { Address } from "./address.model";
import { BaseModel } from "./base.model";

export class PersonModel extends BaseModel {
    public personId: String;
    public firstName: String;
    public lastName: String;
    public middleName: String;
    public initial: String;
    public maidenName: String;
    public motherMaidenName: String;
    public uid: String;
    public code: Number;
    public cnp: String;
    public dob: Date;
    public nationality: String;
    public sex: String; 
    public email: String;
    public phone: String;
    public practice: String;
    public dom: Date;
    public description: String;
    public organizations: [];
    public monitoring_type =[];
    public irregular: Boolean;
    public extra: Boolean;
    // expansion for area monitoring
    public created: Date;
    public createdBy: String;
    public modifiedBy: String;
    public classified: Boolean;
    public type:String //- sa ia valori person sau area
    public location:String;
    public address: Address; 
    //public group:string;
    constructor() {
        
        super();
        this.collection_name="person";

    } 
    protected generateInit() {
        this.personId='';
        this.active = false;
        this.firstName='';
        this.lastName='';
        this.maidenName='';
        this.motherMaidenName='';
        this.uid='';
        this.code=0;
        this.cnp='';
        this.dob=null;
        this.nationality='';
        this.sex='';
        this.initial='';
        this.email='';
        this.phone='';
        this.practice='';
        this.dom=null;
        this.description='';
        this.organizations=[];
        this.monitoring_type=[];
        this.created=null;
        this.createdBy='';
        this.modifiedBy='';
        this.classified=false;
        this.irregular=false;
        this.extra=false;
        // the following are for area monitors
        this.type='';
        this.address=new Address()
    }
}