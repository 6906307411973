import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { NbComponentStatus, NbDateService, NbDialogRef, NbDialogService, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import { BaseComponent } from '../../client/base/base-component';
import { AllocationModel } from "../../../@core/models/allocation.model";
import { DosimeterMeasurement } from '../../../@core/models/dosimeterMeasurement.model';
import { LocalDataSource, Ng2SmartTableComponent, ViewCell } from 'ng2-smart-table';
import { PrinterLabel } from '../../../@core/models/printerlabel.model';
import { DosimeterMake } from '../../../@core/models/dosimeterMake.model';
import { DosimeterInputComponent } from './dosimeterInput/dosimeterInput.component';
import { PrinterService } from '../../../@core/services/printer/printer.service';
import { PositionModel } from '../../maps/search-map/entity/position.model';
import { DosimeterType } from '../../../@core/models/dosimeterType.model';
import { DoseRecordModel } from '../../../@core/models/doseRecord';
import { DosimeterModel } from '../../../@core/models/dosimeter.model';
import { Observable, of } from 'rxjs';
import { GridComponent } from '../../ui-features/grid/grid.component';
import { Grid } from 'ng2-smart-table/lib/lib/grid';
import { DataSet } from 'ng2-smart-table/lib/lib/data-set/data-set';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { OrganizationModel } from '../../../@core/models/organization.model';
import { Row } from 'ng2-smart-table/lib/lib/data-set/row';
import { Countries } from '../../../@core/models/countries.model';
import { Address } from '../../../@core/models/address.model';
import { ChangeDetectorRef } from '@angular/core';
import { MonitoringFrequency } from '../../../@core/models/monitoringFrequency.model';

@Component({
  selector: 'ngx-admin-allocation',
  templateUrl: './admin-allocation.component.html',
  styleUrls: ['./admin-allocation.component.scss']
})
export class AdminAllocationComponent extends BaseComponent implements OnInit {
  @Input() lang: any;
  @ViewChild('exposedOrganizationSelect') exposedOrganizationSelect;
  @ViewChild('monitoringTypesSelect') monitoringTypesSelect;
  @ViewChild ('countriesSelect') countriesSelect;
  @ViewChild('suborganizationsSelect') suborganizationsSelect;
  @ViewChild('dosimetrySystemTypesSelect') dosimetrySystemTypesSelect;
  @ViewChild('dosimeterTypesSelect') dosimeterTypesSelect;
  @ViewChild('printerLabelSelect') printerLabelSelect;
  @ViewChild('allocationTable') allocationTable:Ng2SmartTableComponent;
  @ViewChild("hiddenRow") hiddenRowRef!: ElementRef;
  @ViewChild("frequencySelect") frequencySelect;
  
  @Input() dosimeterMake: any[];
  @Input() dosimeterType: any[];
  workplace_address:Address=new Address();
  selectedDosimeterMake:DosimeterMake;
  selectedDosimeterType: DosimeterType;
  selectedSystem:DosimeterMake;
  selectedCountry:Countries;
  selectedCounties:any[];
  maxCode:number;
  minCode:number;
  client_minCode:number;
  client_maxCode:number;
  allocationStart:Date;
  client:string;
  
  clientsList=[];
  countries=[];
  monitoringTypes=[];
  organizations=[];
  suborganizations=[];
  allocableWearers=[];
  printerLabels=[];
  allocableDosi=[];
  frequencies=[];
  selectedDate:Date;
  current:any = new Date();
  firstDay = new Date(this.current.getFullYear(), this.current.getMonth(), 1);
  formattedDate: Date;
  static SelectedDateValue:Date;
  static SelectedPrinter:any;
  selectedPrinter:any;
  selectedOrganization:any;
  selectedRow: any;
  external= "";
  countWearers: Number;
  allocableAnnealedDosi: Number;
  filteredorganizationsOptions$: Observable<string[]>;

  currentOrganization: any;
  allocationFilterFields: any[] = [];
  allocationSettings:any = {
    mode:'external',
    pager : {
      display: true,
      perPage: 10
    },
    actions: false, columns: {} };
  allocationSource: LocalDataSource = new LocalDataSource();
  dosimeterInputs: any[] = [];
  loadingData:Boolean=false;
  allocationFullColumns= {}
  static selectedDosimeterMake:any;
  static selectedDosimeterType:any;

  constructor(public data: DataService, public auth: AuthService, public ls:LanguageService, 
    public toastrService: NbToastrService, 
    private utils:UtilsService,
    private printerService:PrinterService, private cdr: ChangeDetectorRef) {
    super(); 
    this.selectedDosimeterMake = new DosimeterMake();
    this.selectedDosimeterType = new DosimeterType();
    this.title_key="component_title_admin_allocation";
    AdminAllocationComponent.componentStaticKey=this.title_key;
    this.selectedCountry=new Countries();
    this.selectedCounties=[];
   }

  ngOnInit(): void {
    this.initialize();
    
  };
  shouldShowField(fld):Boolean {
    //let bool=false;
    let bool=true?this.allocationFilterFields.indexOf(fld)>-1:false;
    return bool;
  }
  async initialize() {
    if (this.workplace_address==undefined) { this.workplace_address=new Address(); }
    this.allocationFilterFields = await this.utils.getAllocationFilterFields();
    let resp:any= await this.data.getParentOrganizationsAdmin(this.auth.user.token, {}, true);
    let labels_resp:any = await this.data.getPrintLabels({active:true}); //(this.auth.user.token, new PrinterLabel(), {});
    this.printerLabels=labels_resp.docs;
    console.log("printer Labels is ", this.printerLabels)
    this.organizations = resp;
    this.filteredorganizationsOptions$= of (this.organizations);
    if (resp.status==404) {
      this.organizations=[];
    }
    this.suborganizations=[];
    this.suborganizationsSelect.selected=null;

    await this.getMonitoringType();
    await this.getCountries();
    await this.getFrequencies();
    await this.getDosimetrySystemManufacturer();
    this.showColumns();

  }

  protected reinit(){
    
    this.lang=this.ls.lang;
    this.ls.mySubject.subscribe(message => {
     
      this.allocationSettings = { columns: {} }
 
      setTimeout(() => {
       this.showColumns();
    
      }, 100);
      
  });
  this.showWearers(-99);
  };

  
  exposedOrganizationChangeAutocomplete(id) {
    console.log("event id is : ", id)
    let orgid;
    try {
    orgid=this.organizations.filter(el=>el.denumire==id)[0]._id;
    } catch(e) {
      this.client=null;
      orgid=null;
    }
    console.log("orgid is ", orgid)
    this.getSuborganizations(orgid);
  }

  async getSuborganizations(event) {
    console.log("get suborganization ", event, event==null);
    if (event==null) { 
      console.log("selected is ", event)
      this.suborganizations=[];
      this.suborganizationsSelect.selected=null;
      this.selectedOrganization=null;
    } else { 
      let r:any = await this.data.getUserSuborganizations(event, this.auth.user.token, true);
      this.suborganizations=r;
      this.selectedOrganization=event; 
      this.suborganizationsSelect.selected=null;
      console.log("selectedOrg is ", this.selectedOrganization)
    }
  };

  exposedSuborganizationChange(event) {
    console.log("event exposed ", event)
    if (event!=null) {
    this.selectedOrganization=event;
    
    } else {
      if (this.exposedOrganizationSelect.selected!=null) {
        this.selectedOrganization=this.exposedOrganizationSelect.selected;
      } 
      else {
        this.selectedOrganization=null;
      }
    }
  }

  async getMonitoringType() {
     let resp= await this.data.getGeneric(this.auth.user.token, new DosimeterMeasurement());
     console.log("RESP in initialize is ", resp)
     for (let k in resp.docs) {
          if (resp.docs[k].active != false) {
      this.monitoringTypes.push(resp.docs[k])
     }
    }
     console.log("tipuri ", this.monitoringTypes)
  };

  async getFrequencies() {
    let resp = await this.data.getGeneric(this.auth.user.token, new MonitoringFrequency())
    this.frequencies = resp.docs;
  };

  async getCountries() {
    let resp = await this.data.getGeneric(this.auth.user.token, new Countries());
    this.countries = resp.docs;
    try {
    this.selectedCountry=this.countries.filter(elem=>elem['value']==this.workplace_address.country)[0];
    } catch(e) {
      this.selectedCountry=new Countries();
     }
  };

  async setCountryChange(event) {
    console.log("set country ", event);
      this.selectedCountry=this.countries.filter(elem=>elem['value']==event)[0];
      this.selectedCounties=this.selectedCountry.counties;
   
  };
  
  async getDosimetrySystemManufacturer() {
    let resp= await this.data.getGeneric(this.auth.user.token, new DosimeterMake());
    this.dosimeterMake= resp.docs;
    console.log("Manufacturer is ", this.dosimeterMake)
  };

  setDosimetrySystemManufacturer(event) {
    console.log("setDosimetrySystem ", event)
    try {
    if (event!=null) {
    this.selectedDosimeterMake=event;
    console.log(this.selectedDosimeterMake);
    this.dosimeterTypesSelect.selected=null;
    AdminAllocationComponent.selectedDosimeterMake=event.value;
    this.selectedDosimeterType=null;
    AdminAllocationComponent.selectedDosimeterType=null;
    this.getUnwided();
    } else {
      this.selectedDosimeterMake=new DosimeterMake();
      this.dosimeterTypesSelect.selected=null;
      AdminAllocationComponent.selectedDosimeterType=null;
      AdminAllocationComponent.selectedDosimeterMake=null;
      this.getMonitoringType()
    }
    } catch(e) { console.log("error ",e)}
  };

  setDosimetryType(event) {
    this.selectedDosimeterType=event;
    console.log("Selected dosimeter type",this.selectedDosimeterType);
    if (event!=null) {
    AdminAllocationComponent.selectedDosimeterType=event.value;
    } else {
      AdminAllocationComponent.selectedDosimeterType=null;
    }
    this.getUnwided();
    
  };

  async onDateChangeEvent(event) {
    this.selectedDate=new Date(event.srcElement.value);
    let day=await this.utils.getDateForAllocation();
    
    console.log("Selected date is ", this.selectedDate, event)
    if (day!=0) {
      this.selectedDate.setDate(day)
    }
    
    this.formattedDate = this.utils.getFormattedDate(this.selectedDate, "yyyy-MM-dd")
    this.allocationStart=this.formattedDate;
    console.log("formattedDate is ", this.formattedDate)
    AdminAllocationComponent.SelectedDateValue=this.formattedDate;
  };

  private _filter(denumire: string): any[] {
    const filterValue = denumire.toLowerCase();
    return this.organizations.filter(org => org.denumire.toLowerCase().includes(filterValue));
  }

  onOrganizationChange(denumire: string) {
    this.filteredorganizationsOptions$ = of(this._filter(denumire));
  };

  clearAutoCompleteValue() {
    this.client="";
  };

  changeVal(event) {
  };

  async showWearers(indexToFocus: number = -99) {
    if (this.loadingData) { return }
    this.dosimeterInputs=[];
    let d:Date=this.selectedDate; 
    let _org:string;
    let code: number
    let _frequency: string;
    //try { _org=this.exposedOrganizationSelect.selected; } catch(e) { _org="" } // this one comes from the client dropdown
    console.log("selected org is ", this.selectedOrganization)
    try { _org=this.selectedOrganization} catch(e) { _org="";}
    let _service:string;
    let _dosimeterMake:string;
    let _dosimeterType:string;
    try {_dosimeterMake=this.dosimetrySystemTypesSelect.selected.value} catch(e) {_dosimeterMake=null};
    try {_dosimeterType=this.dosimeterTypesSelect.selected.value} catch(e) {_dosimeterType=null};
    try { _service=this.monitoringTypesSelect.selected} catch(e) {_service=null};
    try { _frequency = this.frequencySelect.selected} catch(e) {_frequency=null};

    DosimeterInputComponent.type=_service;
    if (indexToFocus==-99) {
      this.loadingData=true;
    let resp = await this.data.getAllocableWearers(d, _org, _service, _dosimeterMake, _dosimeterType, this.auth.user.token, _frequency,  this.minCode, this.maxCode,
      this.client_minCode, this.client_maxCode, this.workplace_address);
    console.log(" this resonse is ", resp)
    this.allocableWearers = resp.docs;
    this.loadingData=false;
    } else {
      console.log("here we should do something to remove??")
    }
    console.log('Wearers to be allocated are', this.allocableWearers)
    console.log("Nr of allocable wearers is ", this.allocableWearers.length);
    this.countWearers=this.allocableWearers.length;
    
    for (var k in this.allocableWearers) {
      this.allocableWearers[k].position=this.allocableWearers[k].monitoring_type.position;
      this.allocableWearers[k].monitoring_frequency=this.allocableWearers[k].monitoring_type.monitoring_frequency;
      //show details in table
      this.allocableWearers[k].suborganization=this.allocableWearers[k].monitoring_type.suborganization;
      this.allocableWearers[k].suborganization_code=this.allocableWearers[k].monitoring_type.suborganization_code;
      this.allocableWearers[k].denumire=this.allocableWearers[k].monitoring_type.denumire;
      this.allocableWearers[k].organization_code= this.allocableWearers[k].monitoring_type.organization_code;
      try { this.allocableWearers[k].monitoring_type.mt.price = this.allocableWearers[k].monitoring_type.mt.price } catch (e) { console.log("no price for ", this.allocableWearers[k].monitoring_type.organization_code)}
      
    }
    console.log('Wearers to be allocated', this.allocableWearers)
    this.showColumns(indexToFocus);
    this.setClientsList(this.allocableWearers)
    this.allocationSource.load(this.allocableWearers);
    this.getAllocableDosimeters();
  }

  setClientsList(arr) {
    this.clientsList=[];
    for (var k in arr) {
      if (this.clientsList.indexOf(arr[k].denumire,0)==-1) {
        this.clientsList.push(arr[k].denumire)
      }
    }
    console.log("this clients list is ", this.clientsList)
  }

  getRowColor(row) {
    let colors=['row0', 'row1']; //, 'row2', 'row3', 'row4'];
    let ind=this.clientsList.indexOf(row);
    let color=colors[ind % colors.length]
    return "";
  }

  async getAllocableDosimeters() {
    let _dosimeterMake:string;
    let _dosimeterType:string;
    try {_dosimeterMake=this.dosimetrySystemTypesSelect.selected.value} catch(e) {_dosimeterMake=null};
    try {_dosimeterType=this.dosimeterTypesSelect.selected.value} catch(e) {_dosimeterType=null};
    let state: string;
    //let res= await this.data.getAllocableAnnealedDosi(_dosimeterMake, _dosimeterType, _state, this.auth.user.token);
    let _q={ collection_name: "dosimeters", query: { state: "annealed"}}
    if (_dosimeterMake!=null) { _q.query['make']=_dosimeterMake }
    if (_dosimeterType!=null) { _q.query['type']=_dosimeterType }
    let res = await this.data.countDocuments(_q, this.auth.user.token)
    this.allocableAnnealedDosi= res.count;
  }

  async getUnwided() {
    let _m: string;
    
    let resp = await this.data.getUnwidedDosimetryTypes(AdminAllocationComponent.selectedDosimeterMake, AdminAllocationComponent.selectedDosimeterType, this.auth.user.token);
    console.log("RESP IN ALLOCS MT IS ", resp)
    this.monitoringTypes=[];
    for (var k in resp.docs) {
      let _m=resp.docs[k];
      this.monitoringTypes.push({value: _m})
    }
    this.monitoringTypesSelect.selected=null;
    console.log("xxxxxxxxxxxxxxxxxxxxxx",resp)
  }  
 

  tableRowSelect(event) {
    console.log("Row event is ", event)
    this.selectedRow = event.data;
  }

  hideNames(row:any) {
    let fake_value=row.denumire;
    let grid=this.allocationTable.grid.getRows(); 
    let ind=-1;
    for (var k in grid) {
      if (grid[k].getData()==row) {
        ind=grid[k].index;
        break;
      }
    }
    if (ind>0) {
      let r=grid[ind-1].getData();
      if (row.denumire==r.denumire) {
        fake_value="";
      }
    }
    return fake_value;
  }
  onCustomEvent($event) {
    console.log("on custom event ",$event)
  }
  showColumns(indexToFocus: number = -99) {
    this.dosimeterInputs=[];
    let previousRow=''
    
    this.allocationSettings  = Object.assign({},  {
      mode:'external',
      pager : {

        display: true,
        perPage: 10
      },
      actions: false, 
      rowClassFunction: (row)=> {
        //console.log("--------------row: ",row, row.previousRow, row.data);
        return this.getRowColor(row.data.denumire)
       
      },
 
      columns: {
        fake_name: {
          title: this.ls.strings.denumire,
          type: "html",
          valuePrepareFunction:(cell, row) => {
            let ob=this.hideNames(row);
            return ob;
          }
        },
       /*  denumire: {
          title: this.ls.strings.denumire,
          type: "html",
          valuePrepareFunction:(ob) => {
            //console.log("prepare function ---------------", ob, ob.dataSet)
            return ob;
          }
        }, */
        organization_code: {
          title: this.ls.strings.organization_code,
          type: "string",
        },
        suborganization: {
          title: this.ls.strings.denumire_suborganizatie,
          type: "string",
        },
        suborganization_code: {
          title: this.ls.strings.suborganization_code,
          type: "string",
        },
        position: {
          title: this.ls.strings.menu_dosimeter_position,
          type: "string",

        },
        lastName: {
          title: this.ls.strings.last_name,
          type: "string",
          
        },
        firstName: {
          title: this.ls.strings.first_name,
          type: "string",
       
        },
        monitoring_frequency: {
          title: this.ls.strings.menu_monitoring_frequency,
          type: "string"
        },

        // cnp: {
        //   title: this.ls.strings.cnp,
        //   type: "string",
        
        // },

        dosimeter_id: {
          title: this.ls.strings.dosimeter_id,
          type: "custom",
          renderComponent: DosimeterInputComponent,
          onComponentInitFunction: (instance) => {
            instance._id="_di"+this.dosimeterInputs.length;
            this.dosimeterInputs.push(instance);

            instance.updateResult.subscribe(data => {
              console.log("---------------", instance, data)
              this.updatedData(data, instance);
            });

            setTimeout(() => {
              if (this.dosimeterInputs[indexToFocus] != null && this.dosimeterInputs[indexToFocus] != undefined)
              {
                this.dosimeterInputs[indexToFocus].focusThis();
              };
            }, 150);
            
        },
          filter: false
        },

      },
    } );
    this.cdr.detectChanges();
  };

  
  printerSelect(event) {
    AdminAllocationComponent.SelectedPrinter=this.selectedPrinter;
    console.log("selected printer is ", event, this.selectedPrinter);
    this.printerService.setPrinter(this.selectedPrinter)
  };

  public async updatedData(data, dosimeterInputInstance) {
    let _di = this.dosimeterInputs.indexOf(dosimeterInputInstance);
    this.allocationTable.grid.dataSet.getRows().forEach((row) => {
    //   if(dosimeterInputInstance.rowData.monitoring_type.position == '' || 
    //      dosimeterInputInstance.rowData.monitoring_type.position == null ||
    //      dosimeterInputInstance.rowData.monitoring_type.position == undefined)
    //      {
    //       if (row.getData()._id == dosimeterInputInstance.rowData._id){
    //         _di = this.allocationTable.grid.dataSet.getRows().indexOf(row);
    //         console.log("DI in if ", _di)
    //       }
    //   }else{
    //     if (row.getData()._id == dosimeterInputInstance.rowData._id && row.getData().monitoring_type.position == dosimeterInputInstance.rowData.monitoring_type.position){
    //       _di = this.allocationTable.grid.dataSet.getRows().indexOf(row);
    //     }
    //   }      
    // });

    
  if (row.getData()._id == dosimeterInputInstance.rowData._id &&
      row.getData().monitoring_type.monitoring_type == dosimeterInputInstance.rowData.monitoring_type.monitoring_type
      && row.getData().monitoring_type.monitoring_frequency == dosimeterInputInstance.rowData.monitoring_type.monitoring_frequency 
      && row.getData().monitoring_type.position == dosimeterInputInstance.rowData.monitoring_type.position
      && row.getData().monitoring_type.organizationId == dosimeterInputInstance.rowData.monitoring_type.organizationId
      && row.getData().monitoring_type.suborganizationId == dosimeterInputInstance.rowData.monitoring_type.suborganizationId
      && row.getData().firstName == dosimeterInputInstance.rowData.firstName 
      && row.getData().lastName == dosimeterInputInstance.rowData.lastName) {
        _di = this.allocationTable.grid.dataSet.getRows().indexOf(row);
  };
      });   
     
    let _row:Row=this.allocationTable.grid.getDataSet().getRows()[_di];
    let rd=_row.getData();
    console.log("RD IS ", rd, "allocable weareres are ", this.allocableWearers)
    let _wearer=this.allocableWearers.filter(el=>el._id==rd._id 
      && el.firstName==rd.firstName && el.lastName==rd.lastName
      && el.organizationId==rd.organizationId 
      && el.suborganizationId==rd.suborganizationId && 
      //  el.monitoring_type.mt.key == rd.monitoring_type.mt.key 
        el.monitoringKey == rd.monitoringKey
      && el.code==rd.code 
      && el.created==rd.created && el.monitoring_type==rd.monitoring_type)[0];
    let _index=this.allocableWearers.indexOf(_wearer);
    console.log("---------------should remove this", _index, _wearer)
    console.log("---------------should remove this ",this.allocationTable.grid.dataSet, _di," row? ", _row, " from ", this.allocableWearers);
    this.allocableWearers.splice(_index, 1);

     DosimeterInputComponent.focusedInstance=this.dosimeterInputs[_di];
   
    //this.showWearers(-99);
    this.showWearers(-999)
  };


  invalidated(type:any):boolean {
    let b=true;
    if (type=="selectedDate" && this.selectedDate!=null) return false;
    try {
    if (type=="monitoringTypesSelect" && this.monitoringTypesSelect.selected!=null) return false;
    } catch(e) {}
    return b;
  }
  isFilterEnabled():Boolean {
    let bool=false;
    try {
    if (this.selectedDate!=null && this.monitoringTypesSelect.selected!=null && this.printerLabelSelect.selected!=null) bool=true;
    } catch(e) {}
    return bool;
  }
  async exportXLSXData() {
    let _t=new OrganizationModel();
    _t.active=true;
    let _f2=await this.data.getGeneric(this.auth.user.token, _t);
    //let _f2 = await this.utils.getFullOrganizations(true);
    let fullorgs=_f2.docs;

    let sortedWearers =await this.allocationTable.source.getFilteredAndSorted(); 
    console.log("Sorted and filtered Arr is", sortedWearers);
  
    console.log(this.selectedDate, this.monitoringTypesSelect.selected, fullorgs, _f2);
    this.utils.exportDataAsExcel(sortedWearers, "AssignExport"+Date.now(), fullorgs)
  };


}


