<nb-card>
    <nb-card-header>{{ls.strings.menu_client_dashboard}}
        <nb-select style="width: 100% !important;" #organizationSelect [(ngModel)]="auth.user.selected_suborganization"  (ngModelChange)="onSuborganizationChange($event)">
        
            <nb-option  *ngFor="let org of suborganizations" [value]="org._id">{{org.denumire}}</nb-option>
          </nb-select>
    </nb-card-header>
    <nb-card-body>
        
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="exposed-wearers">
                        <p><b>{{ls.strings.number_exposed_wearers}}:&nbsp;</b><span>{{organizationExposed}}</span></p>
                        <button class="show_wearers" nbButton status="info" (click)="show_wearers()">{{ls.strings.show_wearers}}</button>
                    </div>
                </div>
            </div>
        
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="exposed-wearers">
                        <p><b>{{ls.strings.number_unreturned_dosimeters}}:&nbsp;</b><span>{{lateDosimeters}}</span></p>
                        <button class="show_wearers" nbButton status="info" (click)="show_unreturned_dosi()">{{ls.strings.show_unreturned_dosi}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class ="row">
         <div class="col-sm-6">
            <div class="form-group">
             <p><b>{{ls.strings.contract_expiry_date}}:&nbsp;</b><span>{{organization.contract_valability}}</span></p>
        </div>
    </div>
</div>
    </nb-card-body>
</nb-card>
