<nb-card>
    <nb-card-header>{{ls.strings.menu_unreturned_dosimeters}}
    </nb-card-header>
    <nb-card-body class="card-body-container">
      <div class="table-container">
        <ng2-smart-table [settings]="settings"  [source]="source">
      </ng2-smart-table>
    </div>
      <br>
      <div class="button-container">
      <button nbButton status="success" (click)="exportXLSXUnreturnedDosimeters();">
        <nb-icon icon="download-outline"></nb-icon>{{ls.strings.export_xslx}}</button>
        <button nbButton hero status="primary" (click)="dismiss()">{{this.ls.strings.close}}</button>
        </div>
    </nb-card-body>
</nb-card>
