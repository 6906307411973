<h1 *ngIf="!adminMode" id="title" class="title">{{ ls.strings.register }}</h1>
<section *ngIf="loaded">
<div class="d-flex justify-content-center">
  <a
    *ngIf="!adminMode"
    style="margin-left: 15px; height: 36px"
    (click)="this.ls.changeLanguage('en')"
    ><img style="height: inherit; cursor: pointer" src="./assets/images/uk.png"
  /></a>
  <a
    *ngIf="!adminMode"
    style="margin-left: 15px; height: 39px"
    (click)="this.ls.changeLanguage('ro')"
    ><img style="height: inherit; cursor: pointer" src="./assets/images/ro.png"
  /></a>
</div>
<nb-alert
  *ngIf="showMessages.error && errors?.length && !submitted"
  outline="danger"
  role="alert"
>
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert
  *ngIf="showMessages.success && messages?.length && !submitted"
  outline="success"
  role="alert"
>
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">
      {{ message }}
    </li>
  </ul>
</nb-alert>

<div class="steppers-container row">
  <div class="col-md-12 col-lg-12 col-xxxl-12">
    <nb-card>
      <nb-card-body>
        <nb-stepper #stepper [(selectedIndex)]="stepperIndex">
          <nb-step
            *ngIf="showEIN"
            [stepControl]="firstForm"
            [label]="ls.strings.first_step"
          >
            <form
              [formGroup]="firstForm"
              (ngSubmit)="onFirstSubmit()"
              class="step-container"
            >
              <div class="container-fluid">

                <div class="row">
                  <div class="col-sm-12 col-md-4"></div>
                  <div class="col-sm-12 col-md-4">
                    <div class="input-group">
                      <label class="label" for="input-cui"
                        >{{ ls.strings.cui
                        }}<span style="color: orange">*</span>:</label
                      >
                      <input
                        nbInput
                        formControlName="cui"
                        id="input-cui"
                        name="denumire"
                        [placeholder]="ls.strings.cui"
                        autofocus
                        fullWidth
                        fieldSize="large"
                        [status]="
                          firstForm.invalid &&
                          (firstForm.dirty || firstForm.touched)
                            ? 'danger'
                            : 'basic'
                        "
                      />
                    </div>
                    <div class="text-center mt-4">
                      <button
                        nbButton
                        hero
                        status="primary"
                        [disabled]="!firstForm.valid"
                        nbStepperNext
                        (click)="onFirstSubmit()"
                      >
                        {{ ls.strings.next }}
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4"></div>
                </div>
              </div>
            </form>
          </nb-step>
          <nb-step [stepControl]="secondForm" [label]="ls.strings.second_step">
            <form
              [formGroup]="secondForm"
              (ngSubmit)="onSecondSubmit()"
              #form="ngForm"
              aria-labelledby="title"
            >
              <div class="row form-control-group">
                <div class="col-md-6 col-lg-6 col-sm-12">
                  <label class="label" for="input-name"
                    >{{ ls.strings.denumire
                    }}<span style="color: orange">*</span>:</label
                  >
                  <input
                    nbInput
                    formControlName="denumire"
                    id="input-denumire"
                    name="denumire"
                    [placeholder]="ls.strings.denumire"
                    autofocus
                    fullWidth
                    fieldSize="large"
                    [status]="
                      secondForm.controls.denumire.invalid &&
                      (secondForm.controls.denumire.dirty ||
                        secondForm.controls.denumire.touched)
                        ? 'danger'
                        : 'basic'
                    "
                  />
                </div>

                <div class="col-md-6 col-lg-6 col-sm-12">
                  <label class="label" for="input-name">{{
                    ls.strings.nrRegCom
                  }}</label>
                  <input
                    nbInput
                    formControlName="nrRegCom"
                    id="input-nrRegCom"
                    name="nrRegCom"
                    [placeholder]="ls.strings.nrRegCom"
                    autofocus
                    fullWidth
                    fieldSize="large"
                    [status]="
                      secondForm.controls.nrRegCom.invalid &&
                      (secondForm.controls.nrRegCom.dirty ||
                        secondForm.controls.nrRegCom.touched)
                        ? 'danger'
                        : 'basic'
                    "
                  />
                </div>
              </div>
              <br>
              <!-- BUDGET CODE & CONTRACT VALIDITY -->
              <div class="row form-control-group">
                <div class="col-md-6 col-lg-6 col-sm-12">
                  <label class="label">{{ls.strings.menu_budget_code}}</label>
                  <nb-select fullWidth #budgetCodeSelect formControlName="budget_code"
                  (selectedChange)="setBudgetCodeChange($event)">
                    <nb-option *ngFor="let budget of budgetCodes" [value]="budget">{{budget.description}}</nb-option>
                  </nb-select>
                </div>

                  <div class="col-sm-2">
                    <div class="form-group">
                      <label for="inputContract" class="label">{{ls.strings.contract_valability}}</label>
                      <input type="date" (change)="onDateChangeEvent($event)" nbInput fullWidth id="inputContract"
                      formControlName="contract_valability" [placeholder]="ls.strings.contract_valability" >
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="Id" class="label">{{ls.strings.organization_code}}</label>
                      <input type="text" (change)="onChangeEvent($event)" nbInput fullWidth id="inputCode"  formControlName="organization_code"
                       [placeholder]="ls.strings.organization_code">
                    </div>
                  </div>
              </div>
              <br>
              <div class="form-control-group">
                <div class="row">
                  <div class="col-md-12 col-lg-12 col-sm-12">
                    <label class="label" for="input-email"
                      >{{ ls.strings.adresa }}:</label
                    >
                    <ngx-address-component
                      [address]="workplace_address"
                    ></ngx-address-component>
                  </div>
                </div>
              </div>

              <div class="form-control-group">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12">
                    <label class="label" for="input-banca"
                      >{{ ls.strings.banca }}:</label
                    >
                    <input
                      nbInput
                      formControlName="banca"
                      id="input-banca"
                      name="banca"
                      [placeholder]="ls.strings.banca"
                      fullWidth
                      fieldSize="large"
                      [status]="
                        secondForm.controls.banca.invalid &&
                        (secondForm.controls.banca.dirty ||
                          secondForm.controls.banca.touched)
                          ? 'danger'
                          : 'basic'
                      "
                    />
                  </div>

                  <div class="col-md-6 col-lg-6 col-sm-12">
                    <label class="label" for="input-iban"
                      >{{ ls.strings.iban }}:</label
                    >
                    <input
                      nbInput
                      formControlName="iban"
                      id="input-iban"
                      name="iban"
                      [placeholder]="ls.strings.iban"
                      autofocus
                      fullWidth
                      fieldSize="large"
                      [status]="
                        secondForm.controls.iban.invalid &&
                        (secondForm.controls.iban.dirty ||
                          secondForm.controls.iban.touched)
                          ? 'danger'
                          : 'basic'
                      "
                    />
                  </div>

                  <div class="col-md-4 col-lg-4 col-sm-12 form-control-group">
                    <label class="label" for="input-name"
                      >{{ ls.strings.email
                      }}<span style="color: orange">*</span>:</label
                    >
                    <input
                      nbInput
                      formControlName="email"
                      id="input-email"
                      name="email"
                      [placeholder]="ls.strings.email"
                      autofocus
                      fullWidth
                      fieldSize="large"
                      [status]="
                        secondForm.controls.email.invalid &&
                        (secondForm.controls.email.dirty ||
                          secondForm.controls.email.touched)
                          ? 'danger'
                          : 'basic'
                      "
                    />
                  </div>
                </div>
              </div>
              <br />

              <div class="d-flex justify-content-between">
                <button nbButton hero nbStepperPrevious status="info">
                  {{ ls.strings.prev }}
                </button>
                <button
                  nbButton
                  hero
                  nbStepperNext
                  status="primary"
                  [disabled]="!secondForm.valid || !disabledNextButton()"
                  (click)="onSecondSubmit()"
                >
                  {{ ls.strings.next }}
                </button>
              </div>
            </form>
          </nb-step>
          <nb-step [stepControl]="thirdForm" [label]="ls.strings.third_step">
            <form
              [formGroup]="thirdForm"
              (ngSubmit)="onThirdSubmit()"
              ngNativeValidate
              #form="ngForm"
              aria-labelledby="title"
              class="step-container"
            >
              <div class="row form-control-group">
                <div class="col-md-12 col-lg-12 col-sm-12">
                  <ngx-representative
                    [representative]="representative"
                  ></ngx-representative>
                </div>

                <div class="col-md-6 col-lg-6 col-sm-12">
                  <label class="label" for="input-name"
                    >{{ ls.strings.adresa_web }}:</label
                  >
                  <input
                    nbInput
                    formControlName="adresa_web"
                    id="input-adresa_web"
                    name="adresa_web"
                    [placeholder]="ls.strings.adresa_web"
                    autofocus
                    fullWidth
                    fieldSize="large"
                  />
                </div>
              </div>

              <br />
              <div class="d-flex justify-content-between">
                <button nbButton hero status="info" nbStepperPrevious>
                  {{ ls.strings.prev }}
                </button>
                <button
                  nbButton
                  hero
                  status="primary"
                  nbStepperNext
                  (click)="onThirdSubmit()"
                  [disabled]="!disabledConfirm()"
                >
                  {{ ls.strings.confirm }}
                </button>
              </div>
            </form>
          </nb-step>
          <nb-step [stepControl]="thirdForm" [hidden]="true" label="Third step">
            <div class="step-container">
              <nb-card-body>
                <ng2-smart-table [settings]="settings" [source]="source">
                </ng2-smart-table>
              </nb-card-body>
              <nb-card style="text-align: center">
                <div class="d-flex justify-content-between">
                  <button
                    nbButton
                    hero
                    status="warning"
                    (click)="stepper.selectedIndex = 0"
                  >
                    {{ ls.strings.start_over }}
                  </button>
                  <button nbButton hero status="success" (click)="register()">
                    {{ ls.strings.register }}
                  </button>
                </div>
              </nb-card>
            </div>
          </nb-step>
        </nb-stepper>
      </nb-card-body>
    </nb-card>
  </div>
</div>
</section>
<section *ngIf="!adminMode" class="another-action" aria-label="Sign in">
  {{ ls.strings.already_have_account }}
  <a class="text-link" routerLink="../login">{{ ls.strings.login }}</a>
</section>
