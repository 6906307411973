import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthService, NbLoginComponent, NB_AUTH_OPTIONS } from '@nebular/auth';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';

@Component({
  selector: 'ngx-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss']
})
export class AuthorizeComponent extends NbLoginComponent implements AfterViewInit {
  private routeSub: Subscription;
  public id :any;
  public message:string='';

  constructor(private fb: FormBuilder, 
    public auth:AuthService,
    private dataService:DataService, 
     service: NbAuthService, private route: ActivatedRoute,
     @Inject(NB_AUTH_OPTIONS) options:{},
      cd: ChangeDetectorRef, router: Router,public ls:LanguageService){
     super(service, options, cd, router);
   }
  ngAfterViewInit(): void {
    this.accessLink();
  }

  async accessLink(){
  
    this.routeSub = this.route.params.subscribe(params => {
      this.id=params['id'] 
      
       //log the value of id
     });
     console.log(this.id)
     let resp= await this.dataService.postAccessLink(this.id)
     this.message=resp['message'];
     console.log(resp)
  }
  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}
