<div class="steppers-container row">
  <div class="col-md-12 col-lg-12 col-xxxl-12">
    <nb-card>
      <nb-card-header>{{ls.strings[title_key]}}</nb-card-header>
      <nb-card-body>
        <nb-stepper (click)="stepChangeHandler($event)" #stepper orientation="horizontal" [linear]='false'>

          <nb-step [stepControl]="firstForm" [label]="ls.strings.first_step">

            <div id="fistStepper">

              <nb-card id="allocation_lists">
                <nb-card-header>{{ls.strings.monitored_persons}}</nb-card-header>
                <nb-card-body>
                  <ng2-smart-table #allocationTable2 [settings]="list_settings" [source]="lists_source"
                    (userRowSelect)="viewMonitoredPersons($event)" ng-reflect-is-all-selected='true'>
                  </ng2-smart-table>
                </nb-card-body>
              </nb-card>
            </div>
            <!-- <div class="input-group">
                  <input type="text" placeholder="Enter your name" class="form-control" formControlName="firstCtrl"
                         [ngClass]="{'form-control-danger': firstForm.invalid && (firstForm.dirty || firstForm.touched)}">
                </div> -->
            <button id="nextFirst" nbButton (click)="onFirstSubmit()">{{ls.strings.new_list}}</button>
          </nb-step>




          <nb-step [stepControl]="secondForm" [label]="ls.strings.second_step">
            <nb-card>
              <nb-card-header>
                <!-- <div class="col-md-12 col-lg-12 col-xxxl-12"> -->
                {{ls.strings.organization_users}}
                <nb-select style="width: 100% !important;" #organizationSelect [selected]="auth.user.preffered_organization"
                  (selectedChange)="organizationChange($event)">
                  <nb-option [value]="currentOrganization?._id">{{currentOrganization?.denumire}}</nb-option>
                  <nb-option *ngFor="let org of suborganizations" [value]="org._id">{{org.denumire}}</nb-option>
                </nb-select>
              </nb-card-header>

              <nb-card-body style="text-align: center;">
                <ng2-smart-table id="allocation" #allocationTable  [settings]="settings"
                  [source]="source" (create)="onCreate($event)" (rowSelect)="updateSelection()"
                  (userRowSelect)="onRowSelect($event)" ng-reflect-is-all-selected='true'>
                  (edit)="onEdit($event)"
                  (delete)="onDelete($event)">
                </ng2-smart-table>
              </nb-card-body>

              <nb-card-footer style="text-align: center;">
                <div class="d-flex justify-content-between">
                  <button nbButton nbStepperPrevious>{{ls.strings.prev}}</button>
                  <button nbButton *ngIf="missingMonitoringType" (click)="onSecondSubmit()">{{ls.strings.next}}</button>
                  <button nbButton *ngIf="!missingMonitoringType" nbStepperNext
                    (click)="onSecondSubmit()">{{ls.strings.next}}</button>
                </div>
              </nb-card-footer>
              <!-- </div> -->
            </nb-card>
          </nb-step>



          <nb-step [stepControl]="thirdForm" [label]="ls.strings.third_step">
            <div id="thirdStepper">
              <nb-card id="date_picker">
                <nb-card-header>{{ls.strings.select_period}}</nb-card-header>

                <nb-card-body>
                  <input nbInput placeholder="Pick Date" [nbDatepicker]="startpicker"
                    [(ngModel)]="allocation_start_date">
                  <nb-datepicker #startpicker [max]="allocation_end_date" (dateChange)="changeDate($event)">
                  </nb-datepicker>
                  <!-- <input  nbInput placeholder="Pick Date" [nbDatepicker]="endpicker"
                  [(ngModel)]="allocation_end_date">
                <nb-datepicker disabled #endpicker [min]="allocation_start_date" (dateChange)="changeDate($event)">
                </nb-datepicker> -->

                  <div class="form-group">
                    <p class="label">{{ls.strings.menu_monitoring_frequency}}</p>
                    <nb-select fullWidth [(ngModel)]="frequency" (selectedChange)="selectionFrequencyChange($event)">
                      <nb-option *ngFor="let freq of monitoringFrequency" [value]="freq.key">{{freq.value}}</nb-option>
                    </nb-select>
                  </div>
                </nb-card-body>

              </nb-card>
              <ng2-smart-table id="allocation" [settings]="settings2" [source]="personsSource"
                (create)="onCreate($event)" (userRowSelect)="onRowSelect($event)" (delete)="onDelete($event)">
              </ng2-smart-table>
            </div>
            <div class="d-flex justify-content-between">
              <button nbButton nbStepperPrevious>{{ls.strings.prev}}</button>
              <button nbButton (click)="openConfirmationDialog()">{{ls.strings.confirm}}</button>
            </div>
          </nb-step>
          <nb-step [stepControl]="thirdForm" [hidden]="true" [label]="ls.strings.third_step">
            <div class="step-container">
              <h3>{{this.ls.strings.wizard_completed}}</h3>
              <button nbButton (click)="stepper.reset()">{{ls.strings.try_again}}</button>
            </div>
          </nb-step>
        </nb-stepper>
      </nb-card-body>
    </nb-card>
  </div>
</div>