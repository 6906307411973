import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NbAuthComponent } from '@nebular/auth';

import { NgxLoginComponent } from '../components/login/login.component'; // <---
import { RegisterComponent } from '../components/register/register.component';
import { AuthorizeComponent } from '../components/authorize/authorize.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
export const routes: Routes = [

  {
    path: '',
    component: NbAuthComponent,
    children: [
      // {
      //   path: 'authorize/access/:id',
      //   component: AuthorizeComponent, // <---
      // },
      {
        path: 'login',
        component: NgxLoginComponent, // <---
      },
      {
        path: 'register',
        component: RegisterComponent, // <---
      },
      {
        path: 'request-password',
        component: ForgotPasswordComponent
      }
    ],
    
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxAuthRoutingModule {
}