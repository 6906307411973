import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { DoseRecordModel } from '../../../@core/models/doseRecord';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import { BaseComponent } from '../../client/base/base-component';

@Component({
  selector: 'ngx-assign-compare',
  templateUrl: './assign-compare.component.html',
  styleUrls: ['./assign-compare.component.scss']
})
export class AssignCompareComponent extends BaseComponent implements OnInit {

  @Input() currentAllocation:DoseRecordModel;
  @Input() otherAllocation:DoseRecordModel;
  @Input() newDosimeterId:String;
  
 public existingAllocation:DoseRecordModel;
 constructor(protected ref: NbDialogRef<AssignCompareComponent>,protected authService:AuthService, protected dataService:DataService, 
  public data:DataService, public ls:LanguageService, public auth:AuthService, public toastrService:NbToastrService, protected changeDetectorRef:ChangeDetectorRef) { 

  super(data, auth, toastrService, ls, changeDetectorRef);
}

protected reinit(ob) {

};


async swapDosimetersIds(event) {
  let obj1=this.currentAllocation;
  let obj2=this.otherAllocation;
  let temp; let stemp;
  stemp=this.currentAllocation.status;
  this.currentAllocation.status=this.otherAllocation.status;
  this.otherAllocation.status=stemp;
  temp= {dosimeterIdPerson1:this.otherAllocation.dosimeterId, dosimeterIdPerson2:this.currentAllocation.dosimeterId};
  obj1.dosimeterId=temp.dosimeterIdPerson1;
  obj2.dosimeterId=temp.dosimeterIdPerson2;
};

async updateValues() {
 console.log(this.currentAllocation, " vs ", this.otherAllocation)
   let resp=await this.dataService.updateDoseRecord(this.auth.user.token, this.currentAllocation);
   let resp2=await this.dataService.updateDoseRecord(this.auth.user.token, this.otherAllocation);
   if(resp.status==200){
    this.showToast('success', this.ls.strings.success, '');
    this.dismissOK()
}
  else this.showToast('danger', this.ls.strings.danger, '');
};

  dismissOK() {
    this.ref.close(true)
  }
  dismiss() {
    this.ref.close();
  };

}
