<nb-card>
    <div *ngFor="let _section of sectionChildren">
        <div *ngIf="_section.mimetype=='image/png'">
            <nb-card-header>
                {{_section.title}}
            </nb-card-header>
            <nb-card-body class="img">
                <img src="{{_section.nodeUrl}}" />
            </nb-card-body>
        </div>
    </div>
    
    <div *ngFor="let _section of sectionChildren">
        <nb-card *ngIf="_section.mimetype=='text/html'">
            <nb-card-header>
                {{_section.title}}
            </nb-card-header>
            <nb-card-body>
                <div [innerHTML]="_section.nodeContent"></div>
            </nb-card-body>
        </nb-card>
    </div>
    </nb-card>
