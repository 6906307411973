import { BaseNomenclature } from "./baseNomenclature.model";
import { ImportFields } from "./importFields";
import { RadiationType } from "./radiationType";

export class DosimeterMeasurement extends BaseNomenclature {
    active:Boolean;
    import_fields:Array<ImportFields>;
    radiation_types:Array<RadiationType>;
    measurement_unit: String;
    coefficient: Number;
    min_value: Number;
    max_value: Number;
    min_cutoff: Number;
    daily_background: Number;
    title: String;
    cumulated_title: String;
    report_method_descriptor: String;
    constructor(){
        super();
        this.active=true
        this.import_fields=[];
        this.radiation_types=[];
        this.measurement_unit="";
        this.coefficient=1;
        this.min_value=0;
        this.max_value=0;
        this.min_cutoff=0;
        this.daily_background=0;
        this.report_method_descriptor="";
        this.collection_name="dosimeter_measurements";
    }
}