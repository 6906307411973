import { BaseModel } from "./base.model";
import { BaseNomenclature } from "./baseNomenclature.model";

export class NotificationModel extends BaseModel {
    public title: String;
    public description: String;
    public category: String;
    public body: String;
    public creatorName: String;
    public recipientId: String;
    public readDate: Date;
    public new: Boolean
    public source: Object;
    constructor() {
        super();
        this.collection_name="notification"
    }

    protected generateInit() {
        this.title='';
        this.description='';
        this.category='';
        this.body='';
        this.createdBy='';
        this.creatorName='';
        this.modifiedBy='';
        this.recipientId='';
        this.readDate=null;
        this.new=true;
        this.source={};
    }

}