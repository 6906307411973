import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';

@Component({
  selector: 'ngx-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  nextDisabled:boolean = false;
  prevDisabled:boolean = false;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  page=0;
  totalPages=0;
  pages=[];

  @Input() source: any;
  @Input() perpage: any;
  @Input() collection_name: any;
  @Input() serviceMethod;
  @Input() query;

  constructor(public ls:LanguageService, private dataService: DataService, private auth:AuthService,
    private changeDetector : ChangeDetectorRef, public toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.init();
  }

  init(){
    console.log('serv', this.serviceMethod, this.query)
    this.setPage(0)

    if(this.query==undefined){
      this.query={};
    }
  }

  ngOnChanges(){
    this.init();
    console.log("pagination component has changes....")
    // if(this.perpage!==10) this.showToast('success', 'Succes!', '')
  }

  async loadQuery() {
    console.log("pagination component query is ", this.query)
    let obj = { query: { collection_name:this.collection_name, query:this.query, limit:this.perpage, skip:this.perpage*this.page, sort: {"id": "asc"} }}
    let r = await this.dataService[this.serviceMethod](this.auth.user.token, obj)
    
    if(r.size>0){
      this.totalPages=Math.ceil(r.size/this.perpage);
      console.log('total', r.size, this.totalPages)
      this.source.load(r.docs)

      this.pages=[];
      for(let i=0; i<this.totalPages; i++){
        this.pages.push(i);
      }
  
      if(this.page>this.totalPages) this.page=this.totalPages
      
      this.pages = this.getPages(this.page, this.totalPages);
    }
    else{
      this.totalPages=1;
      this.page=0;
      this.pages = this.getPages(this.page, this.totalPages);
    }
  }

  async setPage(number?) {
    if (number!=null) this.page=number
    this.nextDisabled=false;this.prevDisabled=false;
    if (this.page>=this.totalPages) { this.page=this.totalPages-1; } 
	  if (this.page<0) { this.page=0 }
    if(this.page==0) {this.prevDisabled=true;} else { this.prevDisabled=false; }
    if(this.page==this.totalPages-1) {this.nextDisabled=true;} else { this.nextDisabled=false; }

    console.log('current', this.page, this.totalPages)

	  this.loadQuery();
  } 

  private getPages(current: number, total: number): number[] {
    if(total==0){
      return [0]
    }
    if(total<5) {
      let _a=[];
      for (var i=0;i<total;i++) {
        _a.push(i);
      }
      return _a;
  } 
    if (current >= 3) {
      if (current > total - 3) {
        return [total-5,total - 4, total - 3, total - 2, total -1]
      } else {
        return [current-2, current - 1, current, current + 1, current + 2]
      }
    }
   //else
    return [0,1,2,3,4];
  }


  prev(){
    this.setPage(this.page-1);
  }

  next(){
    this.setPage(this.page+1);
  }

  first(){
    this.setPage(0)
  }

  last(){
    this.setPage(this.totalPages-1)
  }

  public onGoTo(p: number): void {
    this.setPage(p);
  }

  public showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 3000,
      hasIcon: true,
      position: this.position,
      preventDuplicates: true,
    };
    const titleContent = title ? ` ${title}` : "";
  
    this.toastrService.show(body, `${titleContent}`, config);
    console.log(title);
  }
}
