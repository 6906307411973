import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NbComponentStatus, NbDialogRef, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { AuthService } from '../../../../@core/services/auth/auth.service';
import { DataService } from '../../../../@core/services/data/data.service';
import { LanguageService } from '../../../../@core/services/language/language.service';

@Component({
  selector: 'ngx-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, AfterViewInit {
  @Input() body: string;
  @Input() confirm: string;
  @Input() cancel: string;
  @ViewChild('textBody') textBody:ElementRef;
  constructor(protected ref: NbDialogRef<ConfirmationComponent>) { }
  
  ngAfterViewInit(): void {
    console.log("confirmation component init ", this.body);
    this.textBody.nativeElement.innerHTML=this.body; 
  }

  ngOnInit(): void {
    
  }

  async accept(){
    this.ref.close('confirm');
    
  }

  dismiss(){
    this.ref.close();
  }
}
