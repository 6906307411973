<div class="address_box">

    <span>{{_title}}</span>

 <div class="row">
  <div class="col-sm-6">
    <div class="form-group">
      <label class="label">{{ls.strings.company_address_name}}<span style="color:orange">*</span></label>
      <input required type="text" nbInput fullWidth id="inputFloor" [(ngModel)]="address.name" 
      [placeholder]="ls.strings.company_address_name">

    </div>
  </div>
 </div>

<div *ngIf="!addressLines">

<div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="label">{{ls.strings.country}}</label>
        <nb-select [disabled]="_disabled"  #countrySelector  fullWidth 
        [(ngModel)]="address.country" 
        [placeholder]="ls.strings.country" 
        (selectedChange)="setCountry($event)">
        <nb-option *ngFor="let country of countries" [value]="country.value">{{country.value}}</nb-option>
      </nb-select>
      </div>
    </div>

    <div class="col-sm-6">
        <div class="form-group">
          <label for="inputCounty" class="label">{{ls.strings.county}}</label>
          <nb-select [disabled]="_disabled"  #countySelector fullWidth  [(ngModel)]="address.county" [placeholder]="ls.strings.county">
            <nb-option  *ngFor="let county of selectedCountry.counties" [(value)]="county.value">{{county.value}}</nb-option>
          </nb-select>
        </div>
      </div>
      
     </div>

     <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="inputCity" class="label">{{ls.strings.city}}</label>
            <input [disabled]="_disabled" type="text" nbInput fullWidth id="inputCity" [(ngModel)]="address.city" 
            [placeholder]="ls.strings.city">
          </div>
        </div>
    
        <div class="col-sm-6">
            <div class="form-group">
              <label for="inputStreet" class="label">{{ls.strings.street}}</label>
              <input [disabled]="_disabled" type="text" nbInput fullWidth id="inputStreet" [(ngModel)]="address.street" 
              [placeholder]="ls.strings.street">
            </div>
          </div>
         </div>

         <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="inputSteetName" class="label">{{ls.strings.street_number}}</label>
                <input [disabled]="_disabled" type="text" nbInput fullWidth id="inputStreetName" [(ngModel)]="address.streetNumber" 
                [placeholder]="ls.strings.street_number">
              </div>
            </div>
        
            <div class="col-sm-6">
                <div class="form-group">
                  <label for="inputBuildingName" class="label">{{ls.strings.building_name}}</label>
                  <input [disabled]="_disabled" type="text" nbInput fullWidth id="inputBuildingName" [(ngModel)]="address.buildingName" 
                  [placeholder]="ls.strings.building_name">
                </div>
              </div>
             </div>

             <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="inputFloor" class="label">{{ls.strings.floor}}</label>
                    <input [disabled]="_disabled" type="text" nbInput fullWidth id="inputFloor" [(ngModel)]="address.floor" 
                    [placeholder]="ls.strings.floor">
                  </div>
                </div>
            
                <div class="col-sm-6">
                    <div class="form-group">
                      <label for="inputApartment" class="label">{{ls.strings.apartment}}</label>
                      <input [disabled]="_disabled" type="text" nbInput fullWidth id="inputApartment" [(ngModel)]="address.apartment" 
                      [placeholder]="ls.strings.apartment">
                    </div>
                  </div>
               </div>


               </div>

               <div *ngIf="addressLines">

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="addressline1" class="label">{{ls.strings.addressline1}}</label>
                      <input [disabled]="_disabled" type="text" nbInput fullWidth id="addressline1" [(ngModel)]="address.line1" 
                      [placeholder]="ls.strings.addressline1">
                    </div>
                  </div>
              
                  <div class="col-sm-6">
                      <div class="form-group">
                        <label for="addressline2" class="label">{{ls.strings.addressline2}}</label>
                        <input [disabled]="_disabled" type="text" nbInput fullWidth id="addressline2" [(ngModel)]="address.line2" 
                        [placeholder]="ls.strings.addressline2">
                      </div>
                    </div>
                   </div>

                   <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="addressline3" class="label">{{ls.strings.addressline3}}</label>
                        <input [disabled]="_disabled" type="text" nbInput fullWidth id="addressline3" [(ngModel)]="address.line3" 
                        [placeholder]="ls.strings.addressline3">
                      </div>
                    </div>
                
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="addressline4" class="label">{{ls.strings.addressline4}}</label>
                          <input [disabled]="_disabled" type="text" nbInput fullWidth id="addressline4" [(ngModel)]="address.line4" 
                          [placeholder]="ls.strings.addressline4">
                        </div>
                      </div>
                     </div>

               </div>

               <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="inputZipCode" class="label">{{ls.strings.zip__code}}</label>
                    <input [disabled]="_disabled" type="text" nbInput fullWidth id="inputZipCode" [(ngModel)]="address.zipCode" 
                    [placeholder]="ls.strings.zip__code">
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="inputShipping" class="label">{{ls.strings.menu_shipping_type}}</label>
                    <nb-select [disabled]="_disabled"  #selectedShippingType fullWidth  [(ngModel)]="address.shipping_type" [placeholder]="ls.strings.menu_shipping_type">
                      <nb-option  *ngFor="let ship of shipping_type" [value]="ship.key">{{ship.key}}</nb-option>
                    </nb-select>
                  </div>
                </div>
              </div>
            
              <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                      <label for="inputDetails" class="label">{{ls.strings.details}}</label>
                      <input [disabled]="_disabled" type="text" nbInput fullWidth id="inputDetails" [(ngModel)]="address.details" 
                      [placeholder]="ls.strings.details">
                    </div>
                  </div>
                 </div>
</div>