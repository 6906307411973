import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { DataService } from '../data/data.service';
import { AuthService } from '../auth/auth.service';
import { DatePipe } from '@angular/common';
import { LanguageService } from '../language/language.service';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { AlfrescoService } from '../alfresco/alfresco.service';
import { UtilsService } from '../utils/utils.service';
import { Address } from '../../models/address.model';
import { ImportFields } from '../../models/importFields';
import { DosimeterMeasurement } from '../../models/dosimeterMeasurement.model';
import { DoseReportModel } from '../../models/doseReport.model';
import { NotificationModel } from '../../models/notification.model';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    batchesTable: Ng2SmartTableComponent;
    batchesFullColumns: any = {}
    batchesSettings: any;
    cumulativeDoses:any[];
    importFields:ImportFields[];
    dosimeterMeasurements:DosimeterMeasurement[];
    monitoringKey:any;
    valued:any[];
    unvalued:any[];
    public static multiple:any = { index: 0, total: 0};
    private sortField:any;
    private th_style = "border-top:solid #7295D2 1.0pt;  border-left:none;border-bottom:solid #7295D2 1.0pt;border-right:none;  background:#4472C4;padding:0in 5.4pt 0in 5.4pt;height:17.0pt;  color:  white;";
    private tr_style_1 = "font-size: 10px; background-color: #D0DBF0;";
    private tr_style_0 = "font-size: 10px; background-color: #white;";
    private reportType:String="";
    private allowed = ["dosimeterId", "monitoring_type", "fullName", "code", "profession", "position", "wearPeriod"];
    constructor(private data: DataService, private auth: AuthService,
        private alfrescoService:AlfrescoService,
        private utils:UtilsService,
        private ls: LanguageService) {
        console.log("------------------REPORTS SERVICE-------------------------")
        this.batchesTable = new Ng2SmartTableComponent();
        this.batchesSettings  = Object.assign({},  {
            columns: this.batchesFullColumns
          } );
        this.batchesTable.settings=this.batchesSettings;
        this.initializeRequirements();
    }
    async initializeRequirements() {
      ReportService.multiple = { index: 0, total: 1};
      let ob=await this.utils.getReportColorScheme();
      let _if=new ImportFields();
      let _dm=new DosimeterMeasurement();
      let _fdocs=await this.data.getGeneric(this.auth.user.token, _if);
      let _dmdocs=await this.data.getGeneric(this.auth.user.token, _dm);
      this.importFields=_fdocs.docs;
      this.dosimeterMeasurements=_dmdocs.docs;
      this.th_style=ob.th_style;
      this.tr_style_0=ob.tr_style_0;
      this.tr_style_1=ob.tr_style_1;
      console.log("Report color scheme is ", ob)
      console.log("Report dosimeter measurements: ", this.dosimeterMeasurements)
      console.log("Report scheme initialized", this.th_style, this.tr_style_0, this.tr_style_1);
    }

    private allowedDoseFields(str) {
      console.log("--------", str, this.dosimeterMeasurements)
  
      let bool=false;
      //if (str.indexOf("dose_",0)==-1) return true;
      str=str.split("dose_").join("");
      str=str.split(" ").join("_").split(".").join("_").split("_Calc__Dose").join("");
      let mtype=this.monitoringKey;
      
      let fields=this.dosimeterMeasurements.filter(el=>el.key==mtype)[0].import_fields;
      console.log("fields ", str, fields)
      for (var k in fields) {
        let f=String(fields[k]);
        f=f.split(" ").join("_").split(".").join("_").split("_Calc__Dose").join("");
        let _t=false; if (f==str || "∑"+f==str) { _t=true }
        console.log("allowedDoseFields ------", str, " vs ", f, " test ", _t)
         if (_t==true ) return true;
      }
      
      return bool;
    }
    getTableHead(arr, unvalued?) {
        console.log("------------", this.batchesFullColumns);

        let str = "";
        str = str + this.generateTableRow(arr[0], true, unvalued);
        
        return str;
    }

    generateTableRow(obj, head?, unvalued?) {
        let str = "<tr>";
        for (var k in this.allowed) {
            if (this.allowed[k]=="dose") { 
              if (unvalued==true) continue;

              let _val=this.allowed[k];
              if (obj[_val])
              for (var j in obj[_val])  {
                var fld=j.split(" Calc. Dose").join("");
                var fld2='∑'+fld.split("_Calc__Dose").join("");
                if (fld.indexOf('_Calc__Dose',0)!=-1) {
                  fld='∑'+fld.split("_Calc__Dose").join("");
                }
                
                //str = str + "<th style='" + this.th_style + "'> " + fld + " </th>";
                //str = str + "<th style='" + this.th_style + "'> " + fld2 + " </th>";   
                 let _t=this.allowedDoseFields(fld);
                console.log("-----------------DOSE ", j, obj[_val], fld, "allowed?"+_t)
                if (_t==false) { continue;}
                str = str + "<th style='" + this.th_style + "'> " + this.getFieldLabel(j,"title", obj) + " </th>";
                str = str + "<th style='" + this.th_style + "'> " + this.getFieldLabel(j,"cumulated_title", obj) + " </th>";   
              }



            } else {
            str = str + "<th style='" + this.th_style + "'>" + this.ls.strings[this.allowed[k]] + "</th>";
            }
        }
        str = str + "</tr>";
        return str;
    }
    getTableBody(arr, unvalued?) {
        let rows=arr
        let str="";
        let k=0;
        for (var x=0;x<1;x++) {
          for (var i=0;i<rows.length;i++) {
            if (rows[i].active) {
            let m=k%2;
            k++;
            str=str+this.getTableRow(rows[i],m, k, unvalued);
            
            }
          }
        }
        return str;
    }
    getTableRow(cells,m=0, _rowIndex, unvalued?) {
        //console.log("get table row for ", cells)
        let st=this.tr_style_0;
        if (m==1) { st=this.tr_style_1;}
        let str="<tr style='"+st+"'>";
        str="<tr>";
        for (var i=0;i<this.allowed.length;i++) {
          let cel=this.allowed[i];
          let isClassified=cells['classified']
          if (cel=='nrCrt') { cells[cel] = _rowIndex}
          let val=cells[cel];
          //console.log("-------------------", val, cel, isClassified)
          
          if (cells[cel]!=null) {
            if (cells[cel].value!=undefined) { val=cells[cel].value} else { 
              val=cells[cel]
              //console.log("------------------------------------in value override ", cel, val, String(cells[cel]))
              if (String(cells[cel])=='[object Object]' && cel!='dose') { val=""}
            }
          }  else { val=" "}
          if (val=="") { val=""}
          if (cel=='dose') { // || cel == '_dose') {
            
            if (unvalued==true) continue;
            let val2=cells[cel];
            
            for (var k in val) {
              //console.log("-----------------------------------getTableRow ", k, val, val[k])
              let _t=this.allowedDoseFields(k);
              if (_t==false) continue;
              //console.log("CONCATENATED IS-----------------------------------------",cells['personId'],k, cel, val[k], val2[k],val)
              let cumulativ_dose=this.utils.getCumulative(cells['personId'], k, this.cumulativeDoses, val[k], cells);
              //console.log("CONCATENATED IS-----------------------------------------",cells['personId'],k, cel, val[k], val2[k],cumulativ_dose, val)
              str=str+"<td style='" + st + "'> "+this.utils.roundUpDose(val[k])+" </td>";
              str=str+"<td style='" + st + "'> "+this.utils.roundUpDose(cumulativ_dose.value)+" </td>";
            }


          } else {
            str=str+"<td style='" + st + "'>"+val+"</td>";
          }
        }
        str=str+"</tr>"
        return str;
      }

      private getMeasurementDisclaimer(type) {
        let val;
        let m=this.dosimeterMeasurements.filter(el=>el.key==type)[0];
        if (m!=undefined) { val=m.report_method_descriptor } else { val="-" }
        console.log("getMeasurementDisclaimer is ", type, m, val)
        return val;
      }

      private getFieldLabel(fieldname, title, obj) {
        let val;
        let _f;
        
        _f=this.dosimeterMeasurements.filter(el => el.key==obj.monitoringKey)[0];
        console.log("--------------getFieldLabel ",title, _f,  this.importFields,this.dosimeterMeasurements, obj)
        if (_f!=undefined) {
          if (_f!=undefined) { val=_f[title]} else { val=fieldname }
        }
        if (!_f || val==undefined) {
        _f=this.importFields.filter(el=>el.value==fieldname)[0];
          if (_f!=undefined) { val=_f[title]} else { val=fieldname }
        }
        console.log("--------------get fieldLabel  ", fieldname, title, " found value is ", val)
        return val;
      }

      private concatenateFields(arr) {
        
        let dose_fields=[];
        if (this.reportType=="DoseReports") {
        dose_fields=arr.filter(el=>el.dose!=undefined)[0].dose;
        } 
        
        this.valued=[];
        this.unvalued=[];
        for (var j in arr) {
          //console.log(this.reportType, "concatenate trying to sort", j, arr[j].dose)
          if (arr[j].dose==undefined || arr[j].dose==null || arr[j].dose=="-") {
            this.unvalued.push(arr[j]) 
          } else { 
            //if (arr[j].validDose==true)
            this.valued.push(arr[j])
          }
        }
        let ar=[]
        //console.log("concatenate arrs ", this.valued, this.unvalued)
        this.valued.forEach(el=> { ar.push(el)})
        this.unvalued.forEach(el=> { ar.push(el)});
        
        if (this.reportType=="DoseReports") {
          try{ 
          dose_fields=this.valued.filter(el=>el.dose!=undefined)[0].dose;
          } catch (e) { dose_fields=null }
          }
          //console.log("---------------concatenate ", this.reportType, dose_fields) 
        for (var i in ar) {
          let pId=ar[i].personId;
          ar[i].wearPeriod=this.utils.getFormattedDate(ar[i].wearStartDate)+"-"+this.utils.getFormattedDate(ar[i].wearEndDate);
          ar[i].allocationPeriod=this.utils.getFormattedPeriod(ar[i].allocStart, ar[i].allocEnd);
          ar[i].fullName=ar[i].lastName+" "+ar[i].firstName;
          
          if (ar[i].comment==undefined || ar[i].comment==null) { 
            ar[i].comment="-"; 
        };

        if (this.reportType=="DoseReports")
        {
            if (ar[i].dose==undefined || ar[i].dose==null || ar[i].dose=="-") { 
              ar[i].dose={};
              for (var x in dose_fields) {
                let fl=x.split(" Calc. Dose").join("");
                //console.log("overwritting dose value for ",i, x, fl)
                ar[i]["dose_"+fl]="-";//ar[i].dose[k];
                ar[i]["dose_∑"+fl+""]="-";
                ar[i].dose[fl]="-";
              }
              //console.log("overwriting dose value for ", i, ar[i].dose)
            }
            else {
              var _dose={};
              for (var k in ar[i].dose) {
                let d=this.utils.getCumulative(pId, k, this.cumulativeDoses, ar[i].dose[k], ar[i]);
                let fld=k.split(" Calc. Dose").join("");
                //console.log("normal dose value for ------------------ LABEL ", k, pId, d);
                ar[i]["dose_"+fld]=ar[i].dose[k];
                ar[i]["dose_∑"+fld+""]=d.value;
                //_dose["dose_"+fld]=ar[i].dose[k];
                //_dose["dose_∑"+fld+""]=d.value;
              }
              //console.log("normal dose value for ", i, ar[i].dose)
              //ar[i].dose=_dose;
            }
        }

        if (ar[i].classified==true) { ar[i].classified="&#x2713;"}
        if (ar[i].cumulative==undefined || ar[i].cumulative==null) { ar[i].cumulative="xx"}
        }
        
        console.log("--------------CONCATENATED IS-----------------------------", ar)
        return ar;
       }


    async exportOne(batchData:any[], img_data, _columns, _templateName?, _savePath?, _cumulativeDoses?, _register?, _old_report_number?, _templateFile?):Promise<any>{
      
      //_register=false;
      this.allowed=[];
      let columns=[];
      let _template; let savePath="DeliveryNotes";
      let _old_report_override="";
      if (_savePath) savePath=_savePath;
      this.reportType=savePath;
      if (_cumulativeDoses) { this.cumulativeDoses=_cumulativeDoses }
      this.sortField="";
        for (var k in _columns) {
          let _col=_columns[k];
          if (_col.indexOf("*",0)!=-1) {
            _col=_col.split("*").join("");
            this.sortField=_col;
          }
          columns.push(_col);
          this.allowed.push(_col);
        }
        
        this.batchesFullColumns=columns;
        this.monitoringKey=batchData[0].monitoringKey;
        batchData=this.concatenateFields(batchData);
        console.log("------------------------" , _columns,"bbbb", columns,"yyy", this.allowed,"xxx", this.sortField,"-----------------------")
        if (this.sortField!="") {
          batchData=this.utils.sortByProperty(batchData, this.sortField, "ASC")
        }
        this.batchesTable.source=batchData;
        console.log("Batch data is ", batchData)
        console.log("export ONE", this.allowed, this.batchesFullColumns)
        //prima data trebuie generat fisierul
        
        

        let dataObj:any={};
        let header=batchData[0];
        this.monitoringKey=header.monitoringKey;
        //header=batchData.filter(el=>el.validDose==true)[0];
        if (_templateFile) {
          _template = _templateFile;
        } else {
          if (_templateName) {
            _template=await this.alfrescoService.getDocTemplate(header.monitoringKey+"_"+_templateName, this.auth.user.token);
          } else {
          _template=await this.alfrescoService.getDocTemplate("delivery_note_template", this.auth.user.token);
          }
        }
        let register_dose_report;
        let header_valid_by=batchData.filter(el=>el.validBy!=null && el.validBy!='')[0]
        let header_return_by=batchData.filter(el=>el.returnBy!=null && el.returnBy!='')[0]

        //header.
        console.log("REPORTSERVICE header", header_valid_by, header_return_by, header)
        let _org=await this.data.getOrganizationDetails(header.organizationId,this.auth.user.token);
        let _suborg=await this.data.getOrganizationDetails(header.suborganizationId,this.auth.user.token);
        let _dept = _suborg.docs[0];
        let _addy = new Address(this.utils);
        let _period = this.utils.getFormattedPeriod(header.allocStart, header.allocEnd, 'LLL yyyy')
        try {
        _addy.fromJSON(_dept.shipping_address);
        } catch(e) {}
        //dataObj._organization=_org.docs[0];
        //dataObj._suborganization=_suborg.docs[0];
        let cfields=this.utils.flattenObject(_org.docs[0], "_client")
        let dfields=this.utils.flattenObject(_suborg.docs[0], "_department")
        for(var c in cfields) { dataObj[c]=cfields[c]; }
        for(var d in dfields) { dataObj[d]=dfields[d]; }
        console.log("REPORTSERVICE header suborg ", _suborg)
        dataObj.date=new Date();
        dataObj.timestamp=String(Date.now());
        dataObj.serial_code=img_data;
        dataObj.noderef=_template.noderef;
        dataObj.save_path='documentLibrary/clients/'+header.client+"/"+savePath;
        dataObj.table_head=this.getTableHead(batchData); 
        dataObj.table_body=this.getTableBody(batchData);
        dataObj.valued_table_head=this.getTableHead(this.valued); 
        dataObj.valued_table_body=this.getTableBody(this.valued);
        dataObj.unvalued_table_head=this.getTableHead(this.unvalued, true); 
        dataObj.unvalued_table_body=this.getTableBody(this.unvalued, true);
        dataObj.client=header.client;
        dataObj.client_code=_org.docs[0].organization_code;
        try { dataObj.department_code = _suborg.docs[0].organization_code; } catch(e) { dataObj.department_code="-" }
        dataObj.representative_fullname=_org.docs[0].representative.nume;
        dataObj.representative_email=_org.docs[0].representative.email;
        dataObj.department_representative_fullname=_suborg.docs[0].representative.nume;
        dataObj.department_representative_email=_suborg.docs[0].representative.email;
        dataObj.department=header.department;
        dataObj.complete_client_name=header.client+", "+header.department;
        if (header.client==header.department) {
          dataObj.complete_client_name=header.client;
        }
        dataObj.active=header.active;
        try {
        dataObj.department_code=_suborg.docs[0].organization_code;
        } catch(e) { dataObj.department_code="-"}
        dataObj.monitoring_type=header.monitoring_type; 
        try {
        dataObj.savename=header.batchId+"_"+_suborg.docs[0].denumire;
        } catch(e) { dataObj.savename=header.batchId+"_"+Date.now(); }
        dataObj.creator_name=this.auth.user.username;
        dataObj.dosimeter_make=header.dosimeter_make;
        dataObj.allocation_period =  _period;
        if (header.returnDate) { dataObj.return_date=header.returnDate } else { dataObj.return_date=new Date()}
        if (header.validDate)  { dataObj.valid_date=header.validDate } else { dataObj.valid_date=new Date()}
        if (header.validBy) { dataObj.valid_by= header.validBy; }  else { dataObj.valid_by=""}
        if (header.returnBy) { dataObj.return_by= header.returnBy; }  else { dataObj.return_by=""}
        if (dataObj.valid_by=="") { dataObj.valid_by=this.auth.user.fullName; }
        dataObj.valid_date=this.utils.getFormattedDate(dataObj.valid_date);
        dataObj.return_date=this.utils.getFormattedDate(dataObj.return_date);
        dataObj.date=this.utils.getFormattedDate(dataObj.date);
        dataObj.year= new Date().getFullYear(); // this.utils.getFormattedDate(dataObj.date, 'YYYY');
        dataObj.save_path='documentLibrary/clients/'+header.organizationId+"/"+header.suborganizationId+"/"+savePath+"/"+dataObj.year;
        let lined=await this.utils.getAddressLineConfig();
        dataObj.department_address = _addy.getFullAddress(lined);

        if (savePath=="DoseReports") {
          let _q=new DoseReportModel()
          _q.client=dataObj.client;
          _q.department=dataObj.department;
          _q.organization_code=dataObj.client_code;
          _q.suborganization_code=dataObj.department_code;
          _q.batchId=header.batchId;
          _q.organizationId=header.organizationId;
          _q.suborganizationId=header.suborganizationId;
          _q.save_name=dataObj.savename;
          _q.save_path=dataObj.save_path;          
          
          let r;
          if (_old_report_number) {
            let _oldq={ "query": { "query": { "report_number": _old_report_number} , "collection_name": "dose_report"}}
            let oldreport=await this.data.getGeneric(this.auth.user.token, _oldq, {})
            if (oldreport.docs.length==1) {
              let ord=oldreport.docs[0];
              _old_report_override=" ("+this.ls.strings.report_replace+" "+ord.report_number+"/"+this.utils.getFormattedDate(ord.created)+")"
            }
            console.log("OVERRIDE FOR OLD REPORT NUMBER ", oldreport, _old_report_override);
          }
          if (_register) {
            r=await this.data.registerDoseReport(this.auth.user.token, _q);
            register_dose_report=r.report.message;
            
          } else {
            register_dose_report= { message: "", report_number: "-", version: "-"}
            dataObj.save_path="temp";
          }
          
          console.log("REPORTSERVICE register_dose_report= ", register_dose_report)
          dataObj.old_report_override=_old_report_override;
          dataObj.report_number=register_dose_report.report_number;
          dataObj.savename=header.monitoringKey+"_"+header.client+"_"+header.department+"_"+dataObj.report_number;
          dataObj.version=register_dose_report.version;
          dataObj.report_method_descriptor=this.getMeasurementDisclaimer(header.monitoringKey)
          dataObj.serial_code=dataObj.report_number;//+"/"+this.utils.getFormattedDate(dataObj.date,'YYYY');//+"v"+dataObj.version;

          dataObj.unreturned_list_message=this.ls.strings.unreturned_list_message;
          dataObj.header=header;
          if (this.unvalued.length==0) {
            dataObj.unreturned_list_message="";
            dataObj.unvalued_table_body="";
            dataObj.unvalued_table_head="";
          }
          //if (_register) { this.generateReportNotifications(dataObj); }
        }
        //for testing crap in filename
        //dataObj.save_name="Dioniso's `$%^&#&*(xxx).html";
        //dataObj.savename="Dioniso's `$%^&#&*(xxx).html";
        try { dataObj.save_name = this.utils.stripNonAlphaNumeric(dataObj.save_name)+".html"; } catch(e) { }
        try { dataObj.savename = this.utils.stripNonAlphaNumeric(dataObj.savename)+".html"; } catch(e) { }
        console.log("REPORTSERVICE header DATA OBJ ", dataObj);

        return dataObj;

      }
      public async generateReportNotifications(reportObject) {
        let notification=new NotificationModel();
        notification.created=new Date();
        notification.createdBy=this.auth.user.username;
        notification.creatorName=this.auth.user.fullName;
        notification.title=this.ls.strings.notification_new_report_title+" "+reportObject.serial_code;
        notification.body=reportObject.serial_code;
        notification.source=reportObject;

        await this.data.generateReportNotifications(this.auth.user.token, notification);
      };


      //generate shipped notifications
      public async generateShippedDosimetersNotifications(shippedObject, allocs) {
        let notification=new NotificationModel();
        notification.created=new Date();
        notification.createdBy=this.auth.user.username;
        notification.creatorName=this.auth.user.fullName;
        notification.title=this.ls.strings.notification_shipped_dosimeters+" "+shippedObject.serial_code;
        notification.body=shippedObject;
        notification.source=allocs;
        await this.data.generateShippedDosimetersNotifications(this.auth.user.token, notification);
      };


      public async exportMultiple(arr, columns, template?):Promise<any> {
        let processed=[];
        console.log("should export multiple", arr, columns);
        ReportService.multiple = { index: 0 , total: 1};
        ReportService.multiple.total=arr.length;
        ReportService.multiple.index=0;
        for (var i=0;i<arr.length;i++) {
          
            let bdata=arr[i];
            let batchData=await this.data.getBatcheDetails(this.auth.user.token, bdata);
            console.log("MM Batch data is ", batchData)
            //let dataObj:any=await this.exportOne(batchData.batches.docs, '', columns);
            let dataObj:any=await this.exportOne(batchData.batches.docs, '', columns, null, null, null, null, null, template);
            let resp=await this.alfrescoService.quickGenerator(this.auth.user.token, {payload: dataObj});
            if (resp.status==200) {
                processed.push(resp)
            }
            ReportService.multiple.index++;
            console.log("generated ", ReportService.multiple)
        }
        let response={ sent: arr, generated: processed};
        return response;
      }


}