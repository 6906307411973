import { BaseModel } from "./base.model"

export class DosimeterModel extends BaseModel {
    public id: String;
    public make:String;
    public type:String;
    public created:Date;
    public modified:Date;
    public state:String;
    public field:Object;
    public annealDate:Date;
    public annealBy: String;
    constructor() {
        super();
        this.collection_name="dosimeters";
    }
}