import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { OrganizationModel } from '../../../@core/models/organization.model';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import { PrinterService } from '../../../@core/services/printer/printer.service';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { BaseComponent } from '../../client/base/base-component';

@Component({
  selector: 'ngx-extra-label',
  templateUrl: './extra-label.component.html',
  styleUrls: ['./extra-label.component.scss']
})
export class ExtraLabelComponent extends BaseComponent implements OnInit {
  @ViewChild('printerLabelSelect') printerLabelSelect;
  @Input('allocation') allocation=null;
  @Input('organization') organization=null;
  //organization:OrganizationModel=new OrganizationModel();
  
  static SelectedPrinter:any;
  selectedPrinter:any;
  printerLabels=[];

  constructor(protected ref: NbDialogRef<ExtraLabelComponent>,
    public data:DataService, protected authService:AuthService, 
    private utilsService:UtilsService,
    public ls: LanguageService, private dialogService: NbDialogService, private printerService:PrinterService) { 
    super()
  }

  ngOnInit(): void {
    console.log("EXTRA LABEL PRINT FOR ALLOCATION ", this.allocation)
    this.initialize();
  }

  async initialize() {
    let labels_resp:any = await this.data.getPrintLabels({active:true}); 
    this.printerLabels=labels_resp.docs;
    console.log("printer Labels is ", this.printerLabels)
  }



  printerSelect(event) {
    ExtraLabelComponent.SelectedPrinter=this.selectedPrinter;
    console.log("selected printer is ", event, this.selectedPrinter);
    this.printerService.setPrinter(this.selectedPrinter)
  }

  isPrinterEnabled():Boolean {
    let bool=false;
    try {
    if (this.printerLabelSelect.selected!=null) bool=true;
    } catch(e) {}
    return bool;
  }

  printLabel() {
    console.log(this.organization, this.allocation)
    if (this.organization!=null) { this.printShippingLabel()}
    else {
    let obj:any={};
    for (var k in this.allocation) {
      obj[k]=this.allocation[k];
    }
    obj.denumire=this.allocation.client;
    obj.firstName=this.allocation.firstName;
    obj.lastName=this.allocation.lastName;
    obj.allocationDate=this.allocation.allocStart;
    obj.suborganization=this.allocation.department;
    obj.dosimeterId=this.allocation.dosimeterId;
    obj.measurement_type=this.allocation.monitoring_type;
    obj.code=this.allocation.code;
    obj.organization_code=this.allocation.organization_code;
    obj.suborganization_code=this.allocation.suborganization_code;
    obj.dosimeter_make=this.allocation.dosimeter_make;
    obj.dosimeter_type=this.allocation.dosimeter_type;
    
    obj.allocation_period=this.utilsService.getFormattedPeriod(this.allocation.allocStart, this.allocation.allocEnd);
    this.printerService.printLabelObject(obj);
    this.ref.close();
    }
  }

  printShippingLabel() {
    this.printerService.printShippinglObject(this.organization);
    this.ref.close();
  }

  dismiss() {
    this.ref.close();
  }
}
