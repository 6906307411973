import { BaseNomenclature } from "./baseNomenclature.model";

export class ImportFields extends BaseNomenclature {
    // minValue: Number;
    // maxValue: Number;
    public title: String;
    public cumulated_title: String;
    constructor(){
        super();
        this.collection_name="import_fields";
    }
}