import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthService, NbResetPasswordComponent } from '@nebular/auth';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import { DataService } from '../../../@core/services/data/data.service';

@Component({
  selector: 'ngx-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends NbResetPasswordComponent implements OnInit {
  constructor(private fb: FormBuilder, 
    public auth:AuthService,
    public data:DataService,
     service: NbAuthService, 
     @Inject(NB_AUTH_OPTIONS) options:{},
      cd: ChangeDetectorRef, router: Router,public ls:LanguageService){
     super(service, options, cd, router);
   }
   authResult:object={
    status:'',
  
  };  
  ngOnInit(): void {
  }
  async submitRequest() {
    console.log("submitting request")
    let r=await this.data.resetPassword(this.user);
    if (r.status==200) {
      this.auth.showToast( 'success',this.ls.strings.reset_password_message_200, '')
      this.auth.logout();
    } else {
      this.auth.showToast( 'danger',this.ls.strings.reset_password_message_404, '')
      this.user.username='';
      this.user.email='';
      //this.auth.showError(this.ls.strings.reset_password_message_404);
    }
    console.log("response value is ", r)
  }
}
