import { BaseNomenclature } from "./baseNomenclature.model";

export class CodeComment extends BaseNomenclature {

    public comment: Object;

    constructor() {
    super()
    this.collection_name="code_comment";  
    }
}