import { BaseNomenclature } from "./baseNomenclature.model";
import { DosimeterType } from "./dosimeterType.model";

export class DosimeterMake extends BaseNomenclature {
    types:Array<DosimeterType>;
    constructor(){
        super();
        this.types=[];
        this.collection_name="dosimeter_makes";
    }
}