import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import { NbAutocompleteModule, NbInputModule, NbCardModule, NbListModule, NbButtonModule, NbStepperModule, NbActionsModule, NbUserModule, NbCheckboxModule, NbRadioModule, NbDatepickerModule, NbSelectModule, NbIconModule, NbTreeGridModule, NbLayoutModule, NbMenuModule, NbSearchModule, NbSidebarModule, NbContextMenuModule, NbThemeModule, NbDialogModule, NbPopoverModule, NbTabsetModule, NbTooltipModule, NbWindowModule, NbCalendarModule, NbCalendarRangeModule, NbToggleModule,
NbSpinnerModule, NbProgressBarModule } from '@nebular/theme';
import { QRCodeModule } from 'angular2-qrcode';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ThemeModule } from '../../../@theme/theme.module';
import { AssetManagementRoutingModule } from '../../asset-management/asset-management-routing.module';

import { PaginationComponent } from '../../../@core/components/pagination/pagination.component';
import { AllocationComponent } from '../../../@core/components/allocation/allocation.component';
import { NbColumnsService, NbComponentStatus, NbDateService, NbDialogService, NbGlobalPhysicalPosition, NbGlobalPosition, NbStepperComponent, NbToastrService, NbToggleComponent } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { AddressComponent } from '../../../@core/components/address/address.component';
import { RepresentativeComponent } from '../../../@core/components/representative/representative.component';
import { ClientOverviewComponent } from '../../../@core/components/client-overview/client-overview.component';
import { EditwearermonitoringtypeComponent } from '../../../@core/components/editwearermonitoringtype/editwearermonitoringtype.component';
import { FolderBroswerComponent } from '../../../@core/components/folder-broswer/folder-broswer.component';
import { DynamicformComponent } from '../../../@core/components/dynamicform/dynamicform.component';
import { DosimeterInputComponent } from '../../admin/admin-allocation/dosimeterInput/dosimeterInput.component';
import { AddBulkMonitoring } from '../../../@core/components/addbulkmonitoring/addbulkmonitoring.component';
import { ServicesComponent } from '../../../@core/components/services/services.component';
import { ViewClientDashboardComponent } from '../../client/view-client-dashboard/view-client-dashboard.component';
import { SiteSectionComponent } from '../../client/site-section/site-section.component';



 
@NgModule({
  declarations: [ PaginationComponent, AllocationComponent, AddressComponent, RepresentativeComponent, ClientOverviewComponent, EditwearermonitoringtypeComponent, 
    FolderBroswerComponent, DynamicformComponent, DosimeterInputComponent, AddBulkMonitoring, 
    ViewClientDashboardComponent,
    ServicesComponent,
    SiteSectionComponent],
  imports: [
    ThemeModule,
    NbInputModule,
    NbListModule,
    NbButtonModule,
    NbInputModule,
    NbToggleModule,
    NbStepperModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbProgressBarModule,
    NbSpinnerModule,
    ReactiveFormsModule, 
    NbCardModule,
    NbTreeGridModule,
    NbInputModule,
    ThemeModule,
    
    Ng2SmartTableModule,
    NbLayoutModule,
    NbMenuModule,
    NbSearchModule,
    NbSidebarModule,
    NbContextMenuModule,
    NbThemeModule,
    NbEvaIconsModule,
    NbDialogModule,
    NbPopoverModule,
    NbTabsetModule,
    NbTooltipModule,
    NbWindowModule,
    QRCodeModule,
    NgxBarcodeModule,
    NbStepperModule,
    FormsModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbAutocompleteModule
 
    
  ],exports:[
    
    AddBulkMonitoring,
    ThemeModule,
    NbInputModule,
    PaginationComponent,
    AddressComponent,
    RepresentativeComponent,
    ClientOverviewComponent,
    FolderBroswerComponent,
    ServicesComponent,
    NbListModule,
    NbButtonModule,
    NbStepperModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbToggleModule,
    ReactiveFormsModule, 
    NbCardModule,
    NbTreeGridModule,
    NbInputModule,
    ThemeModule,
    
    Ng2SmartTableModule,
    NbLayoutModule,
    NbMenuModule,
    NbSearchModule,
    NbSidebarModule,
    NbContextMenuModule,
    NbThemeModule,
    NbEvaIconsModule,
    NbDialogModule,
    NbPopoverModule,
    NbTabsetModule,
    NbTooltipModule,
    NbWindowModule,
    QRCodeModule,
    NgxBarcodeModule,
    AllocationComponent,
    Ng2SmartTableModule,
    NbCardModule,
    NbTreeGridModule,
    NbIconModule,
    NbInputModule,
    NbSpinnerModule,
    NbProgressBarModule,
    ThemeModule,
    
    Ng2SmartTableModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbAutocompleteModule,
    ViewClientDashboardComponent,
    SiteSectionComponent,
    EditwearermonitoringtypeComponent
    
  ],
  entryComponents:[ AddBulkMonitoring]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> { 
    return {
      ngModule: SharedModule,
      providers: [

      ],
    };
  }
 }
