import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbAuthService, NbRegisterComponent, NB_AUTH_OPTIONS } from '@nebular/auth';
import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
  NbToastrConfig,
} from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import { ActivatedRoute } from '@angular/router';
import { Representative } from '../../../@core/models/representative.model';
import { Address } from '../../../@core/models/address.model';
import { BaseModel } from '../../../@core/models/base.model';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { BudgetCode } from '../../../@core/models/budgetCode';
import { OrganizationModel } from '../../../@core/models/organization.model';

@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends NbRegisterComponent {
  organization:OrganizationModel=new OrganizationModel();
  @ViewChild('budgetCodeSelect') budgetCodeSelect;
  public budgetCodes:any[]; 
  stepperIndex: number = 0;
  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  adminMode: boolean;
  budget_code: BudgetCode;
  representative:Representative;
  workplace_address:Address;
  config: NbToastrConfig;
  showEIN:boolean;
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  selectedDate: Date;
  static SelectedDateValue:Date;
  status: NbComponentStatus = 'primary';
  settings = {
    pager: {
      display: true,
      perPage: 15
    },
    hideSubHeader: true,
    actions:{  add: false,
  edit:false,
    delete: false,
  },
  edit: {
    editButtonContent: '<i class="nb-edit"></i>',
    saveButtonContent: '<i class="nb-checkmark"></i>',
    cancelButtonContent: '<i class="nb-close"></i>',
  },
    columns: {
 
      keys: {
        title: 'Details',
 
      },
      values: {

        //  facem aici un on prepare function, si daca este vorba de addresa/reprezentant, atunci luam cu getHumanValue
    
      },
  
    },
  };

  source: LocalDataSource = new LocalDataSource();

  company: any;
  loaded:boolean=false;
  
    constructor(private fb: FormBuilder, 
     public auth:AuthService,
       public data:DataService,
       private toastrService: NbToastrService, private utils:UtilsService,
      service: NbAuthService, 
      @Inject(NB_AUTH_OPTIONS) options:{},
       cd: ChangeDetectorRef, router: Router,public ls:LanguageService, 
       private activatedRoute: ActivatedRoute, ){
      super(service, options, cd, router)
     // this.title_key="component_title_new_client"
    }

    
  ngOnInit() {
    this.firstForm = this.fb.group({
      cui: [null, Validators.required,   ],
      
    });

    this.secondForm = this.fb.group({
      denumire: [null, Validators.required],
      nrRegCom: [null],
      budget_code: [null],
      contract_valability: [null],
      organization_code: [null],
      email: [null, [Validators.required,Validators.email]],
      workplace_address: [null],
      banca:[null],
      iban:[null]

    });

    this.thirdForm = this.fb.group({
      representative: [null],
      adresa_web:[null ]
    });
    this.initialize();

  }
  async initialize() {
    await this.configEin();
    await this.getBudgetCode();
    this.budget_code= new BudgetCode();
    this.representative=new Representative();
    this.workplace_address=new Address(); //this.utils)
    let ind = this.router.url.indexOf("auth/register");
    console.log("IND", ind);
    if (ind == -1) {
      this.adminMode= true
    } else {this.adminMode = false};

    
    this.activatedRoute.params.forEach((params) => {

    });
    this.loaded=true;
  };

   async configEin() {
   this.showEIN=await this.utils.getShowEIN();
   console.log("config ein is ", this.showEIN);
  };

  async getBudgetCode()  {
    let resp= await this.data.getGeneric(this.auth.user.token, new BudgetCode());
    this.budgetCodes= resp.docs;
    try {
    setTimeout(()=> {
      this.budgetCodeSelect.selected=this.budgetCodes.filter(el=>el.description==this.budget_code.key)[0];
    }, 0)
    
    } catch(e) { console.log("budget error ", e) }
  };

  setBudgetCodeChange(event) {

    this.budget_code=event;
  };

  onDateChangeEvent(event) {
    this.selectedDate=new Date(event.srcElement.value);
  //   this.selectedDate=event.srcElement.value;
  // RegisterComponent.SelectedDateValue=this.selectedDate
  };

  onChangeEvent(event) {
    console.log("event in register is ", event);

  }

   async onFirstSubmit() {
    console.log(this.firstForm.value)
    let resp = await this.data.getOrganizationAnaf(this.firstForm.value)
    resp=resp.message;
    if (resp.size !=0)  {
      this.showToast("warning", this.ls.strings.warning,'');
    }
    this.secondForm.patchValue({
      codPostal: resp.codPostal,
      adresa: resp.workplace_address,
      budget_code: resp.budget_code.key,
      contract_valability: resp.contract_valability,
      organization_code: resp.organization_code,
      denumire:resp.denumire,
      nrRegCom:resp.nrRegCom,
      iban:resp.iban,
      telefon:resp.telefon
    });
   // this.firstForm.markAsDirty();
   console.log(this.stepperIndex)
  }

  onSecondSubmit() {
    this.secondForm.markAsDirty();
    console.log(this.stepperIndex)
    if (this.showEIN == false) {
      this.firstForm.patchValue({cui:this.utils.generateRandomSequence()});
    }
  }

  async onThirdSubmit() {
    this.thirdForm.markAsDirty();
    this.company = {
      ...this.firstForm.value,
      ...this.secondForm.value,
      ...this.thirdForm.value
    };
    
    this.company.workplace_address = this.workplace_address;
    this.company.representative = this.representative;
    this.company.budget_code = this.budget_code.key;
    try {this.company.contract_valability=this.utils.getFormattedDate(this.selectedDate, 'yyyy-MM-dd')}
      catch(e) { return "-";}

      let lined=await this.utils.getAddressLineConfig();
      let temp_workplace_address = this.workplace_address.getFullAddress(lined)

    console.log("temp workplace address is ", temp_workplace_address);
    let data: any = [];
    const keys = Object.keys(this.company);
    keys.forEach((key, index) => {
      let val = this.company[key];
      try {
        if (typeof this.company[key] == 'object') val = this.company[key].getHumanValue()
      } catch (e) { console.log("could not fix it for ", key) }
      if (key=="workplace_address") {
        val = temp_workplace_address;
      }
      data.push({ keys: this.ls.strings[key] || key, values: val })

    });
    this.source.load(data);
    console.log(this.source)
    console.log(data)
    console.log(this.showEIN, this.company)

  }
    authResult:object={
      status:'',
    
    }; 

    disabledConfirm(): Boolean {
      let bool = false;
      try {
      if(this.representative.nume.length>1 && this.representative.rol_reprezentant.length>1 ) bool=true;
      } catch(e) {}
      return bool;
    }

  
    async register(){
      this.company['parent']="";
       if(this.company.cui!=null) {
        this.company.cui=String(this.company.cui).split(" ").join("")
       }
      this.company.budget_code = this.budget_code;
      try {this.company.contract_valability=this.utils.getFormattedDate(this.selectedDate, 'yyyy-MM-dd')}
      catch(e) { this.company.contract_valability = "-";}
      this.company.representative=this.representative;
      this.company.workplace_address=this.workplace_address;
      this.company.active=false;
      this.company.enrolled=false;
      console.log("===========", this.company)
      console.log("=====this data ", this.data, this.data.registerOrganization)
      try {
        this.utils.trimStrings(this.company);
      let r = await this.data.registerOrganization(this.company);
      console.log("=========== registration response is ", r)
     if(r.message){
      let    message= r.message.split('.')
      this.showToast(this.status,message[0],message[1]); 
     }
     this.router.navigateByUrl('/pages/admin/activate_clients')
      } catch(e) {
        console.log(e);
        this.showToast("warning", this.ls.strings.warning,e.message);
      } 
    }


    disabledNextButton(): Boolean {
      let bool = false;
      try {
        if(this.workplace_address.name.length>1) bool=true;
      } catch(e) {}
      return bool;
     }


private showToast(type: NbComponentStatus, title: string, body: string) {
  const config = {
    status: type,
    destroyByClick: true,
    duration: 6000,
    hasIcon: true,
    position: this.position,

  };
  const titleContent = title ? `. ${title}` : '';

 
  this.toastrService.show(
    body,
    `${titleContent}`,
    config);
}
}
