<nb-card [nbSpinner]="loadingData">
    <nb-card-header>
        <div style="float:left">{{ls.strings.validate_allocation}}</div>
        <div style="float:right">
            <button nbButton hero status="primary" (click)="scrollList(-1)" class="previous">&laquo; Previous</button>
            {{currentIndex}}/{{currentLength}}
            <button nbButton hero status="primary" (click)="scrollList(1)" class="next">Next &raquo;</button>
        </div>
    </nb-card-header>
    <nb-card-body style="text-align: center;" >
        {{body}}
        <div class="row">
        <div class="col-sm-12">
            <div class="form-header">
                <div class="center-align">
                    <p>{{ls.strings.customer_services}}:&nbsp;<span><b>{{servicesKeys}}</b></span></p>
                  </div>

                
               <p>{{ls.strings.denumire}}:&nbsp;<span><b>{{batchLabels.client}}</b></span></p>
               <p>{{ls.strings.department}}:&nbsp;<span><b>{{batchLabels.department}}</b></span></p>
               <p>{{ls.strings.allocation_start}}:&nbsp;<span><b>{{batchLabels.allocStart | date}}</b></span></p>
               <p>{{ls.strings.count_alloc}}:&nbsp;<span><b>{{batchesSource.count()}}</b></span></p>
               <p>{{ls.strings.active_wearers}}:&nbsp;<span><b [ngStyle]="{'color': activeWearers !== batchesSource.count() ? 'red' : 'inherit'}">{{activeWearers}}</b></span></p>
               <p>{{ls.strings.monitoring_type}}:&nbsp;<span><b>{{batchLabels.monitoring_type}}</b></span></p>
               <p>{{ls.strings.department_code}}:&nbsp;<span><b>{{batchLabels.suborganization_code}}</b></span></p> 
            
           </div>
       </div>

       <div style="text-align: center;">
       <button nbButton hero status="success"  [disabled]="loadingData"   (click)="ship()"><nb-icon icon="car-outline"></nb-icon>{{ls.strings.ship}}</button>
       <button nbButton hero status="info" (click)="startExport()"><nb-icon icon="download-outline"></nb-icon>{{ls.strings.export_pdf}}</button>
       <button nbButton hero status="primary"  (click)="printShippingLabel()"><nb-icon icon="printer-outline"></nb-icon>{{ls.strings.print_shipping_label}}</button>
       <button nbButton hero status="primary" (click)="printClientShippingLabel()"><nb-icon icon="printer-outline"></nb-icon>{{ls.strings.print_client_shipping_label}}</button>
    </div>
  
    </div>
    <br>

        <ng2-smart-table  #batchesTable [settings]="batchesSettings"  [source]="batchesSource" (userRowSelect)="onUserRowSelect($event)"
         ng-reflect-is-all-selected='true'
         (create)="onCreate($event)"
        (edit)="onEdit($event)"
        (delete)="onDelete($event)"
         >
       </ng2-smart-table>
    </nb-card-body>
</nb-card>

