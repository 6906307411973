export class MonitoringType {
    active:boolean
    allocEnd:string;
    allocStart:string;
    price:number;
    dom: string
    dosimeter_make:string;
    dosimeter_type:string;
    monitoring_frequency:string;
    monitoring_type:string;
    organization:string;
    organizationId:string;
    position:string;
    mt:any;
    collection_name: string;

    constructor() {
        this.active=true;
        this.allocEnd="";
        this.allocStart=""
        this.dom="";
        this.dosimeter_make="";
        this.monitoring_type="";
        this.dosimeter_type="";
        this.monitoring_frequency="";
        this.organization="";
        this.organizationId="";
        this.position="";
        this.mt={};
        this.collection_name="dosimeter_measurements"
    }

}