import { Injectable, Sanitizer } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { DataService } from '../data/data.service';
import { AuthService } from '../auth/auth.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Address } from '../../models/address.model';
import { Countries } from '../../models/countries.model';
import { ShippingType } from '../../models/shippingType.model';
import { DosimeterPosition } from '../../models/dosimeterPosition.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MonitoringType } from '../../models/monitoringType.model';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public app_config: any;
  public normalizedMenu:any;
  private configData:any;
  private frequencies:any;
  private disabledInput: boolean = false;
  private maintenance_mode:any;
  private dosimeterPositions:any;
  private lastLogin:any;
  public organizationNames:any;
  public allOrganizations:any;
  public countries:any;
  public pass_strength_regex;
  public shipping_types:any;
  monitoringTypes: any;
    constructor(private dataService:DataService, private datePipe:DatePipe, private auth:AuthService, private sanitizer:DomSanitizer) {
      console.log("------------------UTILS SERVICE-------------------------")
      this.normalizedMenu={};
      this.initialize();
      this.setUpListener();
    }
    async initialize() {
      this.app_config = this.dataService.app_config;
     // await this.getConfig()
     // await this.getCountries();
     // await this.getShippingTypes();
      

    }
    private async validateToken():Promise<any> {
      if (this.auth.user.token!=undefined && this.auth.user.token!=null && String(this.auth.user.token).length>15) {
        //console.log("validating token ", this.auth.user.token)
        let session = await this.dataService.validateToken(this.auth.user.token);
        if (session.status!=200 || session.expired == true) {
          this.auth.invalidateToken();  
        }
        return true;
      } else {
        console.log("no token to validate")
        return false;
      }
    }
    public async getDefaultConfig() {
      console.log("geting default config")
      if (!this.app_config && this.dataService.app_config!=undefined) {
        this.app_config=this.dataService.app_config;
        this.configData = this.app_config;
      }
      console.log("default value is ", this.app_config)
    }
    private async getConfig() {
      let bool = await this.validateToken();
      if (bool==false) {
        //this.app_config=this.dataService.app_config; 
        this.configData = this.dataService.app_config;
        return
       }
      let _q={ "query": { "query": { } , "collection_name": "config"}}
      let resp=await this.dataService.getGeneric(this.auth.user.token,  _q);
      try {
      this.configData=resp.docs[0];
      this.app_config = this.dataService.app_config;
      } catch(e) { 
        console.log("error ", e)
        await this.getConfig()
      }
    }
    private setUpListener() {
      let int=setInterval(async ()=>{
        await this.getConfig();
        this.maintenance_mode=this.configData['maintenance_switch'];
        console.log("this maintenance is ", this.maintenance_mode, "selected user is ", this.auth.user.username);
        if (this.maintenance_mode==true && this.auth.user.username!="superadmin") { this.auth.logout(); } 
      }, 15000)
    }
    public async forceUpdate() {
      await this.getOrganizationNames(true);
      await this.getOrganizations(true)
    }
    async initializeFrequencies() {
      let _q={ "query": { "query": { } , "collection_name": "monitoring_frequency"}};
      let resp=await this.dataService.getGeneric(this.auth.user.token, _q);
      this.frequencies=resp.docs;
    }
    public getRecipients(users, role, field):any {
        let ar=[];
        for (var k in users) {
            let u=users[k];
            if (u.roles.indexOf(role,0)!=-1) {
                ar.push(u[field]);
            }
        }
        return ar.join("<br>");  
    }
    public generateRandomSequence() {
      const _characters = 'qwertyuiopasdfghjklzxcvbnm1234567890QWERASDFZXCVYUIOHJKLBNM1234567890';
      var pass=Date.now()+'';
      for (var i=0;i<16;i++) {
          const r=Math.round(Math.random()*(_characters.length-1));
          pass+=_characters.charAt(r);
      }
      return pass
    }
    public resolveOrganizationName(orgid) {
      console.log("trying to resolve ", orgid, "this.allOrganizations is ", this.allOrganizations.length)
      let o=this.allOrganizations.filter(el=>el._id==orgid)[0];
      return o;
    };
    
    public async getFullOrganizations(forced:boolean=false) {
      if (this.allOrganizations.length==0 || forced==true) {
      let t=await this.dataService.getAllOrganizations(this.auth.user.token);
      this.allOrganizations=t.organizations.docs;
      }
      return this.allOrganizations;
    };
    
    public async getOrganizationNames(bool?):Promise<any> {
      if (this.organizationNames==undefined || this.lastLogin!=this.auth.user) {
      
          let t;
          if (this.auth.isSystemRole()) {
            t=await this.dataService.getAllOrganizations(this.auth.user.token);
            //console.log("get organization names for system user", t)
          } else {
            console.log("this auth user is  ", this.auth.user)
            t=await this.dataService.getSuborgNameById(this.auth.user.token, this.auth.user, true);
            console.log("TTTTTTTTT is ", t, this.auth.user, bool)
          }
          this.allOrganizations=t.organizations.docs;
          if (bool==true) {
            this.organizationNames=t.organizations.docs.filter(el=>el.parent=="");  
          } 
          // else {
          //   this.organizationNames=t.organizations.docs.filter(el=>el.parent=="");
          // }
            else {
            this.organizationNames=t.organizations.docs; //.filter(el=>el.parent=="");
          }
          this.lastLogin=this.auth.user;
      }
       console.log("organization names are ", this.organizationNames)
      return this.organizationNames;
    };


    //GET ALL ORGS AND DEPTS INSTEAD OF COMPANIES
    public async getOrganizations(bool?):Promise<any> {
      if (this.organizationNames==undefined || this.lastLogin!=this.auth.user) {
        console.log(this.auth.user)
          let t;
          if (this.auth.isSystemRole()) {
            t=await this.dataService.getOrganizations(this.auth.user.token);
            //console.log("get organization names for system user", t)
          } else {
           // console.log("get organization names for standard user")
            t=await this.dataService.getSuborgNameById(this.auth.user.token, this.auth.user, true);
          }
          this.allOrganizations=t.organizations.docs;
          if (bool==true) {
            this.organizationNames=t.organizations.docs.filter(el=>el.parent=="");  
          } 
          // else {
          //   this.organizationNames=t.organizations.docs.filter(el=>el.parent=="");
          // }
            else {
            this.organizationNames=t.organizations.docs; //.filter(el=>el.parent=="");
          }
          this.lastLogin=this.auth.user;
         // console.log("organization names are ", this.organizationNames)
      }
      return this.organizationNames;
    };

    public async getAddressLineConfig():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.address_lines_switch;
    }
    public async getPasswordStrengthRegex():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.pass_strength_regex;
    }
    public async getPasswordStrengthInfo():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.pass_strenght_details;
    }
    public async getShowEIN():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.ein_required_switch;
    }
    public async getShowPassbook():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.passbook_service_switch;
    };

    public async getShowComments(): Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.multiple_comments_switch;
    };

    public async getGlobalSuborganizationCode(): Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.global_suborg_code;
    };

    public async getMFAConfig():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.mfa_active_switch;
    }
    public async getReportColorScheme():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return JSON.parse(JSON.stringify(this.configData.report_color_scheme_object));
    }
    public async getClassifiedByDefault():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.default_classified_switch;
    }

    public async getDateForAllocation() {
      if (this.configData==undefined) {
        await this.getConfig()
      }
      return this.configData.set_default_allocation_date;
    }

    public async getSuborganizationsDefaultColumns():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      try {
      return this.configData.suborganizations_default_columns.split(" ").join("").split(",");
      } catch(e) {
        return [];
      }
    }
    
    public async getDeliveryNoteFields():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      let keys=this.configData.delivery_note_columns.split(" ").join("").split(",");
      return keys;
    }
    public async getDoseReportFields():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      let keys=this.configData.dose_report_columns.split(" ").join("").split(",");
      return keys;
    }
    public async getAllocationFilterFields():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      let keys=this.configData.allocation_filter_fields.split(" ").join("").split(",");
      return keys;
    }

    public async getIsAuditable(col:String):Promise<any> {
      let bool=false;
      if (this.configData==undefined) {
        await this.getConfig();
      }
      let keys=String(this.configData.auditable_indexes).toLowerCase().split(" ").join("").split(",");
      let ind=keys.indexOf(col.toLowerCase());
      if (ind>-1) { bool = true}
      console.log("-------------get is auditable", col, bool, ind,  " from ", keys)
      return bool;
    }
    public async getDisabledWearerCodeInput():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.disabled_wearer_code_input_switch;
    }
    public async inMaintenance():Promise<any> {
      if (this.configData==undefined) {
        await this.getConfig();
      }
      return this.configData.maintenance_switch;
    }
    public async getDefaultColumns(obj, _type):Promise<any> {
      //console.log("Object in config is ", obj)
      if (this.configData==undefined) {
        await this.getConfig();
      }

      let defaults=this.configData[_type].split(" ").join("").split(",");
      let ob={};
      let fullKeys=[];

      for (var k in obj) {

        fullKeys.push(k)
        if (defaults.indexOf(k)!=-1) {
          ob[k]=obj[k];
        }
      }
      //console.log("defaultColumns is ", ob, this.configData[_type])
      return ob;
    }

    public addToMenu(ob) {
      if (this.normalizedMenu[ob.target]==undefined) {
        this.normalizedMenu[ob.target]=ob;
      }
    }
    public roundUpDose(val) {
      let _v = Math.round(val*100)/100;
      if (isNaN(_v)) { _v=val }
      let s=String(_v).split(".")[1];
      if (s!=undefined && s.length==1) { 
        return String(_v)+0 };
      return _v;
    }
    public uniqueArray(arr) {
      let resp=[];
      for (var k in arr) {
        if (resp.indexOf(arr[k],0)==-1) {
          resp.push(arr[k]);
        }
      }
      return resp;
    }


    public getFormattedDate(d, _format?):any {
      let f='dd.MM.yyyy';
      if (_format!=undefined) f=_format;
      try {
      return this.datePipe.transform(new Date(d), f);
      } catch(e) 
      {
        return "";
      }
    }
    public getFormattedPeriod(startD, endD, _format?) {
      let f='LLL yyyy';
      if (_format!=undefined) f=_format;
      let sd=this.getFormattedDate(startD,f);
      let ed=this.getFormattedDate(endD, f);
      let _period = sd + " - "+ed;
      if (sd==ed) { _period=ed }
      return _period;
    }
    public async calculateAssignmentPeriods(ob): Promise<any> {
      if (this.frequencies==undefined) { await this.initializeFrequencies()}
      let _d=new Date();
      let allocDate=ob.allocationDate.split("-");
      _d.setDate(allocDate[2]);
      _d.setMonth(allocDate[1]-1);
      _d.setFullYear(allocDate[0]);
      let allocStart=new Date(ob.allocationDate);
      let allocEnd=this.getAllocationEndDate(allocDate, this.frequencies, ob.monitoring_frequency)
      console.log("UTILS calculating assignment period for ", allocStart, allocEnd);
      let _p=this.getFormattedPeriod(allocStart, allocEnd);
      console.log("UTILS calculated asignment period is ", _p);
      ob.allocation_period=_p;
      return ob;
    }
    private getAllocationEndDate(startDate, _mf, period) {
      let _endDate=new Date(startDate);
      let _months=1;
      for (var k in _mf) {
          if (_mf[k].value==period) {
              _months=Number(_mf[k].key);
              break;
          }
      }
      _endDate.setMonth(_endDate.getMonth()+_months);
      _endDate.setDate(_endDate.getDate() -1);
      return _endDate; 
      
  }
  checkDuplicateMonitoring(profile:any, o:any, edit?:boolean) {
    if (edit==undefined) { edit=false }
    if (o.position==null) { o.position=""}
    let b=false;
    console.log("this prof is ", profile, "this res is ", o)
    let _t=profile.monitoring_type.filter(i => i.monitoring_type == o.monitoring_type 
    && i.suborganizationId == o.suborganizationId
    && i.monitoring_frequency == o.monitoring_frequency
    && i.dosimeter_make == o.dosimeter_make
    && i.dosimeter_type == o.dosimeter_type
    && i.position == o.position
  );
    console.log("t length is ",_t, _t.length)
    if (_t.length>0) b=true;
    if (b==true && edit==true && _t.length==1) { b=false }
    return b;
    
  };


//check duplicate service
  checkDuplicateService(organization:any, o:any, edit?:boolean, originalService?:any) {
    if (edit==undefined) { edit=false }
    let b=false;
    let servicesToCheck = organization.services;
    if(edit && originalService) {
      servicesToCheck = organization.services.filter(i => i !== originalService)
    }

    let _t=servicesToCheck.filter(i => i.key == o.key) 
    if (_t.length>0) return true;
    let _t2=servicesToCheck.filter(i => i.measurement == o.measurement && i.description==o.description) 
    if (_t2.length>0) return true;
    return false;
  };


  sanitizeToSort(str) {
   // console.log("sanitizing |",str,'|')
    if (str==undefined) str="";
    return str
      .normalize('NFD')                   // Remove accented and diacritics
      .replace(/[\u0300-\u036f]/g, '')    // Remove accented and diacritics
      .toLowerCase()                      // Sort will be case insensitive
    ;
  }
  
  sortByProperty(arr, property, order="ASC") {
    try {
      arr.forEach((item) => item.tempProp = this.sanitizeToSort(item[property]));
    } catch(e) { console.log(e)}
    arr.sort((a, b) => order === "ASC" ?
        a.tempProp > b.tempProp ?  1 : a.tempProp < b.tempProp ? -1 : 0
      : a.tempProp > b.tempProp ? -1 : a.tempProp < b.tempProp ?  1 : 0
    );
    arr.forEach((item) => delete item.tempProp);
    return arr;
  }

/*   private getCumulative(pid, field, _default?, _record?) {
    console.log("getCumulative LABEL ", pid, field, _default, _record, this.cumulativeDoses)
    field=field.split(" ").join("_").split(".").join("_").split("_Calc__Dose").join("");
    let _p=this.batchData.filter(el=>el.personId==pid)[0]
    let ob:any={ lbl: field, value: _default};
    let d=this.cumulativeDoses.filter(el=>el._id.personId==pid && el._id.monitoring_type==_record.monitoring_type)[0];
    for (var k in d) {
      let lbl=k.split("_Calc__Dose").join("");
      console.log("getCumulative =-=======", lbl, " vs ", field)
      if (lbl==field) {
        ob.lbl=lbl;
        ob.value=d[k];
        console.log("getCumulative found ",_p,  ob)
        break;
      }
    }
    return ob;
  } */


  public getCumulative(pid, field, cumulativeDoses, _default?, _record?) {
    console.log("LABEL ", pid, field)
    field=field.split(" ").join("_").split(".").join("_").split("_Calc__Dose").join("");
    let ob:any={ lbl:"", value: _default};
    let d=cumulativeDoses.filter(el=>el._id.personId==pid && el._id.monitoring_type==_record.monitoring_type && el._id.position==_record.position)[0];
    for (var k in d) {
      let lbl=k.split("_Calc__Dose").join("");
      console.log("=-=======", lbl, " vs ", field)
      if (lbl==field) {
        ob.lbl=lbl;
        ob.value=d[k];
        break;
      }
    }
    return ob;
  }
  async getManifestLabelData(_orgs, _ob) {
    let ob:any=_ob; 
    //this.batchData[0]
    let client= _orgs.filter(el=>el._id==ob.monitoring_type.organizationId)[0];
    // let client = await this.dataService.getOrganizationDetails(ob.organizationId, this.auth.user.token)
    // let suborg= await this.dataService.getOrganizationDetails(ob.suborganizationId, this.auth.user.token);
    let suborg= _orgs.filter(el=>el._id==ob.monitoring_type.suborganizationId)[0];
    let suborg2= _orgs.filter(el=>String(el._id)==String(ob.suborganizationId))[0];
    console.log("get manifestlabel data", ob.organizationId, ob.suborganizationId, client.denumire, suborg, suborg2)
    let obj:any={};
    obj.client=client.denumire;
    obj.department=suborg.denumire;
    let addy:Address=new Address(this);
    addy.fromJSON(suborg.shipping_address);
    obj.city=addy.city;
    obj.zipCode=addy.zipCode;
    obj.address=addy.street+" "+addy.streetNumber;
    //obj.building=addy.floor+", "+addy.apartment;
    //obj.country=addy.country;
    //obj.county=addy.county;
    obj.organization_code=client.organization_code;
    obj.department_code=suborg.organization_code;
    let lined=await this.getAddressLineConfig();
    obj.department_address=addy.getFullAddress(lined);
    obj.representative_fullname=suborg.representative.nume;
    obj.shipping_type=addy.shipping_type;
    //obj.representative_email=suborg.representative.email;
    return obj;
  }
  public async exportDataAsExcel(jsonData:any[], filename?:string, orgs?) {
    console.log("ORGS ARE ", orgs)
    console.log("JSON DATA IS ", jsonData)
    let fileName=filename;
    let shippingLabels=[];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    if (orgs!=undefined)
    for (var k in jsonData) { 
      let ob=await this.getManifestLabelData(orgs,jsonData[k]);
      let ex=shippingLabels.filter(el=>el.client==ob.client && el.department==ob.department&&el.department_address==ob.department_address);
      //console.log("-----------------address obj is ", ex.length, shippingLabels.indexOf(ob,0), ob)
      if (ex.length==0) { shippingLabels.push(ob)}
    }
    console.log("this -------------- shipping labels is ", shippingLabels)
    let _sheets:any={};
    let _sheetNames=['data']    
    _sheets.data=ws;
    if (shippingLabels.length>0) {
      let addys: XLSX.WorkSheet = XLSX.utils.json_to_sheet(shippingLabels);
      _sheets.addresses=addys;
      _sheetNames.push('addresses');
    }

    const wb: XLSX.WorkBook = { Sheets: _sheets, SheetNames: _sheetNames };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  };

  //Export excel for manual dosimeters
  public async exportDosimetersAsExcel(filename:string, dosi:any, columns ) {
    let fileName=filename;
    let data=[];
    for (var k in dosi) {
      let ob={};
      let dosimeter=dosi[k]
      for (var j in columns) {
        let c=columns[j]
        ob[c]=dosimeter[c];
      }
      data.push(ob);
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    let _sheets:any={};
    let _sheetNames=['data']    
    _sheets.data=ws;

    const wb: XLSX.WorkBook = { Sheets: _sheets, SheetNames: _sheetNames };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  };
  //

  public async exportOrganizationsAsExcel(filename:string, orgs:any, columns ) {
    let fileName=filename;
    let data=[];
    for (var k in orgs) {
      let ob={};
      let org=orgs[k]
      for (var j in columns) {
        let c=columns[j]
        ob[c]=org[c];
      }
      data.push(ob);
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    let _sheets:any={};
    let _sheetNames=['data']    
    _sheets.data=ws;

    const wb: XLSX.WorkBook = { Sheets: _sheets, SheetNames: _sheetNames };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  };

  public async exportSuborganizationsAsExcel(filename:string, orgs:any, columns ) {
    let fileName=filename;
    let data=[];
    for (var k in orgs) {
      let ob={};
      let org=orgs[k]
      for (var j in columns) {
        let c=columns[j]
        ob[c]=org[c];
      }
      data.push(ob);
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    let _sheets:any={};
    let _sheetNames=['data']    
    _sheets.data=ws;

    const wb: XLSX.WorkBook = { Sheets: _sheets, SheetNames: _sheetNames };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  };

  public async exportPersonsAsExcel( filename:string, pers, columns) {
    let fileName=filename;
    
    let data=[];
    for (var k in pers) {
      let ob={};
      let per=pers[k];
      console.log("PER IS  ", per)
      for (var j in columns) {
        let c=columns[j]
        console.log(j, " and ",c, " and ", columns[j])
        if (c=="created" || c=='modified' || c=='key_as_string') {
          ob[c]=this.getFormattedDate(per[c]);
        } else {
          if (per[c]!=undefined)  {
            if (per[c].value!=undefined) { 
              ob[c]=per[c].value
            }
            else {
              ob[c]=per[c];
            }
        }

        }
      }
      data.push(ob);
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    let _sheets:any={};
    let _sheetNames=['data']    
    _sheets.data=ws;
    
    const wb: XLSX.WorkBook = { Sheets: _sheets, SheetNames: _sheetNames };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  };
  public async exportMultipleSheetsAsExcel( filename:string, personData, columns, _sheetNames) {
    let fileName=filename;
    let sheetData=[];
    for (var i=0; i<personData.length;i++)
    {
    let data=[]; let pers=personData[i];
    for (var k in pers) {
      let ob={};
      let per=pers[k];
      for (var j in columns) {
        let c=columns[j]
        if (c=="created" || c=='modified' || c=='key_as_string') {
          ob[c]=per[c]; //this.getFormattedDate(per[c]);
        } else {
          if (per[c]!=undefined)  {
            if (per[c].value!=undefined) { 
              ob[c]=per[c].value
            }
            else {
              ob[c]=per[c];
            }
        }

        }
      }
      data.push(ob);
    }
    
    sheetData.push(data)
    }

    let _sheets = {}
    for (let i= 0; i <sheetData.length; i++) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetData[i]);
      _sheets[_sheetNames[i]] = ws
    }
    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetData);
    // let _sheets:any={};
    // _sheets.data=ws;
  
    const wb: XLSX.WorkBook = { Sheets: _sheets, SheetNames: _sheetNames };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  };

  public async exportUnreturnedDosiAsExcel(filename:string, rec:any, columns ) {
    let fileName=filename;
    let data=[];
    for (var k in rec) {
      let ob={};
      let org=rec[k]
      for (var j in columns) {
        let c=columns[j]
        ob[c]=org[c];
      }
      data.push(ob);
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    let _sheets:any={};
    let _sheetNames=['data']    
    _sheets.data=ws;

    const wb: XLSX.WorkBook = { Sheets: _sheets, SheetNames: _sheetNames };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  };

  public flattenObject(ob, prefix) {
    let r={};
    for (var k in ob) {
      r[prefix+"_"+k]=ob[k];
    }
    return r;
  }
  private saveExcelFile(buffer: any, fileName: string): void {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const data: Blob = new Blob([buffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  public async getMonitoringTypes():Promise<any> {
    if (this.monitoringTypes) { return this.monitoringTypes; }
    let resp = await this.dataService.getGeneric(this.auth.user.token, new MonitoringType());
    this.monitoringTypes = resp.docs;
    return this.monitoringTypes;
  }
  public async getCountries():Promise<any> {
    if (this.countries) { return this.countries}
    let resp = await this.dataService.getGeneric(this.auth.user.token, new Countries());
    this.countries=resp.docs;
    return this.countries;
  }
  public async getShippingTypes():Promise<any> {
    if (this.shipping_types) { return this.shipping_types}
    let resp = await this.dataService.getGeneric(this.auth.user.token, new ShippingType());
    this.shipping_types=resp.docs;
    return this.shipping_types;
  }
  public async getDosimeterPositions():Promise<any> {
    if (this.dosimeterPositions) { return this.dosimeterPositions}
    let resp = await this.dataService.getGeneric(this.auth.user.token, new DosimeterPosition());
    this.dosimeterPositions=resp.docs;
    return this.dosimeterPositions;
  }

  public getLapseDays(_date1, _date2) {
    var date1 = Date.parse(_date1);
    var date2 = Date.parse(_date2);
    console.log("value is ", _date1, date1, _date2, date2)
    var diff = Math.abs(date1 - date2);
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
    return diffDays
  }

  public cleanupUndefinedStringValues(ob) {
    let o={};
    for (var k in ob) {
      if (String(ob[k])=='undefined' || String(ob[k])=='null') { ob[k]=''};
      o[k]=ob[k];
    }
    return o;
  }

  public stripNonAlphaNumeric(myString):String {
    return myString.replace(/[^A-Za-z0-9 -]/g, "_");
  }
  public getAuditLink(isAuditable, _type, d, row, isSystemUser) {
   
      if (isAuditable==true && isSystemUser) {
      let iconClass = 'control-icon ion ion-ios-information';
      let rowValue = `${d}&nbsp;<a href="#/pages/admin/history/`+_type+`/${row._id}" class="neutral-icon">
      <i class="${iconClass}" style="margin-left: 5px;"></i></a>`;
      
      let sanitized = this.sanitizer.bypassSecurityTrustHtml(rowValue);
      return sanitized;
      } else {
        return d;
      }
    
  }
  public findByMonitoringType(pers, nm) {
    let o=-1;
    let sc=0;
    for (var k in nm) { if (nm[k]!=null) { sc++ }}
    console.log("max score is ", sc, nm)
    for (var i=0; i<pers.monitoring_type.length;i++) {
      let ps=0;
      //console.log(i)
      for (var k in nm) {
        //console.log(k, " vs ", nm[k])
        
        if (nm[k]!=null && pers.monitoring_type[i][k]==nm[k] ) { ps++; }
       
          else
        if (k=="organizationId" && nm.organizationId != null && pers.monitoring_type[i].suborganizationId === nm.organizationId) {
          ps++;
        };

    }
      console.log(" score ", ps, " vs ", sc, pers.monitoring_type[i], " versus ", nm)
      if (ps==sc) { return i; break;}
    }
    return o;
  }
  public trimStrings(obj) {
    for (let prop in obj) {
      if (typeof obj[prop] === 'string') {
        obj[prop] = obj[prop].trim();
      } else if (typeof obj[prop] === 'object' && obj[prop] !== null) {
        this.trimStrings(obj[prop]);
      }
    }
  }
  public getOrganization(orgs, id, parent) {

    let o=orgs.filter(el=>el._id==id)[0];
    if (parent==true) {
      let p=orgs.filter(el=>el._id==o.parent)[0];
      return p;
    }
    if (parent==false) {
      let p=orgs.filter(el=>el._id==id)[0];
      return p;
    }
    return o;
  }

  public async setDefaultLang():Promise<any> {
    if (this.configData==undefined) {
      await this.getConfig();
    }
    return this.configData.set_default_language;
  };

  public async getShowAllLanguages():Promise<any> {
    if (this.configData==undefined) {
      await this.getConfig();
    }
    try {
    return this.configData.multiple_languages_switch;
    } catch(e) {
      return false;
    }
  }
}