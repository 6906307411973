import { BaseModel } from "./base.model";
import { PersonModel } from "./person.model";

export class UserModel  extends BaseModel {
  
    public profile: PersonModel;
   
    public token:string
    public email:string;
    public username: String;
    public firstName: String;
    public lastName: String;
    public person: String;
    public organizations: string[];
    public roles: string[];
    public preffered_organization:string;
    public selected_suborganization:string;
    public _id:string;
 //public group:string;
    constructor() {
        super();
    }
//UserModel initalization
    protected generateInit(){
        this.active=false;
        this.token="";
        this.username="";
        this.firstName="";
        this.lastName="";
        this.organizations=[];
        this.email="";
       this.profile = new PersonModel();
     }

    public get fullName():string {
        let value=""
        if(this.profile.maidenName){ 
            value=this.profile.firstName+" "+this.profile.initial+" "+this.profile.middleName+" "+this.profile.lastName+" "+this.profile.maidenName;
        } 
        else value=this.profile.firstName+" "+this.profile.initial+" "+this.profile.middleName+" "+this.profile.lastName;
        
        if (value.indexOf("undefined ",0)!=-1) { value=value.split("undefined ").join(""); }
        return value;
    }
    
    public setProfile(ob:any) {
        console.log(ob)
        this.profile = this.profile.fromJSON(ob);
    }   

    public getDescriptor() {
        
        let str;
        str=this.profile.firstName+" "+this.profile.lastName;
        if (str.indexOf("undefined",0)!=-1 || str.length<4) {
            str= this.username;
        }
        console.log("----------------------username ",str)
        return str;
    }
}