<h1 id="title" class="title">{{ls.strings.reset_password_title}}</h1>
<p class="sub-title">{{ls.strings.reset_password_text}}</p>
<div class="d-flex justify-content-center">
<a style="margin-left:15px;height:36px" (click)="this.ls.changeLanguage('en')"><img style="height:inherit;cursor: pointer;" src='./assets/images/uk.png'></a>
<a style="margin-left:15px;height:39px" (click)="this.ls.changeLanguage('ro')"><img  style="height:inherit; cursor: pointer;" src='./assets/images/ro.png'></a>
</div>
<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ ls.strings.reset_password_text }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="submitRequest()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">{{ls.strings.user}}:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.username"
           #username="ngModel"
           name="username"
           id="input-email"
       
           [placeholder]="this.ls.strings.user"
           fieldSize="large"
           autofocus
           [status]="username.dirty ? (username.invalid  ? 'danger' : 'success') : 'basic'"
         >
    <ng-container *ngIf="username.invalid && username.touched">
      <p class="caption status-danger" *ngIf="username.errors?.required">
       {{ls.strings.username_required}}
      </p>
      <p class="caption status-danger" *ngIf="username.errors?.pattern">
        {{ls.strings.username_should_exist}}
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-email">{{this.ls.strings.email}}:</label>
    
    </span>
    <input nbInput
           fullWidth
           [(ngModel)]="user.email"
           #email="ngModel"
           name="email"
           type="email"
           id="input-email"
           [placeholder]="this.ls.strings.email"
           fieldSize="large"
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.email.required')"
           [minlength]="getConfigValue('forms.validation.email.minLength')"
           [maxlength]="getConfigValue('forms.validation.email.maxLength')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched ">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        {{ls.strings.email_required}}
      </p>

    </ng-container>
  </div>

 <!-- <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
  </div>
-->
  <button nbButton
          fullWidth
          status="primary"
          size="large"
       
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
          {{ls.strings.submit}}
  </button>
</form>