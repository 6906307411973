
    <div class="steppers-container row">
        <div class="col-md-12 col-lg-12 col-xxxl-12" >
            <nb-card [nbSpinner]="loadingData">
                <nb-card-header>   {{ls.strings.bulk_monitoring_type}}</nb-card-header>
                <nb-card-body>
                    <nb-stepper #stepper disableStepNavigation orientation="horizontal" [linear]='true' >
                        <nb-step  [stepControl]="firstForm" [label]="ls.strings.first_step">
                            <ngx-editwearermonitoringtype
                            [wizzardMode]="true"
                           [bulkMonitoringProfile]="profile"
                           [selectedSuborganizationId]="selectedSuborganizationId"
                           [monitoringTypes]="monitoringTypes" [dosimeterPosition]="dosimeterPosition" 
                           [dosimeterType]="dosimeterType" [monitoringFequency]="monitoringFequency" 
                           [dosimeterMake]="dosimeterMake" [suborganizations]="suborganizations"
                            ></ngx-editwearermonitoringtype>
                            <button nbButton nbStepperNext hero status="primary" (click)="stepChangeHandler($event)" [disabled]="disabledButton()"
                            class="next-button"
                            >{{this.ls.strings.next}}</button>
                            <button nbButton hero status="danger" class="cancel" (click)="dismiss()">{{this.ls.strings.cancel}}</button>
                        </nb-step>
                        <nb-step [stepControl]="secondForm" [label]="ls.strings.second_step">
                            <ng2-smart-table [settings]="exposedSettings"
                            [source]="exposedSource" (userRowSelect)="onUserRowSelect($event)" 
                            ng-reflect-is-all-selected='true'>
                            
                            </ng2-smart-table>
                            <button class="prev-button" nbButton status="primary" nbStepperPrevious>{{this.ls.strings.prev}}</button>
                            <button nbButton status="primary" class="add" (click)="updatePersons()" [disabled]="addButtonDisabled() || loadingData">{{this.ls.strings.add}}</button>
                            <button nbButton hero status="danger" class="cancel-step2" (click)="dismiss()">{{this.ls.strings.cancel}}</button>
                        </nb-step>
                        
                    </nb-stepper>
                </nb-card-body>

            </nb-card>
        </div>
    </div>