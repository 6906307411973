import { BaseNomenclature } from "./baseNomenclature.model"
import { DosimeterMeasurement } from "./dosimeterMeasurement.model";

export class DosimeterType extends BaseNomenclature {
    measurements:Array<DosimeterMeasurement>;
    constructor(){
        super();
        this.measurements = [];
        this.collection_name="dosimeter_types"
    }
}