import { BaseModel } from "./base.model"

export class DoseReportModel extends BaseModel {

    public report_number:Number;
    public version:Number;
    public noderef:String;
    public batchId:String;
    public client:String;
    public organization_code:String;
    public department:String;
    public suborganization_code:String;
    public organizationId:String;
    public suborganizationId:String;
    public save_path:String;
    public save_name:String;
    public dose_records:[];

    constructor() {
        super();
        this.collection_name="dose_report";
        this.version=1;
        this.batchId="-";
        this.client="-";
        this.department="-";
        this.organizationId="-";
        this.suborganizationId="-";
        this.organization_code="-";
        this.suborganization_code="-";
        this.save_name="-";
        this.save_path="-";
        this.dose_records=[];
    }

}