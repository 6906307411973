<nb-card>
    <nb-card-body style="text-align: center;">
        <span #textBody></span>
    </nb-card-body>
    <nb-card-footer style="text-align: center;">       
        <button nbButton hero status="primary" (click)="accept()">{{confirm}}</button>
        <button nbButton hero status="primary" (click)="dismiss()">{{cancel}}</button>
    </nb-card-footer>
</nb-card>

