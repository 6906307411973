<nb-card [nbSpinner]="loadingData">

    <nb-card-body>
        <button class="home_button" *ngIf="this.breadCrumbs.length>0" status="primary" nbButton ghost   mat-raised-button color="primary" (click)="goHome()">
            <nb-icon icon="home-outline"></nb-icon>
          </button>
        <div class="header-section" *ngFor="let crumb of breadCrumbs; let i=index">
            <span style="margin-left:24px; " *ngIf="i==0 && external_path==null" class="bread_crumb">{{crumb.text||crumb.title}} </span>
            
            <span *ngIf="i>0" class="bread_crumb">> {{crumb.text||crumb.title}}</span>
        </div>
    <button *ngIf="this.breadCrumbs.length>1" status="primary" nbButton ghost   mat-raised-button color="primary" (click)="goBack()">
      <nb-icon icon="undo-outline"></nb-icon>
    </button>
        <ng2-smart-table [source]="tableSource" [settings]="tableSettings"
        (userRowSelect)="onRowClick($event)"
        >

        </ng2-smart-table>

    </nb-card-body>
  </nb-card>
