import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbSelectComponent, NbToastrService } from '@nebular/theme';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import { NbDialogRef } from '@nebular/theme';
import { BaseComponent } from '../../client/base/base-component';
import { DosimeterPosition } from '../../../@core/models/dosimeterPosition.model';
import { MonitoringFrequency } from '../../../@core/models/monitoringFrequency.model';

@Component({
  selector: 'ngx-extra-dosimeters',
  templateUrl: './extra-dosimeters.component.html',
  styleUrls: ['./extra-dosimeters.component.scss']
})
export class ExtraDosimetersComponent extends BaseComponent implements OnInit {
  @Input("allocation") allocation:any;
  @Input('organizationId') organizationId:any;
  @Input('suborganizationId') suborganizationId:any;
  @Input('customerName') customerName:any;
  @ViewChild("allocatedMonitoringType") allocatedMonitoringType:NbSelectComponent;
  organization:any = [];
  monitoring_type= [];
  dosimeterPosition: any[];
  monitoringFequency: any[];
  date= [];
  suborganization= [];
  quantity:number=1;

  constructor(protected ref: NbDialogRef<ExtraDosimetersComponent>, public ls:LanguageService, public data:DataService, public auth: AuthService,
     public toastrService: NbToastrService, private dataService:DataService) {
        super()
      }
  
  ngOnInit(): void {
    console.log("this allocation before depersonalize is ", this.allocation, "user is ", this.auth.user.username)
    this.allocation=this.depersonalizeAllocation(this.allocation);
    this.getOrganization();
    this.getPosition();
    this.getMonitoringFrequency()
  }
  private depersonalizeAllocation(a) {
    a.created=null;
    // a.createdBy=null;
    a.createdBy=this.auth.user.username;
    a.modified=null;
    a.modifiedBy=null;
    a.dob=null;
    a.description=null;
    a.cnp=null;
    a.code=null;
    a.classified=false;
    a.nationality=null;
    a.firstName=null;
    a.lastName=null;
    a.initial=null;
    a.middleName=null;
    a.monitoring_type=null;
    a.maidenName=null;
    a.phone=null;
    a.personId=null;
    a.position=null;
    a.sex=null;
    a.profession=null;
    a.uid=null;
    a.type=null;
    a._id=null;
    a.dosimeterId=null;
    a.practice=null;
    a.price=null;
    a.email=null;
    return a;
  }
  async getOrganization() {
      let r=await this.dataService.getOrganizationDetails(this.allocation.organizationId, this.auth.user.token);
      this.organization=r.docs[0];
      this.monitoring_type=this.organization.services;
  }

  async getPosition () {
    let resp = await this.data.getGeneric(this.auth.user.token, new DosimeterPosition())
    this.dosimeterPosition = resp.docs;
    console.log('dosimeterPosition', this.dosimeterPosition)
  }

  async getMonitoringFrequency() {
    let resp = await this.data.getGeneric(this.auth.user.token, new MonitoringFrequency());
    this.monitoringFequency = resp.docs;
    console.log("monitoring frequency", resp.docs)
  }

  selectionPositionChange(event) {
    console.log(event)
    this.allocation.position=event;
  }

  selectionFrequencyChange(event) {
    console.log(event)
    this.allocation.frequency=event;
  }

  setMonitoringType(event) {
    console.log(event)
    this.allocation.monitoring_type=event.value;
    this.allocation.coefficient=event.coefficient;
    this.allocation.price=event.price;
  }

  async updateValues() { 
    let resp:any = await this.dataService.addExtraDoseRecord(this.auth.user.token,  this.allocation, this.quantity);
    if(resp.status==200) {
      this.showToast('success', this.ls.strings.success, "");
    }
      else {
        this.showToast('danger', this.ls.strings.danger, '')
      }
      this.ref.close(resp);
  }

  dismiss() {
    this.ref.close();
  }
 
}
