import { UpperCasePipe } from '@angular/common';
export class BaseModel {
    public collection_name:String;
    public active: Boolean;
    public created: Date;
    public modified: Date;
    public createdBy: String;
    public modifiedBy: String;
    constructor() {
        this.generateInit(); 
    }
    protected generateInit() {
        this.active=false;
    }

    public fromJSON(_json: JSON) {
       // console.log("geterate from JSON ", _json)
        for (let key in _json) {
           // console.log("from JSON ", key,this[key], _json[key]);
            if (this[key]!=undefined) {
                this[key]=_json[key];
            }
        }
        return this;
    }
    private validId(_i):boolean {
        let bool=false;
        if (_i!="_id") { 
            bool=true 
        }
        else if (_i=="_id" && this[_i]!="") { 
            bool=true 
        }
        else {
            bool=false;
        }
        if (_i=="type") {
            bool=false;
        }
        //console.log(_i," valid id is ",bool)
        return bool;
    }
    public getKeys():any[] {
        let ar=[];
        for (let k in this) {
            if (typeof(this[k])!="function" && k.indexOf("priv_")==-1) ar.push(k);
          }
        return ar;
    }

 

    public getObjectValue():JSON {
        let ob:LooseObject={};
        this.getKeys().forEach( key => {
            if (this.validId(key)==true) ob[key]=this[key];
        });
        return JSON.parse(JSON.stringify(ob));
    }
    public static test() {
        
    }

    public getHumanValue(){
        
    }
}
interface LooseObject {
    [key: string]: any
}
