<nb-layout>
    <nb-layout-column>
      <nb-card>
        <nb-card-header>
     <!-- <nav class="navigation">
           <a href="#" (click)="back()" class="link" aria-label="Back"><i class="icon nb-arrow-thin-left"></i></a>
         </nav> -->
        </nb-card-header>
        <nb-card-body>
          <!-- 
            <router-outlet></router-outlet>
          </nb-auth-block> -->
          <nb-auth-block>
          <h1 id="title" class="title">{{ls.strings.welcome}}</h1>

<div class="d-flex justify-content-center">
<a style="margin-left:15px;height:36px" (click)="this.ls.changeLanguage('en')"><img style="height:inherit;cursor: pointer;" src='./assets/images/uk.png'></a>
<a     style="margin-left:15px;height:39px" (click)="this.ls.changeLanguage('ro')"><img  style="height:inherit; cursor: pointer;" src='./assets/images/ro.png'></a>
</div>

<div class="text-center">
    <p>{{message}}</p>
</div>
<div class="text-center">
    <button nbButton
    
    status="primary"
    size="medium" [routerLink]="['/login']">{{ls.strings.login}}</button>
</div>
</nb-auth-block>
        </nb-card-body>
      </nb-card>
    </nb-layout-column>
  </nb-layout>