
<nb-card>
    <nb-card-header>  {{ls.strings.change_allocation_info}}</nb-card-header>
  
    <nb-card-body>

<div class="columns">
<ngx-record-overview class="card1 column" [allocation]="currentAllocation"></ngx-record-overview>
<button class="swap" ghost nbButton status="primary" (click)="swapDosimetersIds($event)"><nb-icon icon="flip-2-outline"></nb-icon></button>
<ngx-record-overview class="card2 column" [allocation]="otherAllocation"></ngx-record-overview>
</div>
</nb-card-body>
<nb-card-footer style="text-align: center;">
    <button nbButton hero status="primary" (click)="updateValues()">{{ls.strings.btn_update}}</button>
    <button nbButton hero status="primary" (click)="dismiss()">{{ls.strings.close}}</button>
  </nb-card-footer>
</nb-card>