import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { AlfrescoService } from '../../services/alfresco/alfresco.service';
import { LanguageService } from '../../services/language/language.service';

@Component({
  selector: 'ngx-dynamicform',
  templateUrl: './dynamicform.component.html',
  styleUrls: ['./dynamicform.component.scss']
})
export class DynamicformComponent implements OnInit , AfterViewInit {
  @Input() _object:any;

  tagDescriptors:any;
  message: string = "";
  icon: string = "";
  color: string = "";
  inputFields: any[];
  finalFields:any[];
  disabledFields:any[];
  templateTitle: string = "";
  _id:string = "";
  noderef:string="";
  popupTemplate:any;
  userId:string="";
  userlist_list = [];
  autocompleteValues:any;
  userSettings:any;
  dynamic:boolean;
  dinamicValues:any;
  nomenclatoare:any;
  _tempobj:any;
  splittedArray=[]
  public templateValues:any={};
  constructor(private dialogRef: NbDialogRef<any>, private datePipe : DatePipe, private alfrescoService:AlfrescoService, public ls:LanguageService) {
   
  }
  private initialize() {
    this.popupTemplate= { "value" : "x"}
    try {
    this.userId=this._object.userId;
    } catch(e) { this.userId=""}
    this.disabledFields=[];
    this.userlist_list = [];
    this.dynamic=false;
    this.nomenclatoare=this.alfrescoService.nomenclatures;
    this.userSettings = {
      singleSelection: true,
      idField: "id",
      textField: "value",
      selectAllText: "Selectează tot",
      unSelectAllText: "Deselectează tot",
      itemsShowLimit: 10,
      allowSearchFilter: true,
      closeDropDownOnSelection:true,
      maxWidth: 450,
      maxHeight:450
    };
    if (this._object.tagDescriptors) {
      this.tagDescriptors=this._object.tagDescriptors;
    } else {
      this.tagDescriptors={};
    }
    if (this._object.message) {
      this.message = this._object.message;

    }
    else this.message = ""
    if (this._object.icon) { this.icon = this._object.icon; }
    else this.icon = "";
    if (this._object.color) { this.color = this._object.color; }
    else this.color = "";
    this.noderef=this._object.noderef;
    if (this._object.autocompleteValues) {
      this.autocompleteValues=this._object.autocompleteValues;
    } else {
      this.autocompleteValues= {};
    }
    if (this._object.dinamicValues) {
      this.dinamicValues=this._object.dinamicValues;
    } else {
      this.dinamicValues= {};
    }
    if (this._object.disabledFields) {
      this.disabledFields=this._object.disabledFields;
    }

    if (this._object.userlist_list) {
      this.userlist_list = this._object.userlist_list;
    }
    if (this._object.dynamic) { this.dynamic = this._object.dynamic; }
    //dialogRef.disableClose = true;
    this.templateTitle=this._object.title;
    if (this._object.inputFields) {
     this.inputFields=[];
     this._object.inputFields.forEach(element => {

      if (element.indexOf("$section_",0)==-1 && element.indexOf("generated_",0)==-1 && element.indexOf("uath_",0)==-1) {
       if(element.indexOf('date')>-1){         
         let date
         if(this.autocompleteValues[element]!=undefined)
   {
           date=new Date(this.sanitizeDate(this.autocompleteValues[element]))
         date.setHours(3)
        }
        else {  date = ''}      
        this.inputFields.push({ "text": "", "label" : " "+element, "value":date , description: this.tagDescriptors[element] })
       } else
       if (element.indexOf("_list")>-1) {
        this.inputFields.push({"text":"", "label": " "+element, "value": this.autocompleteValues[element], 
        list: this.nomenclatoare[element],
        description:this.tagDescriptors[element]
      })
       }
       else{
       this.inputFields.push({ "text": "", "label" : " "+element, "value": this.autocompleteValues[element], description: this.tagDescriptors[element] });
      }

      }

     });
    }
    if (this._object.description && this._object.description.indexOf("; ",0)!=-1) {
      var elements=this._object.description.split("; ");
      if (elements.length>1) {
        elements.forEach(element=> {
          var el=element.split(":");
          try { this.inputFields.filter(data => data.label === el[0])[0].description = el[1]; } catch(e) {}
        })
      }
    }
    if (this._object.template && this._object.description.indexOf("visual")!=-1) {
      this.processTemplate(this._object.template);
    }
let inp= Object.assign([],  this.inputFields);
console.log(inp)
 
if(this._object['section_config'])
 for(let i = 0 ;i< this._object['section_config'].length;i++){ 
   console.log(this._object['section_config'][i])
  this.splittedArray.push(inp.splice(0,this._object['section_config'][i]))
 }
 else{
   while(inp.length>0)
  this.splittedArray.push(inp.splice(0,6))
 }
 
    console.log(this.splittedArray)
    console.log(this.inputFields)
  }
  isDisabled(id:any) {
    try {
    return this.disabledFields.indexOf(id)>-1;
    } catch(e) {
      return false;
    }
  }
  private processTemplate(t:string) {
    this.inputFields=[];
    let matcher= /\{.\S*}/gmi
    let excluder="#RePLACEsTring#"
    var _tags=String(t).match(matcher);
    var _not_tags=String(t).replace(matcher,excluder).split(excluder);
    for (var i=0;i<_tags.length;i++) {
      var t=String(_tags[i].split("{args.")[1]).slice(0,-1);
      this.inputFields.push({ "text": String(_not_tags[i]).slice(0,-1), "label" : t, "value": "" });
      //console.log(_tags[i], t);
    };
    //console.log(_tags, _not_tags)
  
    
  }
  public async saveObject() {

    var ob={};

    ob["noderef"]=this.noderef;
    this.inputFields.forEach(element => {
      let lbl=element.label.split(" ").join('');
      let  val
      if(element.label.indexOf('date')>-1){
         val=this.datePipe.transform(element.value, 'dd-MM-yyyy');
      }
      else{      val=element.value;}
      ob[lbl]=val;
    });
    this.templateValues=ob;
    console.log({"payload":this.templateValues});
    this.dialogRef.close(this.templateValues);
  }
  
  ngAfterViewInit() {

  }
  ngOnInit() {
    this.initialize();
  }
  sanitizeDate(date: string): any {
    if (!date) {
      return null;
    }
     
    const dataArray = date.split('-');
    const month = Number(dataArray[1]) - 1;
    const day = Number(dataArray[0]);
    const year = Number(dataArray[2]);
     let d=new Date(year, month, day)
    return d.toLocaleString('il-IL', { timeZone: 'ASIA/Jerusalem' });
  }

  closeWindow() {
    this.dialogRef.close();
  }
 
}
