<nb-card>
    <nb-card-header>
        {{ls.strings.add_extra_dosi}}
    </nb-card-header>
        <nb-card-body>
            <div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <p class="label"><b>{{ls.strings.customer}}: &nbsp; </b><span>{{allocation.client}}</span></p>
                            <p class="label"><b>{{ls.strings.department}}: &nbsp; </b><span>{{allocation.department}}</span></p>
                            <p class="label"><b>{{ls.strings.allocation_start}}: &nbsp; </b><span>{{allocation.allocStart | date}}</span></p>
                        </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <p class="label">{{ls.strings.dosi_no}}</p>
                            <input fullWidth type="number" min="1" [(ngModel)]="quantity"  nbInput>
                        </div>
                    </div>
                    <div class="col-sm-6">
                          <div class="form-group">
                              <p class="label">{{ls.strings.menu_monitoring_types}}</p>
                              <nb-select fullWidth #allocatedMonitoringType (selectedChange)="setMonitoringType($event)">
                                <nb-option fullWidth *ngFor="let _m of monitoring_type" [value]="_m.service" >{{_m.measurement}}</nb-option>
                               </nb-select>
                          </div>
                        </div>
                 </div>
                 <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <p class="label">{{ls.strings.menu_dosimeter_position}}</p>
                            <nb-select fullWidth #positionSelect 
                                (selectedChange)="selectionPositionChange($event)">
                                <nb-option *ngFor="let position of dosimeterPosition" [value]="position.value">
                                    {{position.value}}</nb-option>
                            </nb-select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <p class="label">{{ls.strings.menu_monitoring_frequency}}</p>
                            <nb-select fullWidth="" class="frequency"  #monitoringFrequencySelect 
                                (selectedChange)="selectionFrequencyChange($event)">
                                <nb-option *ngFor="let frequency of monitoringFequency" [value]="frequency.value">
                                    {{frequency.value}}</nb-option>
                            </nb-select>
                        </div>
                    </div>
                </div>
                
                

            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: center;">
            <button nbButton hero status="primary" (click)="updateValues()">{{ls.strings.btn_update}}</button>
            <button nbButton hero status="primary" (click)="dismiss()">{{ls.strings.close}}</button>
          </nb-card-footer>

</nb-card>
