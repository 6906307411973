import { BaseModel } from "./base.model";

export class DoseRecordModel extends BaseModel{
    public personId: String;
    public firstName: String;
    public lastName: String;
    public middleName: String;
    public initial: String;
    public maidenName: String;
    public motherMaidenName: String;
    public uid: String;
    public code: String;
    public cnp: String;
    public sex: String;
    public nationality: String;
    public dob: Date;
    public email: String;
    public phone: String;
    public customer: String;
    public monitoring_type: String;
    public dosimeter_make: String;
    public dosimeter_type: String;
    public budget_code:any;
    public position: String;
    public profession: String;
    public active: Boolean;
    public practice: String;
    public coefficient: Number;
    public description: String;
    public created: Date;
    public modified: Date;
    public classified: Boolean;
    public client: String;
    public type: String;
    public department: String;
    public frequency: String;
    public frequency_num: Number;
    public dosimeterId: String;
    public zeroDate: Date;
    public createdBy: String;
    public readDate: Date;
    public validDate: Date;
    public annealDate:Date;
    public annealBy: String;
    public readBy: String;
    public validdBy: String;
    public modifiedBy: String;
    public status: String;
    public allocStart: Date;
    public allocEnd: Date;
    public wearStartDate: Date;
    public wearEndDate: Date;
    public price: Number;
    public valid: Boolean;
    public initialDose: any;
    public dose: any;
    public late: Boolean;
    public validDose: Boolean;
    public batchId: String;
    public monitoringKey: String;
    public comment: Object;
    public comments: Object;
    public organizationId: String;
    public suborganizationId: String;
    public suborganizationCode: String;
    public suborganization_code: String;
    public organizationCode: String;
    public organization_code: String;
    public doseMinValue: Number;
    public radiationTypes= [];
    public organizationCui: String;
    public suborganizationCui: String;
    public practiceCode: String;
    public measurementUnit: String;
    

    constructor() {
        super();
        this.collection_name="dose_record";
        this.valid=false;
        this.validDose=false;
        this.classified=false;
    } 

    public getDummyData() {
        this.personId= "personId";
        this.firstName= "firstName";
        this.lastName= "lastName";
        this.middleName= "middle";
        this.initial= "I";
        this.maidenName= "maid";
        this.motherMaidenName= "mother";
        this.uid= "uid";
        this.code= "code";
        this.cnp= "";
        this.sex= "sex";
        this.nationality= "nationality";
        this.dob= new Date();
        this.email= "email@email.com";
        this.phone= "phone";
        this.customer= "CUSTOMER";
        this.monitoring_type= "m_type";
        this.dosimeter_make= "dosi_make";
        this.dosimeter_type= "dosi_type";
        this.budget_code= "budget_code";
        this.position= "position";
        this.profession= "profession";
        this.active= false;
        this.practice= "practice";
        this.coefficient= 3;
        this.description= "description";
        this.created= new Date();
        this.modified= new Date();
        this.classified= false;;
        this.client= "CLIENT";
        this.type= "person";
        this.department= "DEPARTMENT";
        this.frequency= "frequency";
        this.frequency_num= 3;
        this.dosimeterId= "dosi_id";
        this.zeroDate= new Date();
        this.createdBy= "_userC";
        this.readDate= new Date();
        this.validDate= new Date();
        this.annealDate= new Date();
        this.annealBy= "_userA";
        this.readBy= "_userR";
        this.validdBy= "_userV";
        this.modifiedBy= "_userM";
        this.status= "status";
        this.allocStart= new Date();
        this.allocEnd= new Date();
        this.wearStartDate= new Date();
        this.wearEndDate= new Date();
        this.price= 3;
        this.valid= false;;
        this.initialDose = null;
        this.dose = null;
        this.late= false;;
        this.validDose= false;;
        this.batchId= "_batchId";
        this.monitoringKey= "mKey";
        this.comment = "";
        this.comments= "";
        this.organizationId= "orgId";
        this.suborganizationId= "suborgId";
        this.suborganizationCode= "suborgCode";
        this.organizationCode= "orgCode";
        this.suborganization_code= "suborgCode";
        this.organization_code= "orgCode";
        this.doseMinValue= 3;
        this.radiationTypes= ["radiationType"];
        this.organizationCui= "orgCUI";
        this.suborganizationCui= "suborgCUI";
        this.practiceCode= "practiceCode";
        this.measurementUnit= "mUnit";
    }

}