
  <nb-card style="min-height: -webkit-fill-available;">
    <nb-card-header>{{message}} {{ls.strings.generate_contract}}</nb-card-header>
    <nb-card-body  >
      <div style="  overflow-y: auto;overflow-x:hidden;">
        <div style="margin-bottom:10px" fxLayout="column" fxLayoutAlign="space-around"  >
     
  <form class="example-form" *ngFor="let field of inputFields;let i = index">
            
        <span innerHTML={{field.text}} style="float: left;"></span>
        <nb-form-field class="formFields" *ngIf="((field.label.indexOf('date') == -1) && (field.label.indexOf('area') ==-1)) && field.label.indexOf('_list') == -1">
                      <label class="label">{{field.description||field.label}}</label>
                      <input  class="formFields" [disabled]="isDisabled(field.label)" nbInput type="text" value={{field.value}} placeholder="{{field.description||field.label}}" name={{field.label}} [(ngModel)]=field.value>
          </nb-form-field>
          
          <nb-form-field class="formFields" appearance="fill" *ngIf="field.label.indexOf('date')>-1" class="demo-full-width" class="form-group" style="width:100%">
            
            <nb-label>{{field.description||field.label}}</nb-label>
            <!-- <input matInput disabled  [matDatepicker]="i"  value=field.value name={{field.label}} class="form-control"  [(ngModel)]=field.value> -->
            <!-- <nb-datepicker-toggle matSuffix [for]="i"></nb-datepicker-toggle>
            <nb-datepicker #i disabled="false" ></nb-datepicker> -->
          </nb-form-field>
  
          <ng-multiselect-dropdown *ngIf="field.label.indexOf('_list') > -1"
          [placeholder]="field.description||field.label"
          [data]="field.list"
          [(ngModel)]="field.value"
          [settings]="userSettings"
          [ngModelOptions]="{standalone: true}"
        >
        </ng-multiselect-dropdown>    
    <br>
  </form>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
        <div class="center" style="text-align: center;">
            <button nbButton hero status="primary"  (click)='saveObject()'>{{ls.strings.save_changes}}</button> 
            <button  nbButton hero status="primary" (click)="closeWindow();">{{ls.strings.cancel}}</button>
          </div>
    </nb-card-footer>
  </nb-card>
