import { BaseNomenclature } from "./baseNomenclature.model";
import { Counties } from "./counties.model";

export class Countries extends BaseNomenclature {
    counties:Array<Counties>;
    constructor(){
        super();
        this.counties=[];
        this.collection_name="countries";
    }
}