import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener,Input, OnInit, Output, ViewChild } from "@angular/core";
import { NbComponentStatus, NbDialogService, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from "@nebular/theme";
import { ViewCell } from "ng2-smart-table";
import { AuthService } from "../../../../@core/services/auth/auth.service";
import { DataService } from "../../../../@core/services/data/data.service";
import { LanguageService } from "../../../../@core/services/language/language.service";
import { PrinterService } from "../../../../@core/services/printer/printer.service";
import { UtilsService } from "../../../../@core/services/utils/utils.service";
import { AdminAllocationComponent } from "../admin-allocation.component";

@Component({
    selector: 'ngx-dosimeter-input',
    templateUrl: './dosimeterInput.component.html',
    styleUrls: ['./dosimeterInput.component.scss']
  })
export class DosimeterInputComponent implements ViewCell, OnInit, AfterViewInit {
    renderValue: string;
    userValue: any;
    position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    public _id:any;
    @Input() value: string;
    @Input() rowData: any;
    @Output() updateResult = new EventEmitter<any>()
    static type: any;
    @ViewChild('dosimeter_id') dosimeterIdField:ElementRef;
    @ViewChild('errormessage') errorDiv:ElementRef;
  
    public fault_message: any;
    public id_value: any;
    public show_render:Boolean;
    private dosimeterPositions:any[]
    disabledInput:boolean =false;
    public static focusedInstance:any;
    constructor(private dialogService: NbDialogService,public ls:LanguageService, public auth:AuthService,
      private printerService:PrinterService, private utils:UtilsService, 
        public data:DataService, public toastrService: NbToastrService, private dataService:DataService){}
  ngAfterViewInit(): void {
    
    this.focusThis();
  }
  
    ngOnInit() {
        this.initializer();
    }

    async showRenderedValue() {
        this.renderValue=this.id_value;
        this.show_render=true;
        console.log("should show render value of ", this.renderValue, this.show_render)
    };

    async initializer() {
      this.dosimeterPositions = await this.utils.getDosimeterPositions();
    }
    async textChange(ev) {
      console.log("this row data is ", this.rowData)
      let test:Boolean=false;
      this.id_value = ev.srcElement.value;
      let _result=false;
      let obj:any = Object.assign({},this.rowData );
      console.log("let obj is ", obj)
      obj.id=this.id_value; 
      obj.dosimeterId=this.id_value;
      obj.measurement_type =  DosimeterInputComponent.type;
      let temp;
      try {
      temp = {monitoring_type:this.rowData.monitoring_type.monitoring_type, 
        // monitoringKey: this.rowData.monitoring_type.mt.key || this.rowData.monitoringKey,
        monitoringKey: this.rowData.monitoringKey, 
      monitoring_frequency: this.rowData.monitoring_type.monitoring_frequency, description: this.rowData.monitoring_type.description,
      organization_code:this.rowData.organization_code,suborganization_code:this.rowData.suborganization_code, 
      position: this.rowData.monitoring_type.position, budget_code:this.rowData.budget_code, practice:this.rowData.practice, practice_code: this.rowData.practice_code,
       profession:this.rowData.profession, 
       //organizationId and suborgid
       organizationId: this.rowData.monitoring_type.organizationId, suborganizationId: this.rowData.monitoring_type.suborganizationId
      };
      } catch(e){
        console.log("catched error ", e)
        this.showToast("danger", this.ls.strings.assigment_not_done,'');
        return
      }
      obj.monitoringKey = temp.monitoringKey;
      try {
      obj.monitoring_description = this.rowData.monitoring_type.mt.description;
      } catch(e) { obj.monitoring_description=""}
      try {
      obj.price= this.rowData.monitoring_type.mt.price;
      } catch(e) { obj.price=0 }
      obj.monitoring_type = temp.monitoring_type;
      obj.description = temp.description;
      obj.position = temp.position  //.toString();
      try { obj.position_key = this.dosimeterPositions.filter(el=>el.value==obj.position)[0].key; } catch(e) {
        obj.position_key="-";
      }
      obj.practice = temp.practice;
      obj.practice_code=temp.practice_code;
      obj.profession = temp.profession;
      obj.organization_code=temp.organization_code;
      obj.suborganization_code=temp.suborganization_code;
      obj.budget_code=temp.budget_code;
      obj.monitoring_frequency=temp.monitoring_frequency;

      //get orgId and suborgId 
      obj.organizationId = temp.organizationId;
      obj.suborganizationId = temp.suborganizationId;

      obj.allocationDate=AdminAllocationComponent.SelectedDateValue;
      obj.dosimeterMake=AdminAllocationComponent.selectedDosimeterMake;
      obj.dosimeterType=AdminAllocationComponent.selectedDosimeterType;
      obj.dosimeter_make=AdminAllocationComponent.selectedDosimeterMake;
      obj.dosimeter_type=AdminAllocationComponent.selectedDosimeterType;

      console.log("OBJECT SENT is ", obj)
      
      this.renderValue=this.id_value;
      obj.personId=this.rowData._id;
      let r = await this.dataService.validateAssigment(obj);
  
      //if (r.compatible == true && r.exists==true && r.wrong_state==false)  {
      if (r.status==200 && r.message=="ok") {
        obj.dosimeter_make=r.dosimeter.make;
        obj.dosimeter_type=r.dosimeter.type;
        obj.dosimeterMake=r.dosimeter.make;
        obj.dosimeterType=r.dosimeter.type;
        let r2=await this.dataService.assignDosimeter(this.auth.user.token, obj);
        if (r2.status!=200) {
          this.showToast("danger", this.ls.strings.assigment_not_done,'');
          return
        }
        else if (r2.status==200) {


        console.log("ok");
        obj.dosimeter_make=r.dosimeter.make;
        obj.dosimeter_type=r.dosimeter.type;
        obj.dosimeterMake=r.dosimeter.make;
        obj.dosimeterType=r.dosimeter.type;
        obj=await this.utils.calculateAssignmentPeriods(obj);
        this.showToast("success", this.ls.strings.success,'');
        _result=true;
        this.sendToPrinter(obj);
        this.updateResult.emit({ value: this.id_value, row: this.rowData, result: _result});
       
        // if (!test)
        // this.dataService.assignDosimeter(this.auth.user.token, obj);
        }
      }
      else 
      if (r.exists!=true) {
        this.showToast("danger", this.ls.strings.danger_dosi,''); 
      }
      else if (r.compatible!=true) {
        this.showToast("danger", this.ls.strings.incompatible_dosi,''); 
      }
      
      else if (r.wrong_state==true)
        this.showToast("danger", this.ls.strings.wrong_state,'');

      
    };

    onEnter() {
    }
    sendToPrinter(ob) {
        //this.showRenderedValue();
        if (ob.denumire==ob.suborganization) { ob.denumire=""; }
        this.printerService.printLabelObject(ob);
      console.log("must print ", ob, " on ", AdminAllocationComponent.SelectedPrinter);
    }
    validateRow() {
  
    }
  

    focusThis(){
       if (!DosimeterInputComponent.focusedInstance) { return }
      
       if (DosimeterInputComponent.focusedInstance!=undefined && DosimeterInputComponent.focusedInstance._id == this._id) {
         //console.log("must focus on this one ", DosimeterInputComponent.focusedInstance, " vs ", this._id)
          setTimeout(()=> {
            //console.log("focus firing ",this._id, DosimeterInputComponent.focusedInstance, this.dosimeterIdField, this.dosimeterIdField.nativeElement)
            this.dosimeterIdField.nativeElement.focus();
 
          }, 50);
        }
    }
      async getSuborganizationUsers(suborganizationId){ 
    }

    public showToast(type: NbComponentStatus, title: string, body: string) {
      const config = {
        status: type,
        destroyByClick: true,
        duration: 5000,
        hasIcon: true,
        position: this.position,
        preventDuplicates: true,
      };
      const titleContent = title ? ` ${title}` : "";
  
      this.toastrService.show(body, `${titleContent}`, config);
      console.log(title);
    }
  
  
  }