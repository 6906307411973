// country, county, city, street, street_number, building_name, floor, apartment, zip_code, details
import { UtilsService } from "../services/utils/utils.service";
import { BaseModel } from "./base.model";


export class Address extends BaseModel {
    public name: String;
    public country: String;
    public county: String;
    public city: String;
    public street: String;
    public streetNumber: String;
    public buildingName: String;
    public floor: String;
    public apartment: String;
    public zipCode: String;
    public details: String;
    public shipping_type: String
    public line1:String;
    public line2:String;
    public line3:String;
    public line4:String;
    
    private lined:boolean=false;
    constructor(private utils?:UtilsService) {
        super();
        this.getConfig()
    } 
    private async getConfig() {
        if (this.utils) {
        this.lined=await this.utils.getAddressLineConfig();
        console.log("-----------------address normal mode is ", this.lined, await this.utils.getAddressLineConfig(), this.line1)
        } else { this.lined=false }
    }
    protected generateInit() {
        this.active = true;
        this.name='';
        this.country='';
        this.county='';
        this.city='';
        this.street='';
        this.streetNumber='';
        this.buildingName='';
        this.floor= '';
        this.apartment= '';
        this.zipCode= '';
        this.details='';
        this.shipping_type='';
        this.line1="";
        this.line2="";
        this.line3="";
        this.line4="";
    }

    public getFullAddress(lined?) {
        if (lined) { this.lined=lined}
        let citi:String=this.city+", "+this.county;
        if (this.city==this.county) { citi=this.city;}
        //console.log(this, this.lined, this.line1)
        if (this.lined==false || this.line1=="") {
          //  console.log("address ", this.lined, this.line1)
        return this.street+" "+this.streetNumber+", "+this.zipCode+", "+citi+", "+this.country;
        } else {
           // console.log("address lined ", this.lined, this.line1)
        return this.line1+", "+this.line2+", "+this.line3+", "+this.line4;
        }
    }

    public getHumanValue() {
        //console.log("getting human value for address ", this, this.lined, this.line1)
        return this.getFullAddress();
    }
}