
      <nb-card style="text-align: center;">
        <!-- <ng2-smart-table [settings]="settings"  [source]="source" 
        (create)="add($event)"
        (edit)="edit($event)"
        (delete)="delete($event)">
       </ng2-smart-table> -->
       <ng2-smart-table [settings]="settings"  [source]="source" 
       (create)="add($event)"
       (edit)="edit($event)">
      </ng2-smart-table>
      </nb-card>
      <br>
      <nb-card-footer>
        <button (click)="updateOrganization()"  nbButton hero status="primary">{{ls.strings.update}}</button>
      </nb-card-footer>

