import { AfterContentChecked, AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { inherits } from 'util';
import { LanguageService } from '../../../@core/services/language/language.service';
import { BaseComponent } from '../../../pages/client/base/base-component';
import { Address } from '../../models/address.model';
import { Countries } from '../../models/countries.model';
import { OrganizationModel } from '../../models/organization.model';
import { ShippingType } from '../../models/shippingType.model';
import { AuthService } from '../../services/auth/auth.service';
import { DataService } from '../../services/data/data.service';
import { UtilsService } from '../../services/utils/utils.service';
import { NbToastrService } from '@nebular/theme';


@Component({
  selector: 'ngx-address-component',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent extends BaseComponent implements OnInit, AfterViewInit, AfterContentInit, OnChanges  {
  @Input() _title:string;
  @Input() address:Address;
  @Input() _disabled:boolean;
  @ViewChild('countySelector') countySelector:any;
  @Output() modelChange: EventEmitter<Address> = new EventEmitter<Address>();
  countries: [];
  shipping_type: []; 
  selectedShippingType: ShippingType;
  selectedCountry:Countries;
  selectedCounty:any;
  addressLines:boolean=false;
  constructor(public ls:LanguageService, public data:DataService,  public auth:AuthService, private cd:ChangeDetectorRef, private utils:UtilsService, private fb:FormBuilder, public toastrService?: NbToastrService) {
    super(data, auth, toastrService, ls, cd);
    this.selectedCountry=new Countries();
    this.selectedCountry.counties=[];
    
    //this.address=new Address()
  }

 
  ngOnChanges(changes:SimpleChanges) {

    this.initialize();
  }
  ngAfterContentInit(): void {
  
  }
  ngAfterContentChecked(): void {
  
  }
  private init() {
  
  }
  protected reinit(ob: any): void {
    setTimeout(()=> {
      this.initialize();
    }, 500) 
  }
  ngAfterViewInit(): void {

  }

  async initialize() {

      this.addressLines=await this.utils.getAddressLineConfig()
      if (!this.address) { this.address=new Address()}
      
      await this.getCountry();
      await this.getShippingType();

    
  }
  selectionCountryChange(event) {
console.log(event)
  }


async getCountry () {
  this.countries = await this.utils.getCountries();
  if (this.address.country!="")
  try {
  this.selectedCountry=this.countries.filter(elem=>elem['value']==this.address.country)[0];
    //console.log("----------------------------------",this.selectedCountry, this.address)
    try {this.selectedCounty=this.selectedCountry.counties.filter(elem=>elem['value']==this.address.county)[0];}
    catch(e) {
      this.selectedCountry=new Countries();
      this.selectedCountry.counties=[];
    }
    //console.log("selectedCounty", this.selectedCounty.value," ++++++++++++++++ ", this.address)

  } catch(e) {
    //console.log("something went wrong?", e)
    this.selectedCountry=new Countries();
   }
}


async getShippingType() {
  
  this.shipping_type=await  this.utils.getShippingTypes();
  this.selectedShippingType=this.shipping_type.filter(elem=>elem['key']==this.address.shipping_type)[0]

};

setCountry(event) {
  console.log("------------------",event)
  try{
  this.selectedCountry=this.countries.filter(elem=>elem['value']==event)[0];//.filter(event;
  this.address.county=null
  console.log(this.selectedCountry)
  } catch(e) {
    if (typeof this.address == 'string') {
      this.address=new Address();
      this.address.county=null;
    }
   // console.log("string???", this.address, this.selectedCountry);


  }
  console.log(this.address)
}


}
