
const _isDev = window.location.port.indexOf('4200') > -1;
var mw = _isDev ? '/alfresco/s/' : '/dimw/';
mw = '/alfresco/s/'

var alfrescoUrl = "http://di-sed.isc.local:8080"
alfrescoUrl = "https://rd.t2s.cloud";
alfrescoUrl = '';
var apiEndPoint ='http://di.isc.local'
apiEndPoint='http://localhost'
apiEndPoint = _isDev? 'http://localhost:3000' : window.location.protocol+'//'+window.location.host+""; //'https://app-uat.dositracker.io' //'https://'http://localhost';
//apiEndPoint='http://di.isc.local';
const loginPoint = mw+ 'api/login';


// POST
const alfAdm = 'admin';
var alfAdmPass = 'admin';
alfAdmPass = "TemporarAx";
alfAdmPass = "T2.WebtoolzDI";
const getFileContent = '/alfresco/api/-default-/public/cmis/versions/1.1/atom/content?id=';
const getImageContent= '/alfresco/api/-default-/public/cmis/versions/1.1/atom/content?id=';
const copyNode='/alfresco/api/-default-/public/alfresco/versions/1/nodes/'
const deleteNode='/alfresco/api/-default-/public/alfresco/versions/1/nodes/'
const createUpdateFile = "/alfresco/s/ro/tpatrat/di/utils/createUpdateFileInSiteFolder";
const getDocConfig =mw+ 'ro/tpatrat/isc/config/getConfig?config=';
const getDocTemplate =mw+ 'ro/tpatrat/rodos/aggregator/getTemplate?config=';
const getDocumentTypes = mw+ 'ro/tpatrat/isc/config/getDocumentTypes';
const getNomenclatures = mw+ 'ro/tpatrat/isc/config/getNomenclatures';
const getUIConfig = mw+ 'ro/tpatrat/isc/config/getUIConfig';
const quickGenerator = mw + 'ro/tpatrat/rodos/aggregator/quickGenerator';
//const concatenateFiles = mw + 'ro/tpatrat/rodos/aggregator/concatenateFiles';
const advSearch= mw+ "ro/tpatrat/di/utils/advSearch";
const getPDFRendition = mw + "ro/tpatrat/rodos/aggregator/getPDFRendition?node=";
const browseFolder = "/alfresco/s/ro/tpatrat/browseFolder?noderef=workspace://SpacesStore/";
const viewFile_old='/api/downloadFile?noderef=';
const viewFile='/api/alfresco/getFile';
const fileLink='/api/alfresco/fileLink';
const downloadFile="/alfresco/api/-default-/public/alfresco/versions/1/nodes/";
//const browseSiteFolder = mw+ "ro/tpatrat/di/browseSiteFolder"
const getContent = '/getLink/'; 
const getSitePathNode=mw + 'ro/tpatrat/isc/getSitePathNode?site=isc&path='
const getAdminTicket = '/api/alfresco/getAdminTicket';
const getDocumentTemplates = '/api/alfresco/getTemplates';
const searchByDoName = '/api/simasap/search_by_doName?doObj=';
 const updateNodeFile= '/alfresco/s/ro/tpatrat/di/updateNode';
 const browseReportFolders= '/alfresco/s/ro/tpatrat/di/reports/browseReportFolders';
 //const getTemplates = '/alfresco/s/ro/tpatrat/rodos/config/getDosiTemplates';
 const getTemplates = '/api/alfresco/getDosiTemplates';
 const getDocumentTemplate = '/api/alfresco/getTemplate?config=';
 const browseSiteFolder = '/api/alfresco/browseFolders?folder=';
 const concatenateFiles = '/api/alfresco/concatenateFiles';
export var ENV = {
  alfrescoUrl: alfrescoUrl,
  isEncrypted: false,
  middlewareContext: mw,
  API_URL: alfrescoUrl,
  API_LOGIN: alfrescoUrl + loginPoint,
  API_UPDATE_NODE: alfrescoUrl + updateNodeFile,
  API_BROWSE_REPORT_FOLDRES: alfrescoUrl + browseReportFolders,
  API_GET_FILE_CONTENT: alfrescoUrl + getFileContent,
  API_GET_IMAGE_CONTENT: alfrescoUrl + getImageContent,
  API_GET_DOC_CONFIG: alfrescoUrl + getDocConfig,
  API_GET_DOC_TEMPLATE: alfrescoUrl + getDocTemplate,
  API_GET_DOC_TYPES: alfrescoUrl + getDocumentTypes,
  API_GET_NOMENCLATURES: alfrescoUrl + getNomenclatures,
  API_GET_UICONFIG: alfrescoUrl + getUIConfig,
  API_POST_QUICKGENERATOR: alfrescoUrl + quickGenerator,
  
  API_GET_ADVANCED_SEARCH:alfrescoUrl + advSearch,
  API_GET_PDF_RENDITION: alfrescoUrl + getPDFRendition,
  API_GET_CONTENT: alfrescoUrl + getContent,
  API_GET_BROWSEFOLDER: alfrescoUrl + browseFolder,
  API_DOWNLOAD_FILE:alfrescoUrl+downloadFile,
  API_POST_DELETE_NODE: alfrescoUrl + deleteNode,
  API_BROWSE_SITE_FOLDER: alfrescoUrl + browseSiteFolder,
  API_GET_ADMIN_TICKET : apiEndPoint + getAdminTicket,
  API_GET_VIEW_FILE: apiEndPoint + viewFile,
  API_GET_USER_FOLDER: alfrescoUrl + getSitePathNode,
  API_POST_CREATE_UPDATE_FILE_IN_SITE_FOLDER: alfrescoUrl + createUpdateFile,
  API_POST_COPY_NODE:alfrescoUrl+copyNode,
  //API_GET_DOSI_TEMPLATES: alfrescoUrl+ getTemplates,
  API_GET_DOSI_TEMPLATES: apiEndPoint + getTemplates,
  API_GET_DOCUMENT_TEMPLATE: apiEndPoint+getDocumentTemplate,
  API_GET_BROWSE_SITE_FOLDER: apiEndPoint+browseSiteFolder,
  API_GET_FILE_LINK: apiEndPoint+fileLink,
  API_POST_CONCATENATEFILES: apiEndPoint + concatenateFiles,
  ADM_USR: alfAdm,
  ADM_PASS: alfAdmPass,
  
}