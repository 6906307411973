
<nb-card>
    <nb-card-header>
            {{ls.strings.select_label}}
    </nb-card-header>
        <nb-card-body>
            <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <p class="label">{{ls.strings.label_name}}<span style="color:orange">*</span></p>
                    <nb-select fullWidth #printerLabelSelect required
                        [(ngModel)]="selectedPrinter" (selectedChange)="printerSelect($event)"
                         >
                        <nb-option *ngFor="let lbl of printerLabels" [value]="lbl">{{lbl.template_title}} ({{lbl.printer}}) - {{lbl.description}}
                        </nb-option>
                    </nb-select>
                </div>
                </div>
              </div>
        </nb-card-body>

        <nb-card-footer style="text-align: center;">
            <button [disabled]="!isPrinterEnabled()" nbButton hero status="success" (click)="printLabel()">{{ls.strings.print_label}}</button>

            <button nbButton hero status="primary" (click)="dismiss()">{{this.ls.strings.close}}</button>
          </nb-card-footer>
</nb-card>