import { BaseModel } from "./base.model";



export class Service extends BaseModel {
    public measurement:any;
    public service: any;
    public price: Number;
    //public discount: Number;
    public key: String;
    public description: String;
    constructor(){
        super();
        this.key='';
        this.measurement='';
        this.description='';
        this.service='';
        this.price=0;
        this.active=true;
        //this.discount=0;
    }
}