import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { DosimeterMeasurement } from '../../../../@core/models/dosimeterMeasurement.model';
import { ImportFields } from '../../../../@core/models/importFields';
import { MeasurementUnit } from '../../../../@core/models/measurementunits.model';
import { RadiationType } from '../../../../@core/models/radiationType';
import { AuthService } from '../../../../@core/services/auth/auth.service';
import { DataService } from '../../../../@core/services/data/data.service';
import { LanguageService } from '../../../../@core/services/language/language.service';
import { Coefficient } from '../../../../@core/models/coefficient.model';
import { Observable, of } from 'rxjs';
import { Service } from '../../../../@core/models/service.model';
import { ConstantPool, ThisReceiver } from '@angular/compiler';
import { BaseComponent } from '../../../client/base/base-component';
import { CommentField } from '../../../../@core/models/commentField.model';
import { DosimeterState } from '../../../../@core/models/dosimeterState.model';


@Component({
  selector: 'ngx-manage-dosimeters-data',
  templateUrl: './manage-dosimeters-data.component.html',
  styleUrls: ['./manage-dosimeters-data.component.scss']
})
export class ManageDosimetersDataComponent extends BaseComponent implements OnInit {
  @Input() title: string;
  @Input() selectedDosimeterObject: any;
  @Input() selectedCountryObject: any
  @Input() objectToModify: any;
  @Input() makes: any;
  @Input() states: any;
  @Input() fields: any;
  @Input() radiations: any;
  @Input() measures: any;
  @Input() professions: any;
  @Input() practice: any;
  @Input() countries: any;
  @Input() counties: any;
  @Input() person_sex: any;
  @Input() nationality:any;
  @Input() units: any;
  @Input() dosimeter_position: any;
  @Input() monitoring_frequency: any;
  @Input() measurementFields: boolean;
  @Input() stateFields: boolean;
  @Input() services: any;
  @Input() code_comment:any;
  @Input() shipping_type:any;
  @Input() budget_code:any;
  @Input() comment_field:any;
  @Input() services_list: any;
  @Input() selected_service:Service;
  @Input() measurement_import_field:boolean;
  // @Input() coefficient: any;
  data: any = {};
  buttonDisabled: boolean = true;
  keyExists: boolean = false;
  typeManagement: boolean = false;
  editMode: boolean = false;
  dosimeterMeasurements=[];
  importFields=[];
  commentField=[];
  notificationField=[];
  dosiPrerequisite=[];
  radiationTypes=[];
  measurementUnits=[];
  coefficients=[];
  selectedCoefficient=0;
  checked:boolean=true;
  // coefOptions: any;
  // selectedCoefValue:string;
  // filteredcoefficientsOptions$: Observable<string[]>
  @ViewChild('measurementsSelect') measurementsSelect;
  @ViewChild('fieldsSelect') fieldsSelect;
  @ViewChild('radiationsSelect') radiationsSelect;
  @ViewChild('unitSelect') unitSelect;
  @ViewChild('coefficientSelect') coefficientSelect;
  @ViewChild('commentFieldSelector') commentFieldSelector;
  @ViewChild('dosimeterPrerequisiteSelector') dosimeterPrerequisiteSelector;
  @ViewChild('notificationFieldSelector') notificationFieldSelector
  constructor(protected ref: NbDialogRef<ManageDosimetersDataComponent>,public ls:LanguageService, 
    public dataService:DataService, public auth:AuthService,public toastrService: NbToastrService) {
      super();
      this.title_key="component_title_manage_dosimeters_data_component"
     }

  ngOnInit(): void {
    console.log("manage data ", this, this.selected_service)
    this.data.measurements=[];
    this.data.radiation_types=[];
    this.data.import_fields=[];
    this.data.comment_field={};
    this.data.shipping_type={};
    this.data.coefficient=1;
    this.data.service={};
    this.data.price=0;
    this.editMode=false;
    if (this.code_comment!=undefined) this.initCommentField();
    if(this.objectToModify!=undefined){
      this.data = this.objectToModify
      this.editMode=true;
      console.log("code_comment",this.code_comment)
      
      if(this.selectedDosimeterObject!=undefined) {this.typeManagement=true;}
    }
    if (this.services && this.selected_service) {
      this.data=this.selected_service;
      this.data.measurements=[this.data.measurement]
    }
    if(this.selectedDosimeterObject!=undefined){  
      this.typeManagement=true;
      this.initMeasurements();
    }
    if (this.services!=undefined) {
      //this.measurementsSelect
      this.initMeasurements();
     
    }
    if(this.measurementFields){
      this.initMeasurementImportFields();
      this.initMeasurementRadiationTypes();
      this.initMeasurementUnits();
      this.initCoefficientFields();
    }
    if(this.stateFields!=undefined) {
      this.initPrerequisiteSelect()
    }
  }



  async initMeasurements(){
    let respMeasurements = await this.dataService.getGeneric(this.auth.user.token, new DosimeterMeasurement()); 
    console.log("RESP DOSI MEASUREMNTS 1 ARE ", respMeasurements)
    if(respMeasurements.docs.length > 0){
     
      this.dosimeterMeasurements = respMeasurements.docs.filter(el => el.active != false); 
      console.log("RESP DOSI MEASUREMNTS 2 ARE ", this.dosimeterMeasurements)
       console.log('data', this.data)
      if(this.data && this.data.measurements.length > 0){
        let measures = []
        this.dosimeterMeasurements.forEach(m => {
          this.data.measurements.forEach(measure => {
            if(m.value == measure && measures.indexOf(m) == -1){
              measures.push(m.value)
            }
          })
        });
         console.log("----------------------------------------",measures)
        if (!this.services) {
          this.measurementsSelect.selected = measures;
      } else  {
        console.log("------data measurement ",this.data.measurement, "service list ", this.services_list, "dosimeterMeasurement ", this.dosimeterMeasurements)
          this.measurementsSelect.selected = this.dosimeterMeasurements.filter(elem=>elem['value']==this.data.measurement)[0];
          this.editMode=true;
      }
      } else {
        if (!this.services) {
        this.measurementsSelect.selected = [];
        } else { 
          if (this.selected_service) {
            console.log("selected SERV is ", this.selected_service)
            console.log(this.dosimeterMeasurements)
            this.measurementsSelect.selected = this.selected_service.measurement;

          }

          console.log("it gets here")
         }
      }
    }
  }


  async initCoefficientFields(){
    let respCoefficient = await this.dataService.getGeneric(this.auth.user.token, new Coefficient());
    if(respCoefficient.docs!==undefined && respCoefficient.docs.length > 0){
      let def;
      this.coefficients = respCoefficient.docs;
      // console.log('data', this.data)
      if(this.coefficients.length > 0){
        let unit = ""
        this.coefficients.forEach(m => {
          if(m.value == this.data.coefficient){
            unit=m.value
          }
          if (m.description=="default") { def=m.value; }
        });

        //console.log("def", def, unit)
        if (unit!='') {
          this.coefficientSelect.selected=unit;
        } else {
          this.coefficientSelect.selected=def;
        }
      } else  this.unitSelect.selected ="";
    }
  }


  async initMeasurementImportFields(){
    let respImportFields = await this.dataService.getGeneric(this.auth.user.token, new ImportFields());
    if(respImportFields.docs.length > 0){
      this.importFields = respImportFields.docs;
      // console.log(' initMeasurementImportFields data', this.data)
      if(this.data && this.data.import_fields.length > 0){
        let fields = []
        this.importFields.forEach(i => {
          this.data.import_fields.forEach(field => {
            if(i.value == field && fields.indexOf(i) == -1){
              fields.push(i.value)
            }
          })
        });
        // console.log(measures)
        
        this.fieldsSelect.selected = fields;
      } else  this.fieldsSelect.selected = [];
    }
  }

  async initCommentField(){
    let respCommentField = await this.dataService.getGeneric(this.auth.user.token, new CommentField());
    console.log(respCommentField)
    if(respCommentField.docs.length > 0){
      this.commentField = respCommentField.docs;     
    }
    console.log("edit mode ",this.editMode, this.commentField, this.code_comment)
    if (this.editMode) {
    let f=this.commentField.filter(elem=>elem['key']==this.code_comment.comment.key)[0];
    console.log("f is ", f)
      this.commentFieldSelector.selected=f;
      //this.commentFieldSelector.selected=0;
    }
  }

  async initPrerequisiteSelect() {
    let r = await this.dataService.getGeneric(this.auth.user.token, new DosimeterState());
    console.log("R is ", r);
    if(r.docs.length>0) {
      this.dosiPrerequisite=r.docs;
    }
    // if (this.editMode) {
    //   console.log("this data ", this.data)
    //  try {let f=this.dosiPrerequisite.filter(elem=>elem['prerequisite']==this.data.prerequisite)[0];
    //     this.dosimeterPrerequisiteSelector.selected=f.prerequisite;
    // } catch(e) {console.log("error?")}
     
    //   }
    }

  async initMeasurementRadiationTypes(){
    let respRadiations = await this.dataService.getGeneric(this.auth.user.token, new RadiationType());
    if(respRadiations.docs.length > 0){
      this.radiationTypes = respRadiations.docs;
      // console.log('data', this.data)
      if(this.data && this.data.radiation_types.length > 0){
        let radiations = []
        this.radiationTypes.forEach(r => {
          this.data.radiation_types.forEach(rad => {
            if(r.value == rad && radiations.indexOf(r) == -1){
              radiations.push(r.value)
            }
          })
        });
        // console.log(measures)
        
        this.radiationsSelect.selected = radiations;
      } else  this.radiationsSelect.selected = [];
    }
  }

  async initMeasurementUnits(){
    let respUnits = await this.dataService.getGeneric(this.auth.user.token, new MeasurementUnit());
    if(respUnits.docs!==undefined && respUnits.docs.length > 0){
      this.measurementUnits = respUnits.docs;
      // console.log('data', this.data)
      if(this.data.measurement_unit!==undefined && this.data.measurement_unit.length > 0){
        let unit = ""
        this.measurementUnits.forEach(m => {
          if(m.value == this.data.measurement_unit){
            unit=m.value
          }
        });
     
        this.unitSelect.selected = unit;
      } else  this.unitSelect.selected ="";
    }
  }

  addData(){
    if (this.data.service) {
      this.data.measurement=this.data.service.value;
    }
    console.log("this mf data is ", this.data)

    this.ref.close(this.data)
  }

  onChangeEvent(event){
    this.buttonDisabled = false;
    console.log(event)
  };

  onPrerequisiteChangeEvent(event) {
    console.log("Prerequisite event ", event)
    this.buttonDisabled = false;

      if(event === null || event === undefined) {
        this.data.prerequisite = "";
      }
    
  }

  onChangeEventActiveService(event) {
    const input =(event.target as HTMLInputElement);
    this.checked = input.checked;
    console.log("Event on active service is ", this.checked)

  }

 async editData(){
    if (this.data.service) {
      this.data.measurement=this.data.service.value;
    }
    console.log("this mf data is ", this.data)
    this.ref.close(this.data)
  }

  onKeyChangeEvent(){
    this.buttonDisabled = false;
    this.keyExists = false;
    //switch!!!
    if(this.data.key && this.selectedDosimeterObject){ //types
      this.typeManagement =  true;

      let checkKeyExists;
      if (this.services==true) {
        checkKeyExists = this.selectedDosimeterObject.some(({key}) => key == this.data.key);
      } else {
        checkKeyExists = this.selectedDosimeterObject.types.some(({key}) => key == this.data.key);
      }
      if(checkKeyExists==true){
        this.buttonDisabled = true;
        this.keyExists = true;
      }
    } else if (this.data.key && this.makes!==undefined){
      const checkKeyExists = this.makes.some(({key}) => key == this.data.key);
      if(checkKeyExists==true){
        this.buttonDisabled = true;
        this.keyExists = true;
      }
    } else if (this.data.key && this.states!==undefined){
      const checkKeyExists = this.states.some(({key}) => key == this.data.key);
      if(checkKeyExists==true){
        this.buttonDisabled = true;
        this.keyExists = true;
      }
    }
    else if (this.data.key && this.measures!==undefined){
      const checkKeyExists = this.measures.some(({key}) => key == this.data.key);
      if(checkKeyExists==true){
        this.buttonDisabled = true;
        this.keyExists = true;
      }
    }
    else if (this.data.key && this.fields!==undefined){
      const checkKeyExists = this.fields.some(({key}) => key == this.data.key);
      if(checkKeyExists==true){
        this.buttonDisabled = true;
        this.keyExists = true;
      }
    }
    else if (this.data.key && this.radiations!==undefined){
      const checkKeyExists = this.radiations.some(({key}) => key == this.data.key);
      if(checkKeyExists==true){
        this.buttonDisabled = true;
        this.keyExists = true;
      }
    }
    else if (this.data.key && this.professions!==undefined){
      const checkKeyExists = this.professions.some(({key}) => key == this.data.key);
      if(checkKeyExists==true){
        this.buttonDisabled = true;
        this.keyExists = true;
      }
    }
    else if (this.data.key && this.units!==undefined){
      const checkKeyExists = this.units.some(({key}) => key == this.data.key);
      if(checkKeyExists==true){
        this.buttonDisabled = true;
        this.keyExists = true;
      }
    }
  }

  // private filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.coefOptions.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  // }


  // onModelChange(value: string) {
  //   this.filteredcoefficientsOptions$ = of(this.filter(value));
  //   if (this.coefOptions.indexOf(value)!=-1) {
  //     this.data.coefficient=value;
  //   }
  // }

  dismiss(){
    this.ref.close()
  }

}
