import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Representative} from '../../models/representative.model';
import { AuthService } from '../../services/auth/auth.service';
import { DataService } from '../../services/data/data.service';
import { LanguageService } from '../../services/language/language.service';
import { BaseComponent } from '../../../pages/client/base/base-component';
import { OrganizationModel } from '../../models/organization.model';
import { AppModule } from '../../../app.module';

@Component({
  selector: 'ngx-representative',
  templateUrl: './representative.component.html',
  styleUrls: ['./representative.component.scss']
})
export class RepresentativeComponent extends BaseComponent implements OnInit, AfterViewInit, AfterContentInit {
  @Input() title:string;
  @Input() representative:Representative;
  @ViewChild('representativeSelector') representativeSelector:any;
  
  userRoles = [];
  constructor(public ls:LanguageService, public data:DataService, public auth:AuthService, private cd:ChangeDetectorRef, private fb:FormBuilder) { 
    super();
  }




  ngAfterContentInit(): void {
    //throw new Error('Method not implemented.');

    
  }
  ngAfterContentChecked(): void {
    //throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    
    //this.initialize();
  }

  ngOnInit(): void {
    
  }
  
  onChangeEvent(event) {
  
  }
  selectionChange(event) {

  }


}
