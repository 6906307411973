import { BaseModel } from "./base.model";
import { FormBuilder } from '@angular/forms';
import { LanguageService } from "../services/language/language.service";
import { DataService } from "../services/data/data.service";
import { AuthService } from "../services/auth/auth.service";
import { ChangeDetectorRef } from "@angular/core";
import { RepresentativeComponent } from "../components/representative/representative.component";


export class Representative extends BaseModel {
  
    public nume: String;
    public rol_reprezentant: String;
    public email: String;
    public telefon: String;
    public fax: String;
 

    
    constructor() {
        super();

    } 
    protected generateInit() {

        this.nume='-';
        this.rol_reprezentant='-';
        this.email='-';
        this.telefon='-';
        this.fax='-';
    }

    getFullRepresentative() {
         return this.telefon+ ", " +this.fax+", "+this.email+" "
    }
    public getHumanValue() {
        return this.nume+" ("+this.rol_reprezentant+") "+this.getFullRepresentative();
    }
}