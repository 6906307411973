import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';
@Injectable({
    providedIn: 'root'
  })
export class EncryptService {
    private enckey="971b36e4b28abb723480b5aa3a5cd8ac";
    constructor() {

    }
    getAsUtf8(s):String {
      return CryptoJS.enc.Utf8.parse(s);
    }
    public decodeString(value){
        var key = CryptoJS.enc.Utf8.parse(this.enckey);
        var iv = CryptoJS.enc.Utf8.parse(this.enckey);
        
        var decrypted = CryptoJS.AES.decrypt(value, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
      }
}