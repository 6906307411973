<nb-card [nbSpinner]="loadingData">
    <nb-card-body>
      <!-- <button disabled *ngIf="loadingData" class="full_screen">LOADING</button> -->
      <div class="row">
        <div class="col-sm-8">
          <div class="form-group">
            <p class="label">{{ls.strings.label_name}}<span style="color:orange">*</span></p>
            <nb-select fullWidth #printerLabelSelect required
                [(ngModel)]="selectedPrinter" (selectedChange)="printerSelect($event)"
                 >
                <nb-option *ngFor="let lbl of printerLabels" [value]="lbl">{{lbl.template_title}} ({{lbl.printer}}) - {{lbl.description}}
                </nb-option>
            </nb-select>
        </div>
        </div>
      </div>
        <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="inputDom" class="label">{{ls.strings.date}}<span style="color:orange">*</span></label>            
<!--                 <div class="required" *ngIf="invalidated('selectedDate')">
                  {{ls.strings.required_field}}
                </div> -->
                <input type="date" (change)="onDateChangeEvent($event)" 
                
                  nbInput fullWidth id="inputDom" required [(ngModel)]="allocationStart"
                    [placeholder]="ls.strings.dom">
            </div>
            </div>
               <div class="col-sm-4">
              <div class="form-gorup">
                <p class="label">{{ls.strings.menu_dosimeter_makes}}</p>
                <nb-select fullWidth #dosimetrySystemTypesSelect 
                (selectedChange)="setDosimetrySystemManufacturer($event)">
                <nb-option [value]="">*</nb-option>
                  <nb-option *ngFor="let dosi of dosimeterMake" [value]="dosi">{{dosi.value}}

                  </nb-option>
                </nb-select>
              </div>
            </div>
              <div class="col-sm-4">
                <div class="form-group">
                    <p class="label">{{ls.strings.menu_dosimeter_types}}</p>
                    <nb-select fullWidth #dosimeterTypesSelect 
                    (selectedChange)="setDosimetryType($event)">
                      <nb-option [value]="">*</nb-option>
                        <nb-option  *ngFor="let dosimeter of selectedDosimeterMake.types" [value]="dosimeter">{{dosimeter.value}}
                        </nb-option>
                    </nb-select>
                </div>
            </div>
            </div>

                
           <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                  <label for="monitoringTypesSelect" class="label">{{ls.strings.menu_monitoring_types}}<span style="color:orange">*</span></label>
<!--                   <div class="required" *ngIf="invalidated('monitoringTypesSelect')">
                    {{ls.strings.required_field}}
                  </div> -->
                  <nb-select fullWidth #monitoringTypesSelect id="monitoringTypesSelect">
                      <nb-option *ngFor="let mt of monitoringTypes" [value]="mt.value">{{mt.value}}
                      </nb-option>
                  </nb-select>
              </div>
            </div>

            <!-- <div class="col-sm-4">
              <div class="form-group">
            <p class="label">{{this.ls.strings.denumire}}</p>
            <nb-select fullWidth #exposedOrganizationSelect (selectedChange)="getSuborganizations($event)" [(ngModel)]="client">
              <nb-option [value]="" >*</nb-option>
                <nb-option *ngFor="let org of organizations" [value]="org._id">{{org.denumire}}</nb-option>
              </nb-select>
            </div>
            </div> -->


            <div class="col-sm-4">
            <div class="form-group">
              <p class="label">{{this.ls.strings.denumire}}</p>
              <input style="min-width: 90%; width: 90%  !important;"
               [ngModel]="client"
               nbInput  type="text" (ngModelChange)="onOrganizationChange($event)"
          [nbAutocomplete]="exposedOrganizationSelect" />
          <button ghost nbButton style="margin-left: 5px !important; margin-top: 0 !important;" status="primary" (click)="clearAutoCompleteValue();" >
            <nb-icon icon="undo-outline"></nb-icon>
        </button> 
          <nb-autocomplete style="width: 100% !important" #exposedOrganizationSelect fullWidth
          (selectedChange)="exposedOrganizationChangeAutocomplete($event)"
           (change)="changeVal($event)">
           <nb-option [value]="" (click)="clearAutoCompleteValue();">*</nb-option>
          <nb-option fullWidth style="height: auto; width: 100% !important" class="option-list" *ngFor="let org of filteredorganizationsOptions$ | async" [value]="org.denumire">{{org.denumire}}</nb-option>
            </nb-autocomplete>
            </div> 
          </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <p class="label">{{this.ls.strings.organization}}</p>
                    <nb-select fullWidth  #suborganizationsSelect 
                      (selectedChange)="exposedSuborganizationChange($event)" >
                      <nb-option [value]="">*</nb-option>
                      <nb-option *ngFor="let org of suborganizations" [value]="org._id">{{org.denumire}}</nb-option>
                    </nb-select>
                </div>
              </div>     
          </div>

          <div  *ngIf="shouldShowField('frequency')" class="row">
            <div class="col-sm-4">
              <div class="form-group">
                  <label for="frequencySelect" class="label">{{ls.strings.frequency}}</label>
                  <nb-select fullWidth #frequencySelect id="frequencySelect">
                      <nb-option *ngFor="let frequency of frequencies" [value]="frequency.value">{{frequency.value}}
                      </nb-option>
                  </nb-select>
              </div>
            </div>
            </div>
      

          <div *ngIf="shouldShowField('client_interval')" class="row">
            <div class="col-sm-2">
              <div class="form-group">
                <label for="Id" class="label">{{ls.strings.client_interval}}</label>
                <input type="number" nbInput fullWidth id="inputCode" [(ngModel)]="client_minCode"
                [placeholder]="ls.strings.min">
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group">
                <label for="Id" class="label">{{ls.strings.client_interval}}</label>
                <input type="number" nbInput fullWidth id="inputCode" [(ngModel)]="client_maxCode"
                [placeholder]="ls.strings.max">
              </div>
            </div>
          </div>

        <div *ngIf="shouldShowField('department_interval')" class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="Id" class="label">{{ls.strings.interval}}</label>
              <input type="number" nbInput fullWidth id="inputCode" [(ngModel)]="minCode"
              [placeholder]="ls.strings.min">
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="Id" class="label">{{ls.strings.interval}}</label>
              <input type="number" nbInput fullWidth id="inputCode" [(ngModel)]="maxCode"
              [placeholder]="ls.strings.max">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4" *ngIf="shouldShowField('country')">
            <div class="form-group">
                <p class="label">{{this.ls.strings.menu_countries}}</p>
                <nb-select fullWidth  #countriesSelect
                  (selectedChange)="setCountryChange($event)"  [(ngModel)]="workplace_address.country">
                  <nb-option [value]="">*</nb-option>
                  <nb-option *ngFor="let country of countries" [value]="country.value">{{country.value}}</nb-option>
                </nb-select>
            </div>
          </div> 
          <div class="col-sm-4" *ngIf="shouldShowField('county')" >
            <div class="form-group">
                <p class="label">{{this.ls.strings.menu_counties}}</p>
                <nb-select fullWidth  #countiesSelect
                   [(ngModel)]="workplace_address.county">
                  <nb-option [value]="">*</nb-option>
                  <nb-option *ngFor="let county of selectedCounties" [value]="county.value">{{county.value}}</nb-option>
                </nb-select>
            </div>
          </div>
        </div>

          <button [disabled]="!isFilterEnabled() || loadingData" nbButton status="primary" (click)="showWearers(-99)" ><nb-icon icon="funnel-outline"></nb-icon>{{ls.strings.filter_wearers}}</button>
          <span>&nbsp;&nbsp;</span>
          <button [disabled]="allocationSource.count()==0 || loadingData"  nbButton status="primary" (click)="exportXLSXData();"><nb-icon icon="download-outline"></nb-icon>{{ls.strings.export_xslx}}</button>
    </nb-card-body>

    <div class="col-sm-6">
      <div class="form-group">
         <p><b>{{ls.strings.count_allocations}}:&nbsp;</b><span>{{allocationSource.count()}}</span></p>
     </div>
 </div>
 <div class="col-sm-6">
  <div class="form-group">
     <p><b>{{ls.strings.count_annealed_allocations}}&nbsp;</b><span>{{allocableAnnealedDosi}}</span></p>
    
     
 </div>
</div>
    <br>
    <ng2-smart-table [settings]="allocationSettings"  [source]="allocationSource" #allocationTable (custom)="onCustomEvent($event)"
    (rowSelect)="tableRowSelect($event)" 
    >  
  </ng2-smart-table>
</nb-card>
