<nb-card>
    <nb-card-header>  {{ls.strings.change_allocation_info}}</nb-card-header>
  
    <nb-card-body>
      <div class="row">
        <div class="col">
        <nb-toggle status="primary" (change)="onChangeEvent($event)" [(ngModel)]="allocation.active" >{{ls.strings.active_allocation}}</nb-toggle>
      </div>

      <div class="col">
        <nb-toggle status="primary" (change)="changeClassified($event)" [(ngModel)]="allocation.classified" >{{ls.strings.classified_wearer}}</nb-toggle>
      </div>
    </div>
        <div class="row">
    
        
        <div class="col-sm-6">
          <div class="form-group">
            <p class="label">{{this.ls.strings.wearer}}</p>
            <input style="min-width: 85%; width: 85%  !important;"
             [ngModel]="personName"
             nbInput  type="text" (ngModelChange)="onPersonChange($event)"
        [nbAutocomplete]="personSelect" />
        <button ghost nbButton style="margin-left: 5px !important; margin-top: 0 !important;" status="primary" (click)="clearAutoCompleteValue();" >
          <nb-icon icon="undo-outline"></nb-icon>
      </button> 
        <nb-autocomplete style="width: 100% !important" #personSelect fullWidth
        (selectedChange)="personChangeAutocomplete($event)"
         (change)="changeVal($event)">
        <nb-option fullWidth style="height: auto; width: 100% !important" class="option-list" *ngFor="let person of filteredpersonsOptions$ | async" [value]="person">{{person.firstName}} {{person.lastName}}</nb-option>
          </nb-autocomplete>
          </div> 
        </div>
      



        <div class="col-sm-6">
          <div class="form-group">
              <label for="inputDosimeter_id" class="label">{{ls.strings.dosimeter_id}}</label>
              <input nbInput fullWidth [(ngModel)]="allocation.dosimeterId">
          </div>  
      </div>
    </div>

                    <div class="row">
                      <div class="col-sm-6" >
                        <div class="form-group">
                          <p class="label">{{ls.strings.menu_dosimeter_position}}</p>
                          <!-- <input type="text"  nbInput fullWidth id="inputPosition" [(ngModel)]="allocation.position" [placeholder]="ls.strings.menu_dosimeter_position"> -->
                          <nb-select fullWidth #allocatedPosition (selectedChange)="setPosition($event)" 
                          >
                          <nb-option [value]="">*</nb-option>
                            <nb-option fullWidth *ngFor="let _p of positions" [value]="_p" >{{_p.value}}</nb-option>
                           </nb-select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                            <p class="label">{{ls.strings.menu_monitoring_types}}</p>
                            <nb-select [disabled]="monitoringTypeChanged" fullWidth #allocatedMonitoringType (selectedChange)="setMonitoringType($event)" 
                            >
                             <nb-option fullWidth *ngFor="let _m of monitoring_types" [value]="_m" >{{_m.monitoring_type}}</nb-option> 
                             </nb-select>
                        </div>
                    </div>
                    </div>

                    <!-- CHANGE SUBORGANIZATION -->
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                            <p class="label">{{ls.strings.suborganization}}</p>
                            <nb-select fullWidth  #suborganizationSelect (selectedChange)="selectionOrganizationChange($event)" [(ngModel)]="allocation.department">
                                <nb-option *ngFor="let org of suborganizations" [value]="org.denumire">{{org.denumire}}
                                </nb-option>
                            </nb-select>
                        </div>
                    </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6" >
                        <div class="form-group" >
                          <p class="label">{{ls.strings.wear_start_date}}</p>
                          <input type="date"  nbInput fullWidth id="inputWearStartDate" [(ngModel)]="allocation.wearStartDate" [placeholder]="ls.strings.wear_start_date">
                        </div>
                          </div>
                          <div class="col-sm-6" >
                            <div class="form-group" >
                              <p class="label">{{ls.strings.wear_end_date}}</p>
                              <input type="date"  nbInput fullWidth id="inputWearEndDate" [(ngModel)]="allocation.wearEndDate" [placeholder]="ls.strings.wear_end_date">
                            </div>
                              </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6" >
                          <div class="form-group" >
                            <p class="label">{{ls.strings.allocStart}}</p>
                            <input [disabled]="!isSuperadminUser" type="date" class="alloc" (change)="allocationDateChangedEvent()"  nbInput fullWidth id="inputAllocStart" [(ngModel)]="allocation.allocStart" [placeholder]="ls.strings.allocStart">
                          </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group" >
                                <p class="label">{{ls.strings.allocEnd}}</p>
                                <input [disabled]="!isSuperadminUser"  type="date" class="alloc" (change)="allocationDateChangedEvent()"  nbInput fullWidth id="inputAllocEnd" [(ngModel)]="allocation.allocEnd" [placeholder]="ls.strings.allocEnd">
                              </div>
                                </div>
                        </div>

                      <div class="row">
                        <div class="col-sm-6" *ngIf="!showComments">
                          <div class="form-group">
                            <p class="label">{{ls.strings.menu_code_comment}}</p>
                      <nb-select style="width: 100%;" (selectedChange)="onCommentChange($event)" #codeCommentSelect [(ngModel)]="allocation.comment" 
                      [placeholder]="ls.strings.enter_code_comment" >
                        <nb-option *ngFor="let code of codeComment" [value]="code">{{ code.value }} ({{code.key||''}})</nb-option>
                      </nb-select>
                          </div>
                        </div>
                      
                      <!-- COMMENTS -->
                        <div class="col-sm-6" *ngIf="showComments">
                          <div class="form-group">
                            <p class="label" >{{ls.strings.menu_code_comments}}</p>
                      <nb-select  style="width: 100%;" multiple (selectedChange)="onCommentsChange($event)" #codeCommentsSelect [(ngModel)]="selectedComments" 
                      [placeholder]="ls.strings.enter_code_comments" >
                        <nb-option *ngFor="let code of codeComments" [value]="code">{{ code.value }} ({{code.key||''}})</nb-option>
                      </nb-select>
                          </div>
                        </div>
                      </div>


                      <div>
                        <p class="required">{{ls.strings.edit_alloc_rule}}</p>
                    </div>
                 
     
    </nb-card-body>
    <nb-card-footer style="text-align: center;">
        <button nbButton hero status="primary" (click)="updateValues()">{{ls.strings.btn_update}}</button>
        <button nbButton hero status="primary" (click)="dismiss()">{{ls.strings.close}}</button>
      </nb-card-footer>
</nb-card>
