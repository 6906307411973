import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { ManageDosimetersDataComponent } from '../../../pages/admin/modals/manage-dosimeters-data/manage-dosimeters-data.component';
import { BaseComponent } from '../../../pages/client/base/base-component';
import { ConfirmationComponent } from '../../../pages/client/modals/confirmation/confirmation.component';
import { OrganizationModel } from '../../models/organization.model';
import { Service } from '../../models/service.model';
import { AuthService } from '../../services/auth/auth.service';
import { DataService } from '../../services/data/data.service';
import { LanguageService } from '../../services/language/language.service';
import { PersonModel } from '../../models/person.model';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'ngx-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent extends BaseComponent implements OnInit {
  settings= {};
  source: LocalDataSource = new LocalDataSource();
  organization:OrganizationModel=new OrganizationModel();
  disableUpdateButton: boolean=true;
 persons: [];
  
  lang: any;
    columns:any = {
      service: {
        title: this.ls.strings.service_type,
        type: "string",
      },
       price: {
        title: this.ls.strings.service_price,
        type: "string",
      },
      descriere: {
        title: this.ls.strings.description,
        type: "string"
      }
      }
  constructor(public ls:LanguageService, 
    private dialogService:NbDialogService,
    public dataService:DataService, private router:Router, public auth: AuthService,  private utilsService:UtilsService,
    public toastrService: NbToastrService) {
      super(dataService, auth, toastrService);
      this.title_key="component_title_client_organization_profile"
     }

     protected reinit(ob){
      console.log("SERVICES COMP IN REINIT!!!!!!!!!!!!!!!!!!!!")
      this.init();
      this.initialize();
    }
  async init(){
    if (this.auth.isSystemRole() || this.auth.hasClientAdminRole()) {
      this.columns = {
          measurement: {
            title: this.ls.strings.service_type, type: "string" },
           price: { title: this.ls.strings.service_price, type: "string"},
          // discount: { title: this.ls.strings.discount, type: "string"},
          description: { title: this.ls.strings.description, type: "string" }
        }
      } else {
        this.columns = {
          measurement: {
            title: this.ls.strings.service_type, type: "string" },
          description: { title: this.ls.strings.description, type: "string" }
        } 
      }
      this.settings  = Object.assign({},  {
          mode:'external',
          rowClassFunction: (row) => {
            if (row.data.active==!true) {
              return 'disabled_line';
            } 
            return '';
          },
          actions:{
            columnTitle:this.ls.strings.actions,
            delete:false
          },
          add: {
            addButtonContent: '<i class="nb-plus"></i>',
            createButtonContent: '<i class="nb-plus"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
            confirmCreate: true,
          },
          edit: {
            editButtonContent: '<i class="nb-edit"></i>',
            saveButtonContent: '<i class="nb-edit"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
            confirmSave: true,
            enable: false
          },
          delete: {
            deleteButtonContent: '<i class="nb-trash"></i>',
            confirmDelete: false,
          },
          columns: this.columns
        } );
  };

  private async initialize() { 
    let r:any = await this.dataService.getOrganizationDetails(this.auth.user.preffered_organization, this.auth.user.token);
    if (r.docs.length>0) {
        this.organization=r.docs[0];
    }
    try {
      this.source.load(this.organization.services)
    }
    catch(e) {
      this.source.load([]);
    };
};


async add(event) {
  let b=await this.canWrite();
  if (!b) return
if (this.organization.services==null) { this.organization.services=[]; }
this.dialogService.open(ManageDosimetersDataComponent, {
 context: {
 title: this.ls.strings.add_services,
 services_list: this.organization.services,
 services: true 
    },
hasBackdrop: true,
closeOnBackdropClick: false
  })
  .onClose.subscribe(async result => {
    if(result){
      let _test=this.utilsService.checkDuplicateService(this.organization, result)
      if (_test==false) {
      let objToAdd = new Service();
      objToAdd.key=result.key;
      objToAdd.price=result.price;
      objToAdd.service=result.service;
      objToAdd.measurement=result.service.value;
      objToAdd.description=result.description;
      this.organization.services.push(objToAdd);
      this.source.load(this.organization.services);
      } else  {
        this.showToast('warning', this.ls.strings.warning,this.ls.strings.duplicate_service)
      }
  }})

}

async edit(object) {
  let b=await this.canEdit();
  if (!b) return
  if (this.organization.services==null) { this.organization.services=[]; }
  this.dialogService.open(ManageDosimetersDataComponent, {
    context: {
      title: this.ls.strings.modify_service,
      services_list: this.organization.services,
      services: true,
      selected_service: object.data
     
    },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(async result => {
      if(result){
        console.log("this organization is ", this.organization, "result  is ", result, "selected service ", object.data)
        let _test=this.utilsService.checkDuplicateService(this.organization, result, true, object.data)
        if (_test==false) {
        let modifiedObj = new Service();
        modifiedObj.active=result.active;
        modifiedObj.key=result.key;
        modifiedObj.price=result.price;
       //modifiedObj.discount=result.discount;
        modifiedObj.service=result.service;
        modifiedObj.measurement=result.measurement;
        if (modifiedObj.measurement==undefined || !result.measurement) { modifiedObj.measurement=result.service.value; }
        modifiedObj.description=result.description;
        let srv = this.organization.services.indexOf(object.data); 
        console.log("srv ", srv, modifiedObj, result.measurement)
        if(srv !== -1){
          this.organization.services[srv]=modifiedObj;
        this.source.load([...this.organization.services]);

      }
    } else  {
      this.showToast('warning', this.ls.strings.warning,this.ls.strings.duplicate_service)
    }
    }})
}

// async delete(object) {
//   console.log("object is ", object)
//   let b=await this.canDelete();
//   if (!b) return
//     this.dialogService.open(ConfirmationComponent, {
//     context: {
//     body: this.ls.strings.delete_confirmation,
//     confirm: this.ls.strings.yes,
//     cancel: this.ls.strings.cancel
//       },
//       hasBackdrop: true,
//       closeOnBackdropClick: false
//     })
//     .onClose.subscribe(result => {
//       if(result == 'confirm'){
//         let obj;
//         obj= new Service();
//         obj.key=object.key;
//         obj.price=object.price;
//         obj.service=object.service;
//         obj.measurement=object.service;
//         obj.description=object.description;
//         console.log("object.data is ", object.data)
//         let objct = this.organization.services.indexOf(object.data); 
//         console.log("OBJCT ", objct)
//         let index = this.organization.services.findIndex(item => item.key == object.data.key);
//         if(index !== -1){
//           console.log("DELETING", objct)
//           this.organization.services.splice(index, 1);
//           this.source.load(this.organization.services)
//           this.disableUpdateButton=false;
//         }
//       }
//     })
// };

async updateOrganization(){
  let b=await this.canEdit();
  if (!b) return
  let ob:any=this.organization;
  console.log("OB in services is ", ob)
  let resp = await this.dataService.updateOrganizationAndPersonMonitoringPrice(ob._id, ob,this.auth.user.token);
  if(resp.status==200){
    this.showToast('success', this.ls.strings.success, "");
    this.data.setObjectData(this.auth.user.preffered_organization)
  }
  else {
    this.showToast('danger', this.ls.strings.danger, "");
  }
  console.log(resp);
};


async updateData(obj){
  let b=await this.canEdit();
  if (!b) return
  let r = await this.dataService.updateGeneric(this.auth.user.token, obj);
  if(r.status==200){
    this.init()
    this.showToast('success', this.ls.strings.success, "");
    this.disableUpdateButton = true;
  }
  else {
    this.showToast('danger', this.ls.strings.danger, "");
  }
};

async postData(obj){
  let b=await this.canEdit();
  if (!b) return
    let resp = await this.dataService.postGeneric(this.auth.user.token, obj, null);
    console.log('resp', resp)
    if(resp.status==200){
      this.init()
      this.showToast('success',this.ls.strings.success, "");
    }
    else {
      this.showToast('danger', this.ls.strings.danger, "");
    }
  }

}
